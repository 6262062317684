import cn from 'classnames';
import React, { ComponentPropsWithoutRef, useState } from 'react';
import { useAppSelector } from 'src/app/redux/utils';
import { LineTabs } from 'src/shared/ui/_tabs/lineTabs';
import { LeaveEnter } from 'src/pages/TimeTrackingPage/ui/LeaveEnter/LeaveEnter';
import { Heading } from 'src/shared/ui/Heading';
import { TimeEnter } from 'src/widgets/TimeEnter';
import s from './FormsCard.module.scss';

export const FormsCard: React.FC<ComponentPropsWithoutRef<'div'>> = props => {
	const { className } = props;

	const userId = useAppSelector(state => state.user_service.user.userInfo?.user.id);

	// * Tabs
	const [activeTabId, setActiveTabId] = useState<'project' | 'absence'>('project');

	// * Render
	return (
		<div className={cn(s.container, className)}>
			<div>
				<Heading
					level={3}
					marginBottom="l"
				>
					Внести часы
				</Heading>

				<LineTabs
					className={s.lineTabs}
					activeTabId={activeTabId}
					tabs={[
						{
							id: 'project',
							name: 'Проект',
						},
						{
							id: 'absence',
							name: 'Отсутствия',
						},
					]}
					width="full"
					setActiveTabId={(id: string) => setActiveTabId(id as 'project' | 'absence')}
				/>

				{userId && activeTabId === 'project' && <TimeEnter userId={userId} />}

				{userId && activeTabId === 'absence' && <LeaveEnter userId={userId} />}
			</div>
		</div>
	);
};
