import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'src/app/api/apiSlice';
import { ServiceName } from 'src/app/api/api_types';
import { FindUsersREQ, FindUsersRES } from '../../state/user/user/types';
import { CreateAvatarREQ, CreateAvatarRES, GetAvatarREQ, GetAvatarRES } from '../../state/user/avatar/types';
import {
	DeleteAvatarRemoveApiResponse,
	DeleteCommunicationRemoveApiResponse,
	DeletePendingRemoveApiResponse,
	DeleteUserRemoveDateRemoveApiResponse,
	GetAvatarEditcurrentApiResponse,
	GetAvatarGetApiResponse,
	GetCommunicationResendconfirmationApiResponse,
	GetGenderFindApiResponse,
	GetPasswordForgotApiResponse,
	GetPasswordGenerateApiResponse,
	GetPendingCheckApiResponse,
	GetPendingResendinvitationApiResponse,
	GetUserFindApiResponse,
	GetUserGetApiResponse,
	GetUserGetinfoApiResponse,
	PatchUserEditApiResponse,
	PatchUserRemoveDateEditApiResponse,
	PostAvatarCreateApiResponse,
	PostCommunicationCreateApiResponse,
	PostCredentialsCreateApiResponse,
	PostGenderCreateApiResponse,
	PostPasswordChangeApiResponse,
	PostPasswordReconstructApiResponse,
	PostUserCreateApiResponse,
	PostUserRemoveDateCreateApiResponse,
	PutCommunicationConfirmApiResponse,
	PutCommunicationEditApiResponse,
	PutCredentialsReactivateApiResponse,
	PutUserEditactiveApiResponse,
	PutUserEditcoverApiResponse,
} from './types/resTypes';
import {
	DeleteAvatarRemoveApiArg,
	DeleteCommunicationRemoveApiArg,
	DeletePendingRemoveApiArg,
	DeleteUserRemoveDateRemoveApiArg,
	GetAvatarEditcurrentApiArg,
	GetAvatarGetApiArg,
	GetCommunicationResendconfirmationApiArg,
	GetGenderFindApiArg,
	GetPasswordForgotApiArg,
	GetPasswordGenerateApiArg,
	GetPendingCheckApiArg,
	GetPendingResendinvitationApiArg,
	GetUserFindApiArg,
	GetUserGetApiArg,
	GetUserGetinfoApiArg,
	PatchUserEditApiArg,
	PatchUserRemoveDateEditApiArg,
	PostAvatarCreateApiArg,
	PostCommunicationCreateApiArg,
	PostCredentialsCreateApiArg,
	PostGenderCreateApiArg,
	PostPasswordChangeApiArg,
	PostPasswordReconstructApiArg,
	PostUserCreateApiArg,
	PostUserRemoveDateCreateApiArg,
	PutCommunicationConfirmApiArg,
	PutCommunicationEditApiArg,
	PutCredentialsReactivateApiArg,
	PutUserEditactiveApiArg,
	PutUserEditcoverApiArg,
} from './types/regTypes';

const reducerPath = `${ServiceName.USER_SERVICE}` as const;

export const user_serviceAPI = createApi({
	baseQuery: baseQueryWithReAuth,
	reducerPath,
	tagTypes: ['Avatar', 'User', 'Communication', 'Pending', 'Password', 'Gender', 'UserRemoveDate', 'Credentials'],
	endpoints: build => ({
		// * Users
		findUsers: build.query<FindUsersRES, FindUsersREQ>({
			query: arg => ({
				url: `${reducerPath}/user/find`,
				params: arg.params,
			}),
			providesTags: ['User'],
		}),

		getUserFind: build.query<GetUserFindApiResponse, GetUserFindApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/User/find`,
				params: {
					isascendingsort: queryArg.isascendingsort,
					fullnameincludesubstring: queryArg.fullnameincludesubstring,
					isactive: queryArg.isactive,
					ispending: queryArg.ispending,
					includecurrentavatar: queryArg.includecurrentavatar,
					includeremovedate: queryArg.includeremovedate,
					includecommunications: queryArg.includecommunications,
					includedepartments: queryArg.includedepartments,
					GenderId: queryArg.genderId,
					skipcount: queryArg.skipcount,
					takecount: queryArg.takecount,
				},
			}),
			providesTags: ['User'],
		}),

		putUserEditactive: build.mutation<PutUserEditactiveApiResponse, PutUserEditactiveApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/User/editactive`,
				method: 'PUT',
				body: queryArg.editUserActiveRequest,
			}),
			invalidatesTags: ['User'],
		}),

		// * Avatar
		createAvatar: build.mutation<CreateAvatarRES, CreateAvatarREQ>({
			query: arg => ({
				url: `${reducerPath}/avatar/create`,
				method: 'POST',
				body: arg.payload,
			}),
			invalidatesTags: ['Avatar', 'User'],
		}),

		getAvatar: build.query<GetAvatarRES, GetAvatarREQ>({
			query: arg => ({
				url: `${reducerPath}/avatar/get`,
				params: arg.params,
			}),
		}),

		// * Pending
		deletePendingRemove: build.mutation<DeletePendingRemoveApiResponse, DeletePendingRemoveApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/pending/remove`,
				method: 'DELETE',
				params: { userId: queryArg.userId },
			}),
			invalidatesTags: ['User', 'Pending'],
		}),

		// * Other
		postAvatarCreate: build.mutation<PostAvatarCreateApiResponse, PostAvatarCreateApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Avatar/create`,
				method: 'POST',
				body: queryArg.createAvatarRequest,
			}),
			invalidatesTags: ['Avatar', 'User'],
		}),
		getAvatarGet: build.query<GetAvatarGetApiResponse, GetAvatarGetApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Avatar/get`,
				params: { userId: queryArg.userId },
			}),
			providesTags: ['Avatar'],
		}),
		deleteAvatarRemove: build.mutation<DeleteAvatarRemoveApiResponse, DeleteAvatarRemoveApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Avatar/remove`,
				method: 'DELETE',
				body: queryArg.removeAvatarsRequest,
			}),
			invalidatesTags: ['Avatar', 'User'],
		}),
		getAvatarEditcurrent: build.query<GetAvatarEditcurrentApiResponse, GetAvatarEditcurrentApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Avatar/editcurrent`,
				params: { userId: queryArg.userId, avatarId: queryArg.avatarId },
			}),
			providesTags: ['Avatar'],
		}),
		postCommunicationCreate: build.mutation<PostCommunicationCreateApiResponse, PostCommunicationCreateApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Communication/create`,
				method: 'POST',
				body: queryArg.createCommunicationRequest,
			}),
			invalidatesTags: ['User', 'Communication'],
		}),
		putCommunicationEdit: build.mutation<PutCommunicationEditApiResponse, PutCommunicationEditApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Communication/edit`,
				method: 'PUT',
				body: queryArg.editCommunicationRequest,
				params: { communicationId: queryArg.communicationId },
			}),
			invalidatesTags: ['User', 'Communication'],
		}),
		deleteCommunicationRemove: build.mutation<DeleteCommunicationRemoveApiResponse, DeleteCommunicationRemoveApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Communication/remove`,
				method: 'DELETE',
				params: { communicationId: queryArg.communicationId },
			}),
			invalidatesTags: ['User', 'Communication'],
		}),
		putCommunicationConfirm: build.mutation<PutCommunicationConfirmApiResponse, PutCommunicationConfirmApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Communication/confirm`,
				method: 'PUT',
				params: {
					communicationId: queryArg.communicationId,
					secret: queryArg.secret,
				},
			}),
			extraOptions: {
				ignoreErrorNotification: true,
			},
			invalidatesTags: ['User', 'Communication'],
		}),
		getCommunicationResendconfirmation: build.query<GetCommunicationResendconfirmationApiResponse, GetCommunicationResendconfirmationApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Communication/resendconfirmation`,
				params: { communicationId: queryArg.communicationId },
			}),
			providesTags: ['Communication'],
		}),
		postCredentialsCreate: build.mutation<PostCredentialsCreateApiResponse, PostCredentialsCreateApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Credentials/create`,
				method: 'POST',
				body: queryArg.createCredentialsRequest,
			}),
			invalidatesTags: ['Credentials'],
			extraOptions: {
				ignoreErrorNotification: true,
			},
		}),
		putCredentialsReactivate: build.mutation<PutCredentialsReactivateApiResponse, PutCredentialsReactivateApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Credentials/reactivate`,
				method: 'PUT',
				body: queryArg.reactivateCredentialsRequest,
			}),
			invalidatesTags: ['Credentials'],
		}),
		postGenderCreate: build.mutation<PostGenderCreateApiResponse, PostGenderCreateApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Gender/create`,
				method: 'POST',
				body: queryArg.createGenderRequest,
			}),
			invalidatesTags: ['Gender'],
		}),
		getGenderFind: build.query<GetGenderFindApiResponse, GetGenderFindApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Gender/find`,
				params: {
					nameincludesubstring: queryArg.nameincludesubstring,
					skipcount: queryArg.skipcount,
					takecount: queryArg.takecount,
				},
			}),
			providesTags: ['Gender'],
		}),
		getPasswordForgot: build.query<GetPasswordForgotApiResponse, GetPasswordForgotApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Password/forgot`,
				params: { userEmail: queryArg.userEmail },
			}),
			providesTags: ['Password'],
			extraOptions: {
				ignoreErrorNotification: true,
			},
		}),
		postPasswordReconstruct: build.mutation<PostPasswordReconstructApiResponse, PostPasswordReconstructApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Password/reconstruct`,
				method: 'POST',
				body: queryArg.reconstructPasswordRequest,
			}),
			invalidatesTags: ['Password'],
			extraOptions: {
				ignoreErrorNotification: true,
			},
		}),
		postPasswordChange: build.mutation<PostPasswordChangeApiResponse, PostPasswordChangeApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Password/change`,
				method: 'POST',
				body: queryArg.changePasswordRequest,
			}),
			invalidatesTags: ['Password'],
		}),
		getPasswordGenerate: build.query<GetPasswordGenerateApiResponse, GetPasswordGenerateApiArg>({
			query: () => ({
				url: `${reducerPath}/Password/generate`,
			}),
			providesTags: ['Password'],
		}),
		getPendingCheck: build.query<GetPendingCheckApiResponse, GetPendingCheckApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Pending/check`,
				params: { userId: queryArg.userId },
			}),
			providesTags: ['Pending'],
		}),
		getPendingResendinvitation: build.query<GetPendingResendinvitationApiResponse, GetPendingResendinvitationApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/pending/resendinvitation`,
				params: {
					userId: queryArg.userId,
					communicationId: queryArg.communicationId,
				},
			}),
			providesTags: ['User', 'Pending'],
		}),

		postUserCreate: build.mutation<PostUserCreateApiResponse, PostUserCreateApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/User/create`,
				method: 'POST',
				body: queryArg.createUserRequest,
			}),
			invalidatesTags: ['User'],
		}),
		patchUserEdit: build.mutation<PatchUserEditApiResponse, PatchUserEditApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/User/edit`,
				method: 'PATCH',
				body: queryArg.body,
				params: { userId: queryArg.userId },
			}),
			invalidatesTags: ['User'],
		}),
		putUserEditcover: build.mutation<PutUserEditcoverApiResponse, PutUserEditcoverApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/User/editcover`,
				method: 'PUT',
				body: queryArg.editCoverRequest,
			}),
			invalidatesTags: ['User'],
		}),
		getUserGet: build.query<GetUserGetApiResponse, GetUserGetApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/User/get`,
				params: {
					userid: queryArg.userid,
					email: queryArg.email,
					login: queryArg.login,
					includecurrentavatar: queryArg.includecurrentavatar,
					includeavatars: queryArg.includeavatars,
					includecover: queryArg.includecover,
					includecommunications: queryArg.includecommunications,
					includecompany: queryArg.includecompany,
					includedepartment: queryArg.includedepartment,
					includeoffice: queryArg.includeoffice,
					includeposition: queryArg.includeposition,
					includerole: queryArg.includerole,
					locale: queryArg.locale,
				},
			}),
			providesTags: ['User'],
		}),
		getUserGetinfo: build.query<GetUserGetinfoApiResponse, GetUserGetinfoApiArg>({
			query: () => ({
				url: `${reducerPath}/User/getinfo`,
			}),
			providesTags: ['User'],
		}),
		postUserRemoveDateCreate: build.mutation<PostUserRemoveDateCreateApiResponse, PostUserRemoveDateCreateApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/UserRemoveDate/create`,
				method: 'POST',
				body: queryArg.createUserRemoveDateRequest,
			}),
			invalidatesTags: ['UserRemoveDate'],
		}),
		patchUserRemoveDateEdit: build.mutation<PatchUserRemoveDateEditApiResponse, PatchUserRemoveDateEditApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/UserRemoveDate/edit`,
				method: 'PATCH',
				body: queryArg.body,
				params: { userRemoveDateId: queryArg.userRemoveDateId },
			}),
			invalidatesTags: ['UserRemoveDate'],
		}),
		deleteUserRemoveDateRemove: build.mutation<DeleteUserRemoveDateRemoveApiResponse, DeleteUserRemoveDateRemoveApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/UserRemoveDate/remove`,
				method: 'DELETE',
				params: { userRemoveDateId: queryArg.userRemoveDateId },
			}),
			invalidatesTags: ['UserRemoveDate'],
		}),
	}),
});

export const {
	usePostAvatarCreateMutation,
	useGetAvatarGetQuery,
	useDeleteAvatarRemoveMutation,
	useGetAvatarEditcurrentQuery,
	usePostCommunicationCreateMutation,
	usePutCommunicationEditMutation,
	useDeleteCommunicationRemoveMutation,
	usePutCommunicationConfirmMutation,
	useGetCommunicationResendconfirmationQuery,
	usePostCredentialsCreateMutation,
	usePutCredentialsReactivateMutation,
	usePostGenderCreateMutation,
	useGetGenderFindQuery,
	useGetPasswordForgotQuery,
	usePostPasswordReconstructMutation,
	usePostPasswordChangeMutation,
	useGetPasswordGenerateQuery,
	useGetPendingCheckQuery,
	useGetPendingResendinvitationQuery,
	useDeletePendingRemoveMutation,
	usePostUserCreateMutation,
	usePatchUserEditMutation,
	usePutUserEditcoverMutation,
	useGetUserGetQuery,
	useGetUserGetinfoQuery,
	useGetUserFindQuery,
	usePutUserEditactiveMutation,
	usePostUserRemoveDateCreateMutation,
	usePatchUserRemoveDateEditMutation,
	useDeleteUserRemoveDateRemoveMutation,
} = user_serviceAPI;
