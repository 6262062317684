import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { officeServiceActions } from 'src/app/redux/state/office/actions';
import { UserAvatar } from 'src/app/redux/state/user/user/types';
import { useAppDispatch, useAppSelector } from 'src/app/redux/utils';
import { routerPath } from 'src/app/router/paths';
import { ReactComponent as CloseSVG } from 'src/shared/assets/svg_icons/action/close.svg';
import { useRights } from 'src/shared/hooks/useRights';
import { Avatar } from 'src/shared/ui/Avatar/Avatar';
import { DropDownMenu } from 'src/shared/ui/DropDownMenu/DropDownMenu';
import { CardsTable } from 'src/shared/ui/_tables/CardsTable';
import { COLUMNS } from '../consts';

export interface UserList {
	id: string;
	name: string;
	department?: string;
	position?: string;
	avatar?: UserAvatar;
}

interface Props {
	users: UserList[];
}

export const UsersList: FC<Props> = props => {
	const { users } = props;

	// * Navigation
	const navigate = useNavigate();

	// * Rights
	const isAdmin = useRights();

	// * Menu
	const DeleteUserDropDown = [
		{
			icon: <CloseSVG />,
			title: 'Открепить от офиса',
			action: (userId?: string) => onRemoveUser(userId || ''),
		},
	];

	// * Selector
	const officeId = useAppSelector(state => state.office_service.office.office?.id);

	// * Actions
	const dispatch = useAppDispatch();
	const { getOffice } = officeServiceActions.office.async;
	const { removeOfficeUsers } = officeServiceActions.user.async;

	const onRemoveUser = (userId: string) => {
		officeId &&
			dispatch(
				removeOfficeUsers({
					payload: {
						officeId,
						userIds: [userId],
					},
				}),
			)
				.unwrap()
				.then(() => dispatch(getOffice({ params: { officeId } })))
				.catch(error => console.error(error));
	};

	const navigateToUser = (id: number) => navigate(`${routerPath.users.page}/${tableData[id].id}`);

	const tableData = users.map(user => {
		return {
			id: user.id,
			avatar: (
				<Avatar
					userAvatar={user.avatar}
					name={user.name}
				/>
			),
			name: user.name,
			department: user.department ?? '',
			position: user.position ?? '',
			extra: (
				<>
					{isAdmin && (
						<DropDownMenu
							items={DeleteUserDropDown}
							value={user.id}
						/>
					)}
				</>
			),
		};
	});

	// * Render
	return (
		<>
			{users.length > 0 && (
				<CardsTable
					data={tableData}
					columns={COLUMNS}
					onRowClick={navigateToUser}
				/>
			)}
		</>
	);
};
