import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import { project_serviceAPI } from 'src/app/redux/queries/project-service/project_serviceAPI';
import { PageDecorator } from 'src/app/decorators/page_decorators/PageDecorator/PageDecorator';
import { PageTitle } from 'src/entities/PageTitle/PageTitle';
import { TimeListDownload } from 'src/entities/TimeListDownload';
import { MonthPickerHeader } from 'src/pages/project_service_pages/ui/TimeListPage/ui/MonthPickerHeader/MonthPickerHeader';
import { TimeList } from 'src/pages/project_service_pages/ui/TimeListPage/ui/TimeList/TimeList';
import { MAX_APP_DATE, MIN_APP_DATE } from 'src/shared/consts/consts';

export const TimeListPage = () => {
	// * Router
	const params = useParams<{ projectId: string }>();
	const projectId: string = params.projectId as string;

	// * API
	const { data: projectData } = project_serviceAPI.useGetProjectQuery(
		{
			projectId: projectId,
			includeprojectusers: true,
			includedepartment: true,
		},
		{ skip: !projectId },
	);

	const projectInfo = projectData?.body;

	// * Date
	const [date, setDate] = useState(new Date());

	// * Render
	return (
		<PageDecorator>
			<PageTitle
				title="Таймлист команды"
				titleComponent={
					<React.Fragment key="0">
						<MonthPickerHeader
							selectedDate={date}
							setSelectedDate={setDate}
							minDate={MIN_APP_DATE}
							maxDate={MAX_APP_DATE}
						/>
					</React.Fragment>
				}
				goBackRoute={{
					text: projectInfo?.name || 'Проект',
					route: `/projects/${projectId}`,
				}}
				buttons={[
					<React.Fragment key="1">
						<TimeListDownload
							date={date}
							projectId={projectId}
							name={projectInfo?.shortName || projectInfo?.name}
						/>
					</React.Fragment>,
				]}
			/>

			<TimeList
				projectId={projectId}
				luxonDate={DateTime.fromJSDate(date)}
			/>
		</PageDecorator>
	);
};
