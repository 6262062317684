import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { usePostFeedbackCreateMutation } from 'src/app/redux/queries/gateway-service/gateway_serviceAPI';
import { useAppDispatch, useAppSelector } from 'src/app/redux/utils';
import { ButtonPair } from 'src/entities/_buttons/ButtonPair/ButtonPair';
import { actionsNotifications } from 'src/features/notifications/_BLL/slice';
import { galleyFileToBase64 } from 'src/shared/lib/file/galleyFileToBase64/galleyFileToBase64';
import { DropZone } from 'src/shared/ui/DropZone/DropZone';
import { Heading } from 'src/shared/ui/Heading';
import { SelectSingleField } from 'src/shared/ui/_fields/SelectSingleField';
import { TextAreaField } from 'src/shared/ui/_fields/TextAreaField';
import { createImageInfoFromFile } from 'src/shared/ui/_galleries/Galley/_utils';
import { ImageList } from 'src/shared/ui/_galleries/ImageList';
import { v4 } from 'uuid';
import * as yup from 'yup';
import { MAX_FILES } from '../../../const';
import { feedbackCategoryOptions } from '../../../meta/reportCategory';
import { actionsBugReportModal } from '../../../slice';
import { FormValues } from '../../../types';
import s from './CreateBugReport.module.scss';

interface Props {
	closeModal: () => void; // ! Так же очищает форму.
	toggleSuccessModal: () => void;
}

export const CreateBugReport: React.FC<Props> = props => {
	const {
		closeModal, //
		toggleSuccessModal,
	} = props;

	// * Actions
	const dispatch = useAppDispatch();
	const { addNotification } = actionsNotifications;
	const { setCategory, setComment, setImages } = actionsBugReportModal;

	const [postFeedbackCreate, { isLoading }] = usePostFeedbackCreateMutation();

	// * Selectors
	const category = useAppSelector(state => state.bugReportModal.category);
	const comment = useAppSelector(state => state.bugReportModal.comment);
	const images = useAppSelector(state => state.bugReportModal.images);

	// * Form
	const defaultValues: FormValues = {
		category,
		comment,
		images,
	};

	const schema = yup.object().shape({
		category: yup
			.object()
			.shape({
				id: yup.string().nullable(),
				name: yup.string().nullable(),
			})
			.nullable()
			.required('Выберите категорию'),
		comment: yup.string().nullable().max(1000).required('Заполните поле'),
	});

	const formMethods = useForm({
		defaultValues,
		resolver: yupResolver(schema),
	});

	const { handleSubmit, watch, setValue } = formMethods;

	const formCategory = watch('category');
	const formComment = watch('comment');
	const formImages = watch('images');

	useEffect(() => {
		dispatch(setCategory(formCategory));
		dispatch(setComment(formComment));
		dispatch(setImages(formImages));
	}, [formCategory, formComment, formImages.length]);

	const onSubmit = (data: FormValues) => {
		const preparedImages = data.images.map(item => ({
			name: item.name,
			content: item.content,
			extension: item.extension,
		}));

		postFeedbackCreate({
			createFeedbackRequest: {
				type: data.category?.id,
				content: data.comment,
				feedbackImages: preparedImages,
			},
		})
			.unwrap()
			.then(() => {
				toggleSuccessModal();
			})
			.catch(error => console.log(error));
	};

	// * Images
	const existedImages = watch('images');

	const onDrop = async (acceptedFiles: File[]) => {
		const files = acceptedFiles.map(file =>
			Object.assign(file, {
				id: `new_${v4()}`, // !
				parentId: null,
				preview: URL.createObjectURL(file),
			}),
		);

		Promise.all(files.map(file => galleyFileToBase64(file)))
			// TODO: Type values
			.then((values: any) => {
				const images = createImageInfoFromFile(values);

				if (existedImages.length + images.length <= MAX_FILES) {
					setValue('images', [...existedImages, ...images]);
				} else {
					dispatch(addNotification({ type: 'warning', message: `Превышено максимальное количество файлов. Максимум: ${MAX_FILES}` }));
				}
			});
	};

	const onDelete = (id: string) => {
		setValue(
			'images',
			existedImages.filter(item => item.id !== id),
		);
	};

	// * Render
	return (
		<div className={s.container}>
			<Heading
				level={2}
				marginBottom="m"
			>
				Обратная связь
			</Heading>

			<form
				className={s.form}
				onSubmit={handleSubmit(onSubmit)}
			>
				<FormProvider {...formMethods}>
					<div className={s.form_components}>
						<SelectSingleField
							className={s.category}
							name="category"
							label="Категория запроса"
							placeholder="Выберите категорию"
							options={feedbackCategoryOptions}
						/>

						<TextAreaField
							className={s.field}
							name="comment"
							label="Комментарий"
							placeholder="Введите комментарий"
							characterLimit={1000}
							size="small"
						/>

						<DropZone
							className={s.field}
							iconType="media"
							onDrop={onDrop}
							accept={{
								'image/jpeg': ['.jpeg'],
								'image/png': ['.png'],
								'image/gif': ['.gif'],
							}}
							maxFiles={MAX_FILES}
						/>

						<ImageList
							className={s.images}
							images={existedImages}
							onDelete={onDelete}
						/>
					</div>

					<ButtonPair
						primaryText="Отправить"
						primaryIsLoading={isLoading}
						secondaryText="Отменить"
						secondaryOnClick={closeModal}
						secondaryIsLoading={isLoading}
					/>
				</FormProvider>
			</form>
		</div>
	);
};
