import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button } from 'src/shared/ui/_buttons/Button/Button';
import { StringField } from 'src/shared/ui/_fields/StringField';
import * as yup from 'yup';
import { useCreatePosition } from '../../hooks/useCreatePosition';
import { useEditPosition } from '../../hooks/useEditPosition';
import s from './PositionForm.module.scss';

interface Props {
	id?: string;
	name?: string;
	onCloseModal: () => void;
}

export const PositionForm: React.FC<Props> = props => {
	const { id = '', name = '', onCloseModal } = props;

	// * Form
	const defaultValues = {
		name: name,
	};

	const schema = yup.object().shape({
		name: yup.string().required('Обязательное поле').nullable(),
	});

	const formMethods = useForm({
		defaultValues,
		resolver: yupResolver(schema),
	});

	const { handleSubmit, watch } = formMethods;

	const nameValue = watch('name');

	// * Submit
	const { onCreateSubmit, isCreateLoading } = useCreatePosition(onCloseModal);
	const { onEditSubmit, isEditLoading } = useEditPosition(id, name, onCloseModal);

	// * Render
	return (
		<div className={s.container}>
			<h2>{id ? 'Редактирование должности' : 'Новая должность'}</h2>

			<form onSubmit={handleSubmit(id ? onEditSubmit : onCreateSubmit)}>
				<FormProvider {...formMethods}>
					<StringField
						name="name"
						label="Название"
						placeholder="Введите название"
						characterLimit={80}
						maxLength={80}
						required
					/>

					<div className={s.buttons}>
						<Button
							fixedWidth
							variant="tertiary"
							onClick={onCloseModal}
						>
							Отмена
						</Button>

						<Button
							fixedWidth
							type="submit"
							variant="primary"
							isLoading={isCreateLoading || isEditLoading}
							disabled={!nameValue}
						>
							{id ? 'Сохранить' : 'Создать'}
						</Button>
					</div>
				</FormProvider>
			</form>
		</div>
	);
};
