import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { usePostBookingCreateMutation } from 'src/app/redux/queries/office-service/office_serviceAPI';
import { UserInfo } from 'src/app/redux/state/user/user/types';
import { generateExcludedDates } from 'src/pages/booking/lib';
import { injectTimeToDate } from 'src/shared/lib/date';
import { Button } from 'src/shared/ui/_buttons/Button/Button';
import { SwitchField } from 'src/shared/ui/_fields/SwitchField';
import { TextAreaField } from 'src/shared/ui/_fields/TextAreaField';
import { TimeRangeField } from 'src/shared/ui/_fields/TimeRangeField';
import * as yup from 'yup';
import { ModalHeader } from 'src/shared/ui/_modals/ModalHeader/ModalHeader';
import { SelectedCellInfo } from '../FullBookingMC/FullBookingMC';
import s from './ForbidFullBookingMC.module.scss';
import { ButtonPair } from 'src/entities/_buttons/ButtonPair';

interface Props {
	forbidInfo: SelectedCellInfo;
	userInfo: UserInfo;
	closeModal: () => void;
}

export const ForbidFullBookingMC: React.FC<Props> = props => {
	const {
		forbidInfo: { startTime, selectedDate, room, unavailableIntervals },
		userInfo,
		closeModal,
	} = props;

	// * API
	const [createBooking, { isLoading }] = usePostBookingCreateMutation();

	// * Exclude times
	const excludedTimes = generateExcludedDates(selectedDate, unavailableIntervals);

	// * Form
	const schema = yup.object().shape({
		comment: yup.string().nullable().required('Заполните комментарий'),
	});

	const formMethods = useForm({
		defaultValues: {
			isEndless: false,
			startTime,
			// startDate: selectedDate,
			endTime: null,
			// endDate: null
			comment: null,
		},
		resolver: yupResolver(schema),
	});

	const { handleSubmit, watch, setValue, setError, clearErrors } = formMethods;

	const isEndless = watch('isEndless');

	useEffect(() => {
		setValue('endTime', null);
	}, [isEndless]);

	const onSubmit = (values: any) => {
		const { isEndless, comment, startTime: startTimeValue, endTime: endTimeValue } = values;

		const noEndDate = !isEndless && !endTimeValue;

		if (noEndDate) {
			setError('endTime', { message: 'Введите время окончания бронирования' });
		} else {
			clearErrors();
		}

		const startTime = injectTimeToDate(selectedDate, startTimeValue);
		const endTime = endTimeValue && injectTimeToDate(selectedDate, endTimeValue);

		!noEndDate &&
			createBooking({
				createBookingRequest: {
					startTime: startTime.toLocaleString('sv'),
					endTime: endTime ? endTime.toLocaleString('sv') : null,
					userId: userInfo.user.id,
					parentId: room.id,
					isBan: true,
					comment,
				},
			})
				.unwrap()
				.then(() => closeModal())
				.catch(error => console.log('error', error));
	};

	// * Render
	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<FormProvider {...formMethods}>
				<ModalHeader
					title="Запрет бронирования"
					placeName={room.name}
				/>

				<div className={s.inner}>
					<SwitchField
						name="isEndless"
						text="Без даты окончания"
					/>

					<TimeRangeField
						firstName="startTime"
						secondName="endTime"
						labels={['начало', 'конец']}
						minTime={!(selectedDate.getDate() > new Date().getDate()) ? new Date() : undefined}
						excludeTimes={excludedTimes}
						disabledEnd={isEndless}
					/>

					<TextAreaField
						name="comment"
						label="Комментарий"
						placeholder="Введите комментарий"
						size="tiny"
					/>
				</div>

				<ButtonPair
					primaryText="Подтвердить"
					primaryIsLoading={isLoading}
					secondaryText="Отменить"
					secondaryOnClick={() => closeModal()}
					secondaryIsLoading={isLoading}
				/>
			</FormProvider>
		</form>
	);
};
