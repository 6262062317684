import { FC } from 'react';
import { ImageInfo } from 'src/app/redux/state/image/image/types';
import s from './Image.module.scss';
import { createGalleyFileFromImageInfo } from 'src/shared/ui/_galleries/Galley/_utils';
import { ReactComponent as DeleteSVG } from 'src/shared/assets/svg/action/delete.svg';

interface Props {
	image: ImageInfo;
	onDelete: (imageId: string) => void;
}

export const Image: FC<Props> = props => {
	const {
		image, //
		onDelete,
	} = props;

	const { preview } = createGalleyFileFromImageInfo(image);

	// * Render
	return (
		<div
			className={s.container}
			onClick={() => onDelete(image.id)}
		>
			<div className={s.overlay}>
				<DeleteSVG />
				<span className={s.imageName}>{image.name}</span>
			</div>

			<img
				className={s.image}
				src={preview}
				alt={image.name}
			/>
		</div>
	);
};
