import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'src/app/api/apiSlice';
import { ServiceName } from 'src/app/api/api_types';
import { EditFeedbackStatusesApiArg, CreateFeedbackReplyApiArg, CreateFeedbackReplyFileApiArg } from './types/reqTypes';
import { EditFeedbackStatusesApiResponse, CreateFeedbackReplyApiResponse, CreateFeedbackReplyFileApiResponse } from './types/resTypes';

const reducerPath = `${ServiceName.FEEDBACK_SERVICE}` as const;

export const feedback_serviceAPI = createApi({
	baseQuery: baseQueryWithReAuth,
	reducerPath,
	tagTypes: ['Feedback', 'FeedbackReply', 'Feedback file'] as const,
	endpoints: build => ({
		editFeedbackStatuses: build.mutation<EditFeedbackStatusesApiResponse, EditFeedbackStatusesApiArg>({
			query: queryArg => ({ url: `${reducerPath}/feedback/editstatus`, method: 'PUT', body: queryArg.editFeedbackStatusesRequest }),
			invalidatesTags: ['Feedback'],
		}),
		createFeedbackReply: build.mutation<CreateFeedbackReplyApiResponse, CreateFeedbackReplyApiArg>({
			query: queryArg => ({ url: `${reducerPath}/feedbackReply/create`, method: 'POST', body: queryArg.createFeedbackReplyRequest }),
			invalidatesTags: ['FeedbackReply'],
		}),
		createFeedbackReplyFile: build.mutation<CreateFeedbackReplyFileApiResponse, CreateFeedbackReplyFileApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/file/createforreply`,
				method: 'POST',
				body: queryArg.body,
				params: { feedbackReplyId: queryArg.feedbackReplyId },
			}),
			invalidatesTags: ['Feedback file'],
		}),
	}),
});

export const { useEditFeedbackStatusesMutation, useCreateFeedbackReplyMutation, useCreateFeedbackReplyFileMutation } = feedback_serviceAPI;
