import React, { ChangeEvent, HTMLAttributes, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { wiki_serviceAPI } from 'src/app/redux/queries/wiki-service/wiki_serviceAPI';
import { actionsWiki } from 'src/app/redux/state/wiki/slice';
import { useActions, useAppSelector } from 'src/app/redux/utils';
import { PageTitle } from 'src/entities/PageTitle/PageTitle';
import { useDebounceEffect } from 'src/pages/project_service_pages/hooks/useDebounceEffect';
import { ReactComponent as AddSVG } from 'src/shared/assets/svg/action/add.svg';
import { useRights } from 'src/shared/hooks/useRights';
import { ConditionalRendering } from 'src/shared/providers';
import { EmptyData } from 'src/shared/ui/EmptyData/EmptyData';
import { Heading } from 'src/shared/ui/Heading';
import { Button } from 'src/shared/ui/_buttons/Button/Button';
import { SearchBar } from 'src/shared/ui/_inputs/text_Inputs/SearchBar/SearchBar';
import { Modal } from 'src/shared/ui/_modals/Modal/ui/Modal/Modal';
import { OptionsWindow } from 'src/shared/ui/_option_lists/OptionsWindow/OptionsWindow';
import { IconWrapper } from 'src/shared/ui/_wrappers/IconWrapper';
import { ModalContentCreateNew } from 'src/pages/wiki/_common_components/modals/ModalCreateNew/ModalContentCreateNew';
import { Articles } from '../content/ContentMode/components/Articles/Articles';
import s from './WikiSearch.module.scss';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';
import { BooleanSwitch } from 'src/shared/ui/_switches/BooleanSwitch/BooleanSwitch';

interface Props extends HTMLAttributes<HTMLDivElement> {
	isPrivate: boolean;
}

export const WikiSearch: React.FC<Props> = props => {
	const { className, isPrivate, ...restDivProps } = props;

	// * Router
	const navigate = useNavigate();

	// * Selectors
	const editMode = useAppSelector(state => state.wiki.editModeWiki);
	const searchMode = useAppSelector(state => state.wiki.searchMode);
	const archiveMode = useAppSelector(state => state.wiki.archiveMode);

	// * API
	const [findArticles, { data: articlesData, isFetching: isFindLoading }] = wiki_serviceAPI.useLazyFindArticlesByContentQuery();
	const [findPublicArticles, { data: publicArticlesData, isFetching: isPublicFindLoading }] = wiki_serviceAPI.useLazyFindPublicArticlesByContentQuery();

	const searchResults = isPrivate ? articlesData?.body : publicArticlesData?.body;
	const isLoading = isFindLoading || isPublicFindLoading;

	// * Actions
	const { setEditModeWiki, setSearchMode, setArchiveMode } = useActions(actionsWiki);

	const getSearchResults = (searchString: string) => {
		const params = {
			skipCount: 0,
			takeCount: 1000,
			nameincludesubstring: searchString,
		};

		if (isPrivate) {
			return findArticles({
				...params,
				includedeactivated: false,
			});
		} else {
			return findPublicArticles({
				...params,
			});
		}
	};

	const exitSearchResultMode = () => {
		setEditModeWiki(false);
		setSearchMode(false);
		setArchiveMode(false);
		setSearchValue('');
		setShowOptions(false);
	};

	// * User actions
	// - Search
	const [searchValue, setSearchValue] = useState('');
	const [showOptions, setShowOptions] = useState(false);

	const onEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
		event.stopPropagation();

		if (event.key === 'Enter' && searchValue) {
			setSearchMode(true);
			setShowOptions(false);
		}
	};

	const onSearchValueChange = (event: ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		setSearchValue(value);
		!searchMode && setShowOptions(true);
	};

	useDebounceEffect(
		() => {
			if (!searchValue && !searchResults) return;

			if (searchMode && !searchValue) return setSearchMode(false);

			const promise = getSearchResults(searchValue);

			return () => {
				promise.abort();
			};
		},
		300,
		[searchValue],
	);

	/* Clear search on page change */
	useEffect(() => {
		return () => {
			exitSearchResultMode();
		};
	}, []);

	// - Edit mode
	const saveChanges = () => {
		setEditModeWiki(false);
	};

	// - Create rubric/sub-rubric/article
	const [modalActive, setModalActive] = useState(false);
	const toggleModal = () => setModalActive(prevState => !prevState);

	// * Conditions
	const allowedToEditWiki = useRights(13) && isPrivate;

	// * Toggle archive articles
	const handleChangeSwitch = (event: ChangeEvent<HTMLInputElement>) => {
		event.preventDefault;
		setArchiveMode(!archiveMode);
	};

	// * Title buttons
	const buttons = [
		{
			isVisible: allowedToEditWiki && editMode,
			button: (
				<Button
					key="1"
					variant="secondary"
					onClick={saveChanges}
				>
					Готово
				</Button>
			),
		},
		{
			isVisible: allowedToEditWiki && !editMode,
			button: (
				<Button
					key="2"
					variant="secondary"
					onClick={() => setEditModeWiki(true)}
				>
					Редактировать
				</Button>
			),
		},
		{
			isVisible: allowedToEditWiki,
			button: (
				<Button
					key="0"
					onClick={toggleModal}
				>
					<IconWrapper>
						<AddSVG />
					</IconWrapper>
				</Button>
			),
		},
	]
		.filter(btn => btn.isVisible)
		.map(btn => btn.button);

	// * Render
	return (
		<>
			{modalActive && (
				<ModalNewProto
					width="s"
					isOpen={modalActive}
					onClose={toggleModal}
				>
					<ModalContentCreateNew toggleModal={toggleModal} />
				</ModalNewProto>
			)}

			<PageTitle
				title="База знаний"
				buttons={buttons}
				titleComponent={
					editMode ? (
						<BooleanSwitch
							checked={archiveMode}
							onChange={handleChangeSwitch}
							text="Показать архив"
						/>
					) : undefined
				}
			/>

			{!editMode && (
				<>
					<div
						{...restDivProps}
						className={`${s.container} ${className}`}
					>
						<div
							className={s.search_bar}
							onFocus={() => searchValue && !searchMode && setShowOptions(true)}
							// onBlur={() => setShowOptions(false)}
						>
							<SearchBar
								placeholder="Задайте свой вопрос"
								value={searchValue}
								onChange={onSearchValueChange}
								onKeyDown={onEnter}
								active={!!searchValue}
								onActiveClick={exitSearchResultMode}
								loading={isLoading}
							/>
							{showOptions && searchValue && searchResults && (
								<div className={s.search_list}>
									<OptionsWindow
										keyNames={{
											name: 'name',
											value: 'id',
										}}
										options={searchResults}
										onOptionClick={option => navigate(option.id)}
									/>
								</div>
							)}
						</div>
					</div>
					{searchMode && searchResults && (
						<ConditionalRendering
							initialLoading={isLoading}
							isSuccessful={searchResults.length > 0}
							FailedResult={<EmptyData />}
							LoadedResult={
								<>
									<Heading
										level={5}
										className={s.search_results}
										marginBottom="s"
									>
										Всего результатов: {searchResults.length}
									</Heading>
									<Articles
										articles={searchResults}
										isPrivate={isPrivate}
									/>
								</>
							}
						/>
					)}
				</>
			)}
		</>
	);
};
