import { FC } from 'react';
import { PageDecorator } from 'src/app/decorators/page_decorators/PageDecorator/PageDecorator';
import { PageTitle } from 'src/entities/PageTitle/PageTitle';
import { FormsCard } from '../FormsCard/ui/FormsCard';
import { InfoCards } from '../InfoCards/InfoCards';
import s from './TimeTrackingPage.module.scss';

export const TimeTrackingPage: FC = () => {
	// * Render
	return (
		<PageDecorator>
			<PageTitle title="Учет времени" />

			<div className={s.container}>
				<FormsCard />
				<InfoCards />
			</div>
		</PageDecorator>
	);
};
