import React, { DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react';
import s from './ListCard.module.scss';
import cn from 'classnames';

interface Column {
	Value: ReactNode;
	width: number; // In percent
}

interface Props extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
	leftComponent?: {
		Component?: ReactNode;
		width: number;
	};
	rightComponent?: {
		Component?: ReactNode;
		width: number;
	};
	columns: Column[];
}

export const ListCard: React.FC<Props> = props => {
	const { leftComponent, rightComponent, columns, onClick, ...divProps } = props;

	const leftWidth = leftComponent?.width ?? 0;
	const rightWidth = rightComponent?.width ?? 0;

	const totalMarginsWidth = leftWidth + rightWidth;
	const totalColumnsWidth = columns.reduce((previousValue, currentValue) => previousValue + currentValue.width, 0);

	const totalWidth = totalMarginsWidth + totalColumnsWidth;

	const leftBasis = (leftWidth / totalWidth) * 100 + '%';
	const rightBasis = (rightWidth / totalWidth) * 100 + '%';

	// * Render
	return (
		<div
			{...divProps}
			className={cn(s.container, Boolean(onClick) && s.clickable)}
			onClick={onClick}
		>
			{leftComponent && (
				<div
					className={s.left}
					style={{
						flexBasis: leftBasis,
					}}
				>
					{leftComponent.Component}
				</div>
			)}

			<div className={s.inner}>
				{columns &&
					columns.map((column, index) => {
						const { width, Value } = column;

						return (
							<div
								key={index}
								style={{
									flexBasis: (width / totalWidth) * 100 + '%',
								}}
							>
								{Value}
							</div>
						);
					})}
			</div>

			{rightComponent && (
				<div
					className={s.right}
					style={{
						flexBasis: rightBasis,
					}}
				>
					{rightComponent.Component}
				</div>
			)}
		</div>
	);
};
