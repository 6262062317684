import { yupResolver } from '@hookform/resolvers/yup';
import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { RequestStatus } from 'src/app/api/api_types';
import { BookingFilter } from 'src/app/redux/state/office/_states/types';
import { officeServiceActions } from 'src/app/redux/state/office/actions';
import { useAppDispatch, useAppSelector } from 'src/app/redux/utils';
import { BOOKING_RULE_OPTIONS } from 'src/pages/offices/consts/consts';
import { Button } from 'src/shared/ui/_buttons/Button/Button';
import { DateAndTimeRangeField } from 'src/shared/ui/_fields/DateAndTimeRangeField';
import { SelectMultiField } from 'src/shared/ui/_fields/SelectMultiField';
import { SelectSingleField } from 'src/shared/ui/_fields/SelectSingleField';
import * as yup from 'yup';
import s from './BookingFilters.module.scss';

interface Props {
	officeId: string;
}

export const BookingFilters: React.FC<Props> = props => {
	const { officeId } = props;

	// * Actions
	const dispatch = useAppDispatch();
	const { findRoomTypes } = officeServiceActions.roomType.async;
	const { findRooms } = officeServiceActions.room.async;
	const { findTags } = officeServiceActions.tag.async;

	// * Selectors
	const roomTypes = useAppSelector(state => state.office_service.roomType.roomTypes);
	const allTags = useAppSelector(state => state.office_service.tag.allTags);
	const status = useAppSelector(state => state.office_service.room.status);

	// * Initialize
	useEffect(() => {
		officeId &&
			dispatch(
				findRoomTypes({
					params: {
						skipCount: 0,
						takeCount: 1000,
						includeDeactivated: false,
						officeId: officeId,
					},
				}),
			);

		officeId &&
			dispatch(
				findRooms({
					params: {
						skipCount: 0,
						takeCount: 1000,
						officeId: officeId,
					},
				}),
			);
	}, [officeId]);

	useEffect(() => {
		dispatch(
			findTags({
				params: {
					nameIncludeSubstring: '',
					takeCount: 200,
					skipCount: 0,
				},
			}),
		);
	}, []);

	// * Options
	const roomTypeValues = roomTypes?.map(type => {
		const { id, name } = type;

		return {
			id: id,
			name: name,
			bookingRule: type.bookingRule,
		};
	});

	const bookingRuleValues = BOOKING_RULE_OPTIONS.map(rule => {
		const { name, value } = rule;

		return {
			id: value,
			name: name,
		};
	});

	// * Form
	const defaultValues: BookingFilter = {
		type: { id: null, name: null },
		rule: { id: null, name: null },
		date: '',
		startTime: '',
		endTime: '',
		features: [],
	};

	const schema = yup.object().shape({
		type: yup
			.object()
			.shape({
				id: yup.string().nullable(),
				name: yup.string().nullable(),
			})
			.nullable(),
		rule: yup
			.object()
			.shape({
				id: yup.string().nullable(),
				name: yup.string().nullable(),
			})
			.nullable(),
	});

	const formMethods = useForm({
		defaultValues,
		resolver: yupResolver(schema),
	});

	const { handleSubmit, reset, watch, setValue } = formMethods;

	const currentRule = watch('rule');

	useEffect(() => {
		if (currentRule.id) {
			setValue('type', { id: null, name: null });
		}
	}, [currentRule]);

	const onSubmit = (data: any) => {
		const dateValue = DateTime.fromJSDate(new Date(data.date)).toISODate();

		const params = {
			skipCount: 0,
			takeCount: 1000,
			officeId: officeId,
			roomTypeId: data.type?.id || '',
			bookingRule: data.rule?.id || '',
		};

		if (dateValue) {
			const preparedStartTime = `${dateValue}T${data.startTime ? data.startTime : '00:00:00'}`;
			const preparedEndTime = data.endTime ? `${dateValue}T${data.endTime}` : `${DateTime.fromJSDate(new Date(data.date)).plus({ day: 1 }).toISODate()}T00:00:00`;

			Object.assign(params, {
				startTime: preparedStartTime,
				endTime: preparedEndTime,
			});
		}

		if (data.features.length) {
			const allTags = data.features.map((tag: { id: string }) => tag.id);
			const allTagsParam = allTags
				.map((tag: any, index: number) => {
					if (index === 0) {
						return `${tag}`;
					} else {
						return `&tags[]=${tag}`;
					}
				})
				.join('');

			Object.assign(params, {
				'tags[]': allTagsParam,
			});
		}

		if (officeId) {
			dispatch(
				findRooms({
					params,
				}),
			);
		}
	};

	// * Reset filters
	const resetFilters = () => {
		reset(() => defaultValues);
	};

	useEffect(() => {
		resetFilters();
	}, [officeId]);

	// * Render
	return (
		<form
			className={s.container}
			onSubmit={handleSubmit(onSubmit)}
		>
			<FormProvider {...formMethods}>
				<h3>Фильтры</h3>
				<div className={s.all_filters}>
					<div className={s.first_row}>
						<SelectSingleField
							name="rule"
							label="Правило бронирования"
							placeholder="Выберите из списка"
							options={bookingRuleValues}
						/>

						<SelectSingleField
							name="type"
							label="Тип помещения"
							placeholder="Выберите тип помещения"
							options={roomTypeValues.filter(type => {
								if (currentRule.id) {
									return type.bookingRule === currentRule.id;
								} else {
									return true;
								}
							})}
						/>
					</div>

					<div className={s.second_row}>
						<DateAndTimeRangeField
							dateName="date"
							startTimeName="startTime"
							endTimeName="endTime"
							label="Дата и время"
						/>
					</div>

					<div className={s.third_row}>
						<SelectMultiField
							name="features"
							label="Характеристики"
							placeholder="Выберите из списка"
							options={allTags}
						/>
					</div>

					<div className={s.buttons}>
						<Button
							wide
							variant="tertiary"
							onClick={resetFilters}
							isLoading={status === RequestStatus.loading}
							type="button"
						>
							Сбросить фильтры
						</Button>

						<Button
							type="submit"
							wide
							isLoading={status === RequestStatus.loading}
						>
							Применить
						</Button>
					</div>
				</div>
			</FormProvider>
		</form>
	);
};
