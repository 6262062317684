import React, { createRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageDecorator } from 'src/app/decorators/page_decorators/PageDecorator/PageDecorator';
import { event_serviceAPI, useGetEventFindQuery } from 'src/app/redux/queries/event-service/event_serviceAPI';
import { routerPath } from 'src/app/router/paths';
import { PageTitle } from 'src/entities/PageTitle/PageTitle';
import { ReactComponent as CalendarSVG } from 'src/shared/assets/svg/action/calendar.svg';
import { ReactComponent as BulletedListSVG } from 'src/shared/assets/svg/bulleted_list.svg';
import { Button } from 'src/shared/ui/_buttons/Button/Button';
import { DAY_OF_THE_WEEK } from '../../../../consts';
import { Calendar } from '../Calendar';
import { DayEventsInfoList } from '../DayEventsInfoList';
import { EventList } from '../EventList/ui/EventList/EventList';
import s from './EventCalendarPage.module.scss';
import { dateToBackendISO, isDateInRange, isDateTheSame } from 'src/shared/lib/date';
import { isDateInRangeEvent } from '../../lib';
import { useSelector } from 'react-redux';
import { RootState } from 'src/app/redux/store';

export interface DateTypeProps {
	day: string | number;
	dayName: string;
	month: string | number;
}

export const EventCalendarPage: React.FC = () => {
	// * Selectors
	const userInfo = useSelector((state: RootState) => state.user_service.user.userInfo?.user);

	// * States
	const [pageType, setPageType] = useState<boolean>(true);
	const [month, setMonth] = useState(new Date().getMonth());
	const [year, setYear] = useState(new Date().getFullYear());

	const [selectedDate, setSelectedDate] = useState<Date>(new Date());

	// * API
	const { data: eventList } = useGetEventFindQuery({
		takecount: 100,
		skipcount: 0,
		access: 'Closed',
		startTime: dateToBackendISO(new Date(year, month - 1, 1)) ?? new Date().toISOString(),
		endTime: dateToBackendISO(new Date(year, month + 2, 1)) ?? new Date().toISOString(),
		userId: userInfo?.id,
	});

	const { data: birthdaysList } = event_serviceAPI.useGetUserBirthdayFindQuery({
		skipcount: 0,
		takecount: 100,
		startTime: dateToBackendISO(new Date(year, month, 1)) ?? new Date().toISOString(),
		endTime: dateToBackendISO(new Date(year, month + 1, 0)) ?? new Date().toISOString(),
	});

	const navigate = useNavigate();
	const goToCreateEvent = () => navigate(routerPath.calendar.page + routerPath.calendar.newEvent);

	const previousMonth = () => {
		return setMonth(prevState => {
			if (prevState - 1 >= 0) {
				return prevState - 1;
			} else {
				setYear(prevStateYear => prevStateYear - 1);
				return 11;
			}
		});
	};

	const nextMonth = () => {
		return setMonth(prevState => {
			if (prevState + 1 < 12) {
				return prevState + 1;
			} else {
				setYear(prevStateYear => prevStateYear + 1);
				return 0;
			}
		});
	};

	// - data
	const allEvents = eventList?.body || [];
	const allBirthdays = birthdaysList?.body || [];

	return (
		<PageDecorator>
			<div className={s.container}>
				<PageTitle
					title="Календарь событий"
					isBeta
					buttons={[
						<Button
							key={0}
							onClick={() => setPageType(!pageType)}
							LeftIcon={pageType ? <BulletedListSVG /> : <CalendarSVG />}
							variant="tertiary"
							className={s.button}
						>
							{pageType ? 'К списку' : 'К календарю'}
						</Button>,
						<Button
							key={1}
							onClick={goToCreateEvent}
						>
							Создать
						</Button>,
					]}
				/>
				<div className={s.body}>
					{pageType ? (
						<div className={s.pageLayout}>
							<div className={s.calendar}>
								<Calendar
									events={allEvents}
									birthdays={allBirthdays}
									month={month}
									year={year}
									onNextMonth={nextMonth}
									onPreviousMonth={previousMonth}
									selectedDate={selectedDate}
									onChangeDate={setSelectedDate}
								/>
							</div>

							<DayEventsInfoList
								date={selectedDate}
								events={allEvents.filter(event => isDateInRangeEvent(selectedDate, new Date(event.date), event.endDate ? new Date(event.endDate) : null))}
								birthdays={allBirthdays.filter(day => isDateTheSame(selectedDate, new Date(day.dateOfBirth)))}
							/>
						</div>
					) : (
						<EventList />
					)}
				</div>
			</div>
		</PageDecorator>
	);
};
