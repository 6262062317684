import { ImageContent } from 'src/app/redux/queries/survey-service/types';
import { GalleyFile } from 'src/shared/ui/_galleries/Galley/_types';
import { v4 } from 'uuid';
import { base64InfoToString } from '../base64InfoToString/base64InfoToString';
import { base64ToBlob } from '../base64ToBlob/base64ToBlob';
import { extensionToContentType } from '../extensionToContentType/extensionToContentType';
import { isValidBase64DataString } from '..';

export const imageContentToGalleyFile = (imageContent: ImageContent): GalleyFile => {
	const contentType = extensionToContentType(imageContent.extension, 'image');
	const base64String = isValidBase64DataString(imageContent.content) ? imageContent.content : base64InfoToString(contentType, imageContent.content);

	const blob = base64ToBlob(base64String);
	const file = new File([blob], imageContent.name ? imageContent.name : 'unnamed');

	return Object.assign(file, {
		id: `new_${v4()}`,
		parentId: null,
		preview: URL.createObjectURL(blob),
	});
};
