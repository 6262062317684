import { ContractTerm } from 'src/app/redux/queries/user-service/types/types';
import { user_serviceAPI } from 'src/app/redux/queries/user-service/user_serviceAPI';
import { COMPANY_ID } from 'src/shared/consts/consts';
import { dateToBackendISO } from 'src/shared/lib/date';
import { DefaultValues } from '../types';

export const useCreateUser = (onCloseModal: () => void) => {
	// * API
	const [createUser, { isLoading: isCreateLoading }] = user_serviceAPI.usePostUserCreateMutation();

	// * Submit
	const onCreateSubmit = (data: DefaultValues) => {
		const {
			firstName,
			lastName,
			email,
			isAdmin,
			birthday,
			contract,
			contractByTerm,
			department,
			middleName,
			position,
			dateEndContract,
			dateEndProbation,
			rate,
			role,
			startWorkingDate,
		} = data;

		if (!email) return; // TODO

		const dateOfBirth = birthday ? dateToBackendISO(birthday) : null;
		const endWorkingAt = dateEndContract ? dateToBackendISO(dateEndContract) : null;
		const probationDate = dateEndProbation ? dateToBackendISO(dateEndProbation) : undefined;
		const startWorkingAt = startWorkingDate ? dateToBackendISO(startWorkingDate) : undefined;

		createUser({
			createUserRequest: {
				firstName,
				lastName,
				middleName,
				communication: {
					type: 'Email',
					value: email,
				},
				dateOfBirth,
				departmentId: department.id || undefined,
				isAdmin,
				positionId: position.id || undefined,
				roleId: role.id || undefined,
				userCompany: {
					companyId: COMPANY_ID,
					contractSubjectId: contract.id,
					contractTermType: contractByTerm.id as ContractTerm,
					rate: Number(rate.id),
					probation: probationDate,
					endWorkingAt,
					startWorkingAt,
				},
			},
		})
			.unwrap()
			.then(() => {
				onCloseModal();
			})
			.catch(err => console.log(err));
	};

	return { onCreateSubmit, isCreateLoading };
};
