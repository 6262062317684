import { useNavigate, useParams } from 'react-router-dom';
import { useGetDepartmentGetQuery } from 'src/app/redux/queries/department-service/department_serviceAPI';
import { Button } from 'src/shared/ui/_buttons/Button/Button';
import { LineTabs } from 'src/shared/ui/_tabs/lineTabs';
import { PageDecorator } from 'src/app/decorators/page_decorators/PageDecorator/PageDecorator';
import { PageTitle } from 'src/entities/PageTitle/PageTitle';
import { routerPath } from 'src/app/router/paths';
import { ConditionalRendering } from 'src/shared/providers';
import { AddEditDepartmentButton } from '../AddEditDepartmentButton/ui/AddEditDepartmentButton/AddEditDepartmentButton';
import { Projects } from '../Projects/Projects';
import { Team } from '../Team';
import s from './DepartmentPage.module.scss';
import { useRights } from 'src/shared/hooks/useRights';
import { useAppSelector } from 'src/app/redux/utils';

export const DepartmentPage = () => {
	// * Routing
	const { departmentId, tab } = useParams() as { departmentId: string; tab: 'projects' | 'team' };
	const navigate = useNavigate();

	const toTimeList = () => navigate(`${routerPath.departments.page}${routerPath.departments.timeList}/${departmentId}`);

	// * Selectors
	const departmentUser = useAppSelector(state => state.user_service.user.userInfo?.departmentUser);

	// * Rights
	const departmentRightsOrAdmin = useRights(4);
	const departmentManager = departmentUser?.role === 'Manager' && departmentUser?.department.id === departmentId;

	// * API
	const { data, isLoading } = useGetDepartmentGetQuery({
		departmentid: departmentId,
	});

	const department = data?.body;

	// * Tabs
	const TABS = [
		{
			id: 'team',
			name: 'Команда',
		},
		{
			id: 'projects',
			name: 'Проекты',
		},
	];

	const setActiveTab = (tab: string) => {
		navigate(`${routerPath.departments.page}${routerPath.departments.singleDepartment}/${departmentId}/${tab}`);
	};

	// * Title buttons
	const buttons = [];

	const TimeListButton = (
		<Button
			key="1"
			onClick={toTimeList}
		>
			Таймлист
		</Button>
	);

	if (departmentRightsOrAdmin && department) {
		buttons.push([
			<AddEditDepartmentButton
				key="0"
				department={{
					...department,
					isActive: department.isActive
						? {
								id: 'true',
								name: 'Активный',
							}
						: {
								id: 'false',
								name: 'Архивированный',
							},
				}}
			/>,
			TimeListButton,
		]);
	} else if (departmentManager) {
		buttons.push(TimeListButton);
	}

	// * Render
	return (
		<PageDecorator>
			<ConditionalRendering
				initialLoading={isLoading}
				isSuccessful={!!department}
				LoadedResult={
					<>
						{department && (
							<PageTitle
								title={department.name ?? ''}
								goBackRoute={{
									text: 'Департаменты',
									route: routerPath.departments.page,
								}}
								buttons={buttons}
							/>
						)}

						<LineTabs
							className={s.tabs}
							tabs={TABS}
							activeTabId={tab}
							setActiveTabId={setActiveTab}
						/>

						{tab === 'team' ? <Team departmentId={departmentId} /> : <Projects departmentId={departmentId} />}
					</>
				}
			/>
		</PageDecorator>
	);
};
