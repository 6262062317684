import { FC } from 'react';
import { image_serviceAPI } from 'src/app/redux/queries/image-serviceAPI/image_serviceAPI';
import { ReactComponent as DocSVG } from 'src/shared/assets/svg/files/doc.svg';
import { imageContentToGalleyFile } from 'src/shared/lib/file';
import { IconButton } from 'src/shared/ui/_buttons/IconButton/IconButton';

interface Props {
	id: string;
}

export const ImageButton: FC<Props> = props => {
	const { id } = props;

	// * API
	const { currentData: imageInfo } = image_serviceAPI.useGetImageQuery({
		params: {
			imageId: id as string,
			source: 'Education',
		},
	});

	const image =
		imageInfo?.body &&
		imageContentToGalleyFile({
			...imageInfo.body,
			name: imageInfo.body.name || 'image.jpg',
		}).preview;

	const openImage = () => {
		window.open(image, '_blank');
	};

	// * Render
	return (
		<IconButton
			Icon={<DocSVG />}
			onClick={openImage}
		/>
	);
};
