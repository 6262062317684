import React from 'react';
import s from './RemoveModalContent.module.scss';
import { Button } from 'src/shared/ui/_buttons/Button/Button';
import { company_structure_serviceAPI } from 'src/app/redux/queries/company-structure-service/company_structure_serviceAPI';

interface Props {
	unit?: boolean;
	id: string;
	name: string;
	toggleRemoveModal: () => void;
}

export const RemoveModalContent: React.FC<Props> = props => {
	const { unit = false, id, name, toggleRemoveModal } = props;

	// * ?
	const [editDepartment] = company_structure_serviceAPI.useEditDepartmentMutation();
	const [editUnit] = company_structure_serviceAPI.useEditUserMutation();

	// * Remove unit or administration
	// TODO remove admin
	const handleRemove = () => {
		if (unit) {
			editDepartment({
				departmentId: id,
				payload: [
					{
						op: 'replace',
						path: '/isActive',
						value: false,
					},
				],
			})
				.unwrap()
				.then(() => toggleRemoveModal())
				.catch(error => console.log('error: ', error));

			return;
		}

		editUnit({
			userId: id,
			payload: [
				{
					op: 'replace',
					path: '/isActive',
					value: false,
				},
			],
		});
	};

	return (
		<div className={s.modal_content}>
			{unit ? (
				<h3 className={s.modal_title}>
					Удаление
					<br />
					организационной единицы
				</h3>
			) : (
				<h3 className={s.modal_title}>Удаление администратора</h3>
			)}

			<div className={s.modal_description}>
				Вы действительно хотите удалить{' '}
				{unit ? (
					<>
						<br />
						организационную единицу
					</>
				) : (
					'администратора'
				)}
				<br />
				<span className={s.remove_name}>{name}</span>?
			</div>

			<div className={s.modal_buttons}>
				<Button
					wide
					variant="tertiary"
					onClick={event => {
						event.preventDefault();
						event.stopPropagation();
						toggleRemoveModal();
					}}
				>
					Отменить
				</Button>

				<Button
					wide
					variant="primary"
					onClick={event => {
						event.preventDefault();
						event.stopPropagation();
						handleRemove();
					}}
				>
					Удалить
				</Button>
			</div>
		</div>
	);
};
