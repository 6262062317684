import React, { ChangeEvent, useState } from 'react';
import { ImageInfo } from 'src/app/redux/queries/user-service/types/types';
import { useAppSelector } from 'src/app/redux/utils';
import { Avatar } from 'src/shared/ui/Avatar/Avatar';
import { Checkbox } from 'src/shared/ui/_inputs/Checkbox/Checkbox';
import { Switch } from 'src/shared/ui/_switches/Switch/Switch';
import { CardsTable } from 'src/shared/ui/_tables/CardsTable';
import s from './AddUserList.module.scss';
import { COLUMNS } from './consts';
import { UserList } from '../../UsersList/UsersList';

interface Props {
	users: Array<{ id: string; name: string; department: string; avatar: ImageInfo | undefined }>;
	selectedUsers: string[];
	setSelectedUsers: (selectedUsers: string[]) => void;
	addSelectedFullUser: (user: UserList) => void;
	deleteSelectedFullUser: (user: string) => void;
	addToEvent?: boolean;
}

export const AddUserList: React.FC<Props> = props => {
	const {
		users, //
		selectedUsers,
		setSelectedUsers,
		addSelectedFullUser,
		deleteSelectedFullUser,
		addToEvent,
	} = props;

	// * Selector
	const companyUsers = useAppSelector(state => state.user_service.user.companyUsers);

	// * Company users id
	const companyUsersId =
		companyUsers?.map(companyUser => {
			const { body } = companyUser;
			const { user } = body;
			return user.id;
		}) || [];

	const addNameUser = (selectedUserId: string) => {
		setSelectedUsers([...selectedUsers, selectedUserId]);
	};

	const deleteNameUser = (selectedUserId: string) => {
		setSelectedUsers(selectedUsers.filter(id => id !== selectedUserId));
	};

	// * Switch
	const [isSelected, setIsSelected] = useState<boolean>(false);

	const handleChangeSwitch = (event: ChangeEvent<HTMLInputElement>) => {
		event.stopPropagation();
		setIsSelected(prevState => !prevState);
	};

	// * Table
	const data = users
		.filter(user => {
			if (!isSelected) return user;
			return selectedUsers.find(item => item === user.id);
		})
		.map(user => {
			const checked = selectedUsers.includes(user.id);

			return {
				checked,
				avatar: (
					<div className={s.user_checkbox}>
						<Checkbox
							checked={selectedUsers.includes(user.id)}
							onChange={() => {
								if (!checked) {
									addToEvent && addSelectedFullUser(user);

									addNameUser(user.id);
								} else {
									addToEvent && deleteSelectedFullUser(user.id);

									deleteNameUser(user.id);
								}
							}}
						/>

						<Avatar
							name={user.name}
							userAvatar={user.avatar}
						/>
					</div>
				),
				name: user.name,
				department: user.department,
			};
		});

	// * Render
	return (
		<>
			<div className={s.selected_block}>
				<h5 className={s.selected_text}>Выбрано сотрудников: {selectedUsers.length}</h5>

				<div className={s.selected_switch}>
					<div className={s.switch_text}>Просмотреть выбранных</div>

					<Switch
						checked={isSelected}
						onChange={handleChangeSwitch}
						disabled={selectedUsers.length === 0}
					/>
				</div>
			</div>

			<div className={s.table}>
				{companyUsersId && (
					<CardsTable
						columns={COLUMNS}
						data={data}
					/>
				)}
			</div>
		</>
	);
};
