import React from 'react';
import { useAppDispatch, useAppSelector } from 'src/app/redux/utils';
import { BugReportModal } from 'src/features/_layout/Header/ReportBug/ui/BugReportModal';
import { ReactComponent as BugSVG } from 'src/shared/assets/svg_icons/side_menu/bug.svg';
import { Button } from 'src/shared/ui/_buttons/Button/Button';
import { actionsBugReportModal } from '../../slice';

export const ReportBug: React.FC = () => {
	// * Actions
	const dispatch = useAppDispatch();
	const { setShowModal } = actionsBugReportModal;

	// * Selectors
	const showModal = useAppSelector(state => state.bugReportModal.showModal);

	const toggleModal = () => {
		dispatch(setShowModal(!showModal));
	};

	// * Render
	return (
		<>
			<BugReportModal
				showModal={showModal}
				setShowModal={() => dispatch(setShowModal(!showModal))}
			/>

			<div>
				<Button
					variant="tertiary"
					LeftIcon={<BugSVG />}
					onClick={toggleModal}
				>
					Сообщить об ошибке
				</Button>
			</div>
		</>
	);
};
