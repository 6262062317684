import { FC } from 'react';
import { useGetUserFindQuery } from 'src/app/redux/queries/user-service/user_serviceAPI';
import { Avatar } from 'src/shared/ui/Avatar/Avatar';
import { Checkbox } from 'src/shared/ui/_inputs/Checkbox/Checkbox';
import { ConditionalRendering } from 'src/shared/providers/ConditionalRendering/ConditionalRendering';
import { CardsTable } from 'src/shared/ui/_tables/CardsTable';
import { COLUMNS } from '../consts';
import { UserFindResponse } from 'src/app/redux/queries/user-service/types/resTypes';

interface Props {
	filteredUsers: UserFindResponse[];
	selectedUsers: string[];
	setSelectedUsers: React.Dispatch<React.SetStateAction<string[]>>;
}

export const UsersTable: FC<Props> = props => {
	const { filteredUsers, selectedUsers, setSelectedUsers } = props;

	const { data, isLoading } = useGetUserFindQuery({
		skipcount: 0,
		takecount: 1000,
		includedepartments: true,
		isactive: true,
		includecurrentavatar: true,
	});

	const users = data?.body;

	const isLoaded = !!users;

	const tableData = filteredUsers.map(({ user, departmentUser }) => {
		const fullName = `${user.firstName ?? ''} ${user.middleName ?? ''} ${user.lastName ?? ''}`;

		return {
			Checkbox: (
				<Checkbox
					checked={selectedUsers.includes(user.id)}
					onClick={() => setSelectedUsers(prevState => (prevState.includes(user.id) ? selectedUsers.filter(userId => userId !== user.id) : [...prevState, user.id]))}
				/>
			),
			Name: <div>{fullName}</div>,
			Avatar: (
				<Avatar
					name={fullName}
					userAvatar={user.avatar}
				/>
			),
			department: departmentUser?.department.name,
		};
	});

	return (
		<ConditionalRendering
			initialLoading={isLoading}
			isSuccessful={isLoaded}
			LoadedResult={
				<>
					{isLoaded && tableData.length > 0 && (
						<CardsTable
							data={tableData}
							columns={COLUMNS}
						/>
					)}
				</>
			}
		/>
	);
};
