import { ComponentPropsWithoutRef, FC } from 'react';
import { ImageInfo } from 'src/app/redux/state/image/image/types';
import s from './ImageList.module.scss';
import { Image } from '../Image/Image';
import cn from 'classnames';

interface Props extends ComponentPropsWithoutRef<'div'> {
	images: ImageInfo[];
	onDelete: (imageId: string) => void;
}

export const ImageList: FC<Props> = props => {
	const {
		images, //
		onDelete,
		className,
	} = props;

	return (
		<div className={cn(s.container, className)}>
			{images.map(imageInfo => (
				<Image
					key={imageInfo.id}
					image={imageInfo}
					onDelete={onDelete}
				/>
			))}
		</div>
	);
};
