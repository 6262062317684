import React from 'react';
import s from './ModalContent.module.scss';
import { Button } from 'src/shared/ui/_buttons/Button/Button';

interface Props {
	title: string;
	subtitle: string;
	buttonText: string;
	closeModalText?: string;
	isLoading?: boolean;

	action: () => void;
	onClose?: () => void;
}

export const ModalContent: React.FC<Props> = props => {
	const {
		title,
		subtitle,
		buttonText,
		closeModalText = 'Отмена',
		isLoading,

		action,
		onClose,
	} = props;

	const hasCancel = !!onClose;

	// * Render
	return (
		<div className={s.container}>
			<h2 className={s.title}>{title}</h2>

			<p className={s.text}>{subtitle}</p>

			<div className={s.buttons}>
				{hasCancel && (
					<Button
						wide
						variant="tertiary"
						isLoading={isLoading}
						onClick={e => {
							e.preventDefault();
							e.stopPropagation();
							onClose();
						}}
					>
						{closeModalText}
					</Button>
				)}

				<Button
					wide
					variant={hasCancel ? 'primary' : 'secondary'}
					isLoading={isLoading}
					onClick={e => {
						e.preventDefault();
						e.stopPropagation();
						action();
					}}
				>
					{buttonText}
				</Button>
			</div>
		</div>
	);
};
