import React from 'react';
import { ReactComponent as AddSVG } from 'src/shared/assets/svg/action/add.svg';
import { ReactComponent as RemoveSVG } from 'src/shared/assets/svg/action/delete.svg';
import { Button } from 'src/shared/ui/_buttons/Button/Button';
import { DatePickerInput } from 'src/shared/ui/_inputs/date_pickers/DatePickerInput/DatePickerInput';
import { SelectSingleCustomValue } from 'src/shared/ui/_inputs/selects/SelectSingleCustomValue/SelectSingleCustomValue';
import { TextArea } from 'src/shared/ui/_inputs/text_Inputs/TextArea/TextArea';
import { TextInput } from 'src/shared/ui/_inputs/text_Inputs/TextInput/TextInput';
import { FieldBase, SectionTitle } from '../../pages/Offer/Offer';
import s from './InputsBlock.module.scss';

interface InputsBlockProps extends SectionTitle {
	renderData: FieldBase[];
	onChange: (section: string, index: number, value: string, empty: boolean) => void;
}

export const InputsBlock: React.FC<InputsBlockProps> = props => {
	const {
		isEditable, //
		title,
		renderData,
		addField,
		removeField,
		nameForField,
		onChange,
	} = props;

	const lastBlock = renderData.length === 1;

	const renderFields = (field: FieldBase, index: number, isBoned = false) => {
		const key = `${field.name}[${index}].name${isBoned}`; // TODO: Переписать! Необоснованно сложный.

		const fieldProps = {
			label: field.label,
			placeholder: field.placeholder,
			value: field.value,
		};

		switch (field.type) {
			case 'textInput':
				return (
					<TextInput
						{...fieldProps}
						key={key}
						onChange={e => onChange(nameForField, index, e.target.value, false)}
					/>
				);

			case 'numberInput':
				return (
					<TextInput
						{...fieldProps}
						key={key}
						type="number"
						onChange={e => onChange(nameForField, index, e.target.value, false)}
					/>
				);

			case 'selectInput':
				return (
					<>
						{field.options && (
							<SelectSingleCustomValue
								{...fieldProps}
								searchSubstring={field.value}
								onStringChange={value => onChange(nameForField, index, value, false)}
								setSelectedOption={option => onChange(nameForField, index, option.value, false)}
								selectedOption={field.options.find(option => option.value === field.value) ?? { name: field.value, value: field.value }}
								options={field.options}
								keyNames={{ name: 'name', value: 'value' }}
							/>
						)}
					</>
				);

			case 'dateInput':
				return (
					<DatePickerInput
						{...fieldProps}
						selectedDate={new Date(field.value)}
						setSelectedDate={date => date && onChange(nameForField, index, date.toDateString(), false)}
					/>
				);

			case 'textArea':
				return (
					<TextArea
						{...fieldProps}
						Icon={isEditable && !lastBlock && <RemoveSVG />}
						onIconClicked={() => deleteItemFromArray(nameForField, index)}
						key={key}
						onChange={e => onChange(nameForField, index, (e.target as HTMLTextAreaElement).value, false)}
						size={field.size as 'small' | 'large' | 'tiny'}
					/>
				);
		}
	};

	const renderRows = (fieldSection: FieldBase[]) => {
		return fieldSection.map((field, index) => {
			return (
				<div
					className={s.row}
					key={`${field.name}[${index}].name`}
				>
					{renderFields(field, index)}
				</div>
			);
		});
	};

	const deleteItemFromArray = (section: string, index: number) => {
		removeField(section, index);
	};

	const addNewField = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();

		addField(nameForField, {
			type: 'textArea',
			name: nameForField,
			value: '',
			size: 'small',
			placeholder: 'Заполните поле',
		});
	};

	return (
		<div
			className={s.container}
			key={title}
		>
			<h2 className={s.title}>{title}</h2>
			<div className={s.fields}>{renderRows(renderData)}</div>
			{isEditable && (
				<Button
					variant="secondary"
					LeftIcon={<AddSVG />}
					wide={true}
					type="button"
					onClick={addNewField}
				>
					Добавить пункт
				</Button>
			)}
		</div>
	);
};
