import { yupResolver } from '@hookform/resolvers/yup';
import React, { ChangeEvent, useEffect, useMemo, useRef } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { rights_serviceAPI } from 'src/app/redux/queries/rights-service/rights_serviceAPI';
import { Checkbox } from 'src/shared/ui/_inputs/Checkbox/Checkbox';
import { Button } from 'src/shared/ui/_buttons/Button/Button';
import { StringField } from 'src/shared/ui/_fields/StringField';
import { InputLabel } from 'src/shared/ui/_inputs/_shared/InputLabel/InputLabel';
import { LoaderCircle } from 'src/shared/ui/_loaders/LoaderCircle/LoaderCircle';
import * as yup from 'yup';
import { useCreateRole } from '../../hooks/useCreateRole';
import { useEditRole } from '../../hooks/useEditRole';
import s from './RoleForm.module.scss';

interface Props {
	roleId?: string;
	localizationId?: string;
	name?: string;
	description?: string;
	roleRights?: string[];
	onCloseModal: () => void;
}

export const RoleForm: React.FC<Props> = props => {
	const { roleId = '', localizationId = '', name = '', description = '', roleRights, onCloseModal } = props;

	// * API
	const { data: rightsData, isLoading } = rights_serviceAPI.useGetRightsListQuery({
		locale: 'ru',
	});

	// * Rights
	const rights = useMemo(() => {
		return (
			rightsData?.body?.map(right => ({
				id: `${right.rightId}`,
				name: right.name,
			})) ?? []
		);
	}, [rightsData]);

	// * Form
	const defaultValues = {
		name: name,
		description: description,
		rights: roleRights || ([] as string[]),
	};

	const schema = yup.object().shape({
		name: yup.string().required('Обязательное поле').nullable(),
		description: yup.string().nullable(),
		rights: yup.array().of(yup.string()).min(1, 'Выберите хотя бы одно право'),
	});

	const formMethods = useForm({
		defaultValues,
		resolver: yupResolver(schema),
	});

	const { handleSubmit, control, setValue, watch, setError, formState } = formMethods;

	const error_rights = formState.errors.rights;
	const error_name = formState.errors.name;

	const nameValue = watch('name');
	const rightsIds = watch('rights');

	// scroll to start form
	useEffect(() => {
		const startForm = document.getElementById('start_form');

		if (error_name && startForm) {
			startForm.scrollIntoView({ block: 'start', behavior: 'smooth' });
		}
	}, [error_name]);

	// - Change rights
	const changeRights = (e: ChangeEvent<HTMLInputElement>, rightId: string) => {
		const checked = e.target.checked;

		if (checked) {
			setValue('rights', [...rightsIds, rightId]);
		} else {
			setValue(
				'rights',
				rightsIds.filter(id => id !== rightId),
			);
		}
	};

	// * Submit
	const { onCreateSubmit, isCreateLoading } = useCreateRole(onCloseModal, setError);
	const { onEditSubmit, isEditLoading } = useEditRole(roleId, localizationId, defaultValues, onCloseModal, setError);

	// * Render
	return (
		<form onSubmit={handleSubmit(localizationId ? onEditSubmit : onCreateSubmit)}>
			<FormProvider {...formMethods}>
				<div className={s.container}>
					<h2 id="start_form">{localizationId ? 'Редактирование роли' : 'Новая роль'}</h2>

					<div className={s.form}>
						<StringField
							name="name"
							label="Название"
							placeholder="Введите название"
							characterLimit={80}
							maxLength={80}
							required
						/>

						<StringField
							name="description"
							label="Описание"
							placeholder="Введите описание"
							characterLimit={300}
							maxLength={300}
						/>

						<div>
							<InputLabel
								label="Выберите права"
								required
							/>

							{isLoading ? (
								<LoaderCircle />
							) : (
								<div className={s.rights__list}>
									{rights.map(right => (
										<Controller
											key={right.id}
											name="rights"
											control={control}
											render={({ field }) => (
												<Checkbox
													{...field}
													label={right.name}
													checked={field.value.includes(right.id)}
													onChange={e => changeRights(e, right.id)}
												/>
											)}
										/>
									))}
								</div>
							)}
						</div>

						{error_rights && <div className={s.error_message}>Роль с таким набором прав уже существует.</div>}
					</div>

					<div className={s.buttons}>
						<Button
							fixedWidth
							variant="tertiary"
							onClick={onCloseModal}
						>
							Отмена
						</Button>

						<Button
							fixedWidth
							type="submit"
							variant="primary"
							isLoading={isCreateLoading || isEditLoading}
							disabled={!nameValue || rightsIds.length === 0}
						>
							{localizationId ? 'Сохранить' : 'Создать'}
						</Button>
					</div>
				</div>
			</FormProvider>
		</form>
	);
};
