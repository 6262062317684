import { AsyncThunkAction } from '@reduxjs/toolkit';
import { saveAs as saveFile } from 'file-saver';
import React, { HTMLAttributes, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { ImageInfo } from 'src/app/redux/state/image/image/types';
import { useAppDispatch } from 'src/app/redux/utils';
import { ReactComponent as CloseSVG } from 'src/shared/assets/svg/action/close.svg';
import { ReactComponent as DownloadSVG } from 'src/shared/assets/svg/files/download.svg';
import { ReactComponent as PrevSVG } from 'src/shared/assets/svg/navigation/Arrow_left_v1.svg';
import { ReactComponent as NextSVG } from 'src/shared/assets/svg/navigation/Arrow_right_v1.svg';
import { IconSquareButton } from '../../_buttons/IconSquareButton/IconSquareButton';
import { GalleyFile } from '../../_galleries/Galley/_types';
import { createGalleyFileFromImageInfo } from '../../_galleries/Galley/_utils';
import { Backdrop } from '../Modal/ui/Backdrop/Backdrop';
import s from './ImageModal.module.scss';

interface ModalOverlayProps {
	files: GalleyFile[];
	imageToShow: number;
	setImageToShow: (imageIndex: number | null) => void;
	fetchImage?: (imageId: string) => AsyncThunkAction<any, any, any>;
	zIndex: 1 | 2 | 3 | 4 | 5;
}

const ModalOverlay: React.FC<ModalOverlayProps> = props => {
	const {
		files, //
		imageToShow,
		setImageToShow,
		fetchImage,
		zIndex,
		...restProps
	} = props;

	const downloadFile = (activeImage: GalleyFile) => {
		const downloadedFile = activeImage;
		saveFile(downloadedFile, `${downloadedFile.name}`);
	};

	// * Actions
	const dispatch = useAppDispatch();

	const [activeImage, setActiveImage] = useState<GalleyFile | null>(null);

	useEffect(() => {
		const imageFile = files[imageToShow];
		const parentId = imageFile.parentId;

		if (fetchImage && parentId) {
			dispatch(fetchImage(parentId))
				.unwrap()
				.then((res: { body: ImageInfo }) => {
					setActiveImage(createGalleyFileFromImageInfo(res.body));
				})
				.catch(error => console.log(error));
		} else {
			setActiveImage(imageFile);
		}
	}, [imageToShow]);

	const onImageClick = (img: string) => {
		activeImage !== null && window.open(img, '_blank');
	};

	const component = (
		<div
			className={s.container}
			style={{ zIndex: `calc(${zIndex} * var(--z-index-modal))` }}
			{...restProps}
		>
			{fetchImage ? (
				<>
					{activeImage !== null && (
						<img
							className={s.modal_image}
							src={activeImage.preview}
							alt={activeImage.name}
							onClick={() => onImageClick(activeImage.preview)}
						/>
					)}
				</>
			) : (
				<>
					{imageToShow !== null && (
						<img
							className={s.modal_image}
							src={files[imageToShow].preview}
							alt={files[imageToShow].name}
							onClick={() => onImageClick(files[imageToShow].preview)}
						/>
					)}
				</>
			)}

			<IconSquareButton
				size="large"
				Icon={<CloseSVG />}
				onClick={() => setImageToShow(null)}
				className={s.close}
			/>

			{imageToShow !== files.length - 1 && (
				<IconSquareButton
					size="large"
					Icon={<NextSVG />}
					onClick={() => setImageToShow(imageToShow + 1)}
					className={s.next}
				/>
			)}

			{imageToShow !== 0 && (
				<IconSquareButton
					size="large"
					Icon={<PrevSVG />}
					onClick={() => setImageToShow(imageToShow - 1)}
					className={s.previous}
				/>
			)}

			{activeImage !== null && (
				<IconSquareButton
					size="large"
					Icon={<DownloadSVG />}
					onClick={() => downloadFile(activeImage)}
					className={s.download}
				/>
			)}
		</div>
	);

	const container = document.getElementById('modal-portal') as HTMLElement;
	return ReactDOM.createPortal(component, container);
};

interface ModalProps extends HTMLAttributes<HTMLDivElement> {
	// show: boolean;
	toggleModal?: () => void;
	files: GalleyFile[];
	imageToShow: number;
	setImageToShow: React.Dispatch<React.SetStateAction<number | null>>;
	fetchImage?: (imageId: string) => AsyncThunkAction<any, any, any>;
	closeOnBackdrop?: boolean;
	zIndex?: 1 | 2 | 3 | 4 | 5;
}

export const ImageModal: React.FC<ModalProps> = props => {
	const {
		toggleModal, //
		files,
		imageToShow,
		setImageToShow,
		fetchImage,
		closeOnBackdrop = true,
		zIndex = 1,
		...restProps
	} = props;

	// * Render
	return (
		<>
			<Backdrop
				isActive={imageToShow !== null}
				onClick={closeOnBackdrop && toggleModal}
				zIndex={zIndex}
			/>

			<ModalOverlay
				{...restProps}
				files={files}
				imageToShow={imageToShow}
				setImageToShow={setImageToShow}
				fetchImage={fetchImage}
				zIndex={zIndex}
			/>
		</>
	);
};
