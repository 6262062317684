export const routerPath = {
	auth: {
		login: '/auth/login',
		signup: '/auth/signup',
		forgot: '/auth/forgot',
		reset: '/auth/reset',
		reactivate: '/auth/reactivate',
	},
	time: {
		page: '/time',
	},
	projects: {
		page: '/projects',
		timelist: '/timelist',
	},
	users: {
		page: '/users',
	},
	wiki: {
		page: '/wiki',
		newArticles: '/new_article',
		editArticles: '/edit_article',
	},
	public: {
		page: '/wiki_public',
		newArticles: '/new_article',
		editArticles: '/edit_article',
	},
	offices: {
		page: '/offices',
		singleOffice: '/single_office',
		roomForm: '/room_form',
	},
	departments: {
		page: '/departments',
		singleDepartment: '/single_department',
		timeList: '/time_list',
	},
	booking: {
		page: '/booking',
	},
	admin: {
		page: '/admin',
		dashboard: '/dashboard',
	},
	feedback: {
		page: '/feedback',
	},
	structure: {
		page: '/structure',
		details: '/structure/details',
	},
	surveys: {
		page: '/surveys',
		newSurvey: '/survey_form',
		edit: '/edit',
		template: '/template',
		newTemplate: '/template_form',
	},
	calendar: {
		page: '/event-calendar',
		newEvent: '/new-event',
		editEvent: '/edit-event',
		event: '/event',
	},
	hr: {
		page: '/new-employee',
		offer: '/offer',
	},
};
