import React from 'react';
import { SelectMulti } from '../_inputs/selects/SelectMulti/SelectMulti';
import { Controller, useFormContext } from 'react-hook-form';
import { extractNestedError } from 'src/shared/lib/fields';

interface Props {
	name: string;
	label: string;
	placeholder?: string;
	options: Array<{
		id: string | null;
		name: string | null;
	}>;
	required?: boolean;
}

export const SelectMultiField: React.FC<Props> = props => {
	const {
		name, //
		label,
		placeholder,
		options,
		required,
	} = props;

	const { control, setValue } = useFormContext();

	return (
		<Controller
			name={name}
			control={control}
			render={({ field, fieldState }) => (
				<SelectMulti
					{...field}
					label={label}
					placeholder={placeholder}
					keyNames={{
						name: 'name',
						value: 'id',
					}}
					selectedOptions={field.value}
					setSelectedOptions={value => setValue(field.name, value)}
					options={options}
					errorMessage={extractNestedError(fieldState.error)}
					required={required}
				/>
			)}
		/>
	);
};
