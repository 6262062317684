import cn from 'classnames';
import React, { ComponentPropsWithoutRef, useState } from 'react';
import { ImageContent } from 'src/app/redux/queries/survey-service/types';
import { imageContentToGalleyFile } from 'src/shared/lib/file';
import { ImageModal } from 'src/shared/ui/_modals/ImageModal/ImageModal';
import { Image } from '../Image/Image';
import s from './SimpleGallery.module.scss';

interface Props extends ComponentPropsWithoutRef<'div'> {
	images: ImageContent[];
}

export const SimpleGallery: React.FC<Props> = props => {
	const {
		images, //
		className,
	} = props;

	const [activeImage, setActiveImage] = useState<number | null>(null);

	const files = images.map(image => imageContentToGalleyFile(image));

	// * Render
	return (
		<>
			{activeImage !== null && (
				<ImageModal
					toggleModal={() => setActiveImage(null)}
					files={files}
					imageToShow={activeImage}
					setImageToShow={setActiveImage}
					zIndex={5}
				/>
			)}

			<div className={cn(s.container, className)}>
				<div className={s.inner}>
					{images.map((imageInfo, index) => (
						<Image
							key={imageInfo.name + '_' + index}
							onClick={() => setActiveImage(index)}
							{...imageInfo}
						/>
					))}
				</div>
			</div>
		</>
	);
};
