import React, { FormEvent } from 'react';
import { DropDownMenu } from 'src/shared/ui/DropDownMenu/DropDownMenu';
import { TextInput } from 'src/shared/ui/_inputs/text_Inputs/TextInput/TextInput';
import { ReactComponent as DeleteSVG } from 'src/shared/assets/svg_icons/action/delete.svg';
import { Question } from '../Question/Question';
import s from './Section.module.scss';
import { SectionProps } from '../_types';
import { AddButtons } from './AddButtons/AddButtons';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';

interface Props {
	section: SectionProps;
	sections: SectionProps[];
	setSections: (sections: SectionProps[]) => void;
	indexSection: number;

	allRequiredFields: boolean;
	isEdit?: boolean;
}

export const Section: React.FC<Props> = props => {
	const {
		section,
		sections,
		setSections,
		indexSection,

		allRequiredFields,
		isEdit,
	} = props;

	// * handle change
	const onSectionTitleChange = (event: FormEvent<HTMLInputElement>, index: number) => {
		const isTypeBlur = event.type === 'blur';
		const value = event.currentTarget.value;
		const newValue = isTypeBlur ? value.trim() : value;

		const changedSection = sections.map((sect, i) => {
			if (i !== index) return sect;

			return {
				...sect,
				subject: isTypeBlur && !newValue ? `Раздел ${index + 1}` : newValue,
			};
		});

		setSections([...changedSection]);
	};

	// * Section
	const countSections = sections.length;

	// * Delete section
	const deleteSection = (index: number) => {
		const newListSections = sections.filter((_s, ind) => ind !== index);
		setSections([...newListSections]);
	};

	// * Render
	return (
		<div className={s.section}>
			<div className={s.section_header}>
				<div className={s.section_count}>
					{indexSection + 1} из {countSections}
				</div>
				<div className={s.section_top} />
				<div className={s.section_title}>
					<TextInput
						placeholder="Название раздела"
						value={section.subject}
						maxLength={150}
						onChange={e => onSectionTitleChange(e, indexSection)}
						onBlur={e => onSectionTitleChange(e, indexSection)}
					/>

					{!isEdit && (
						<DropDownMenu
							items={[
								{
									icon: <DeleteSVG />,
									title: 'Удалить',
									action: () => deleteSection(indexSection),
								},
							]}
						/>
					)}
				</div>
			</div>

			<SortableContext
				items={section.questions}
				strategy={verticalListSortingStrategy}
			>
				{section.questions.map((question, indexQuestion, questionsArray) => (
					<Question
						key={question.id}
						sections={sections}
						setSections={setSections}
						indexSection={indexSection}
						question={question}
						indexQuestion={indexQuestion}
						onlyQuestionInSection={questionsArray.length === 1}
						isEdit={isEdit}
					/>
				))}
			</SortableContext>

			{!isEdit && (
				<AddButtons
					sections={sections}
					indexSection={indexSection}
					setSections={setSections}
					allRequiredFields={allRequiredFields}
				/>
			)}
		</div>
	);
};
