import React from 'react';
import s from './WordSpaceFormModal.module.scss';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as yup from 'yup';
import { useAppSelector } from 'src/app/redux/utils';
import { StringField } from 'src/shared/ui/_fields/StringField';
import { WorkSpaceInfo } from 'src/app/redux/state/office/workspace/types';
import { Button } from 'src/shared/ui/_buttons/Button/Button';
import { ChipsField } from 'src/shared/ui/_fields/ChipsField';

interface Props {
	roomId: string;
	workspace: WorkSpaceInfo;
	currentWorkspaces: WorkSpaceInfo[];
	setWorkspaces: (workspace: WorkSpaceInfo[]) => void;
	closeModal: () => void;
}

export const WorkSpaceFormModal: React.FC<Props> = props => {
	const { roomId, workspace, currentWorkspaces, setWorkspaces, closeModal } = props;

	// * Selectors
	const activeRoom = useAppSelector(state => state.office_service.room.activeRoom);

	// * Form
	const schema = yup.object().shape({
		name: yup.string().trim().required('Введите название места'),
	});

	const defaultValues = {
		...workspace,
	};

	const formMethods = useForm({
		defaultValues: defaultValues,
		resolver: yupResolver(schema),
	});

	const { control, handleSubmit, setValue } = formMethods;

	const existingWorkSpace = currentWorkspaces.find(prevWorkspace => prevWorkspace.id === workspace.id);

	const onSubmit = (workspace: WorkSpaceInfo) => {
		if (existingWorkSpace) {
			setWorkspaces(
				currentWorkspaces.map(existingWorkspace => {
					if (workspace.id === existingWorkspace.id) {
						return workspace;
					} else {
						return existingWorkspace;
					}
				}),
			);
		} else {
			setWorkspaces([...currentWorkspaces, workspace]);
		}

		closeModal();
	};

	// * Render
	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<FormProvider {...formMethods}>
				<div className={s.container}>
					<h3>Новое место</h3>

					<StringField
						name="name"
						label="Название"
						placeholder="Введите название нового места"
					/>

					{activeRoom && (
						<ChipsField
							name="tags"
							label="Выберите характеристики"
							options={activeRoom.tags}
						/>
					)}

					<Button type="submit">{existingWorkSpace ? 'Редактировать место' : 'Создать место'}</Button>
				</div>
			</FormProvider>
		</form>
	);
};
