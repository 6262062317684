import React, { DetailedHTMLProps, InputHTMLAttributes } from 'react';
import { Controller, ControllerRenderProps, FieldValues, useFormContext } from 'react-hook-form';
import { TextInput } from '../_inputs/text_Inputs/TextInput/TextInput';

interface Props extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
	name: string;
	label?: string;
	placeholder?: string;
	defaultValue?: string;
	numberType?: 'integer' | 'decimal';
	noNegative?: boolean;
	required?: boolean;
}

export const NumberField: React.FC<Props> = props => {
	const {
		name,
		label,
		placeholder,
		defaultValue,
		numberType = 'decimal',
		step = '0.01',
		noNegative, // TODO: Доработать, не достаточно только min 0 надо так же добавить изменение пользовательского ввода.
		required,
		...inputProps
	} = props;

	const { control } = useFormContext();

	const onChange = (event: React.ChangeEvent<HTMLInputElement>, field: ControllerRenderProps<FieldValues, string>) => {
		const value = event.currentTarget.value;
		field.onChange(value ? Number(event.currentTarget.value) : null);
	};

	/*
		! Если в TextInput написано 222,222. То реальное значение равно 222.222. type="number" работает только с запятой. lang/pattern не работает.
		! Вот 2222. === null,  2222, === 2222
		! Но 2222.0 === 2222.0, и 2222,0 === 2222.0
	*/
	return (
		<Controller
			name={name}
			control={control}
			defaultValue={defaultValue}
			render={({ field, fieldState }) => (
				<TextInput
					{...inputProps}
					{...field}
					label={label}
					placeholder={placeholder}
					errorMessage={fieldState.error?.message}
					onKeyDown={event => {
						if (/[.]/.test(event.key)) {
							event.preventDefault();
						} else if (numberType === 'integer' && /[.,]/.test(event.key)) {
							event.preventDefault();
						}
					}}
					min={noNegative ? 0 : undefined}
					onChange={event => onChange(event, field)}
					step={numberType === 'integer' ? '1' : step}
					type="number"
					lang="en"
					required={required}
				/>
			)}
		/>
	);
};
