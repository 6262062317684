import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useGetDepartmentFindQuery } from 'src/app/redux/queries/department-service/department_serviceAPI';
import { project_serviceAPI } from 'src/app/redux/queries/project-service/project_serviceAPI';
import { user_serviceAPI } from 'src/app/redux/queries/user-service/user_serviceAPI';
import { UserAddList } from 'src/pages/project_service_pages/ui/ProjectDetailPage/ui/UserAddList/UserAddList';
import { Avatar } from 'src/shared/ui/Avatar/Avatar';
import { EmptyData } from 'src/shared/ui/EmptyData/EmptyData';
import { Button } from 'src/shared/ui/_buttons/Button/Button';
import { SelectSingleField } from 'src/shared/ui/_fields/SelectSingleField';
import { StringField } from 'src/shared/ui/_fields/StringField';
import { SwitchField } from 'src/shared/ui/_fields/SwitchField';
import { LoaderCircle } from 'src/shared/ui/_loaders/LoaderCircle/LoaderCircle';
import * as yup from 'yup';
import s from './UserAddContent.module.scss';

interface Props {
	projectId: string;
	userIds: string[];
	onCloseModal: () => void;
}

export const UserAddContent: React.FC<Props> = props => {
	const { projectId, userIds, onCloseModal } = props;

	// * API
	const [createProjectUsers, { isLoading: isCreateLoading }] = project_serviceAPI.useCreateProjectUsersMutation();

	const { data: departmentData } = useGetDepartmentFindQuery({
		skipcount: 0,
		takecount: 1000,
	});

	const { data: usersData, isLoading } = user_serviceAPI.useFindUsersQuery({
		params: {
			skipCount: 0,
			takeCount: 1000,
			isActive: true,
			includeDepartments: true,
			includeCurrentAvatar: true,
		},
	});

	// * Form
	const defaultValues = {
		name: '',
		department: {
			id: '',
			name: '',
		},
		showSelected: false,
		selectedUsers: [],
	};

	const schema = yup.object().shape({
		name: yup.string().nullable(),
		department: yup
			.object()
			.shape({
				id: yup.string().nullable(),
				name: yup.string().nullable(),
			})
			.nullable(),
		showSelected: yup.bool(),
		selectedUsers: yup.array().of(yup.string()),
	});

	const formMethods = useForm({
		defaultValues,
		resolver: yupResolver(schema),
	});

	const { handleSubmit, watch } = formMethods;

	const onSubmit = (data: typeof defaultValues) => {
		const { selectedUsers: ids } = data;

		if (ids.length === 0) {
			onCloseModal();
			return;
		}

		createProjectUsers({
			createProjectUsersRequest: {
				projectId,
				users: ids.map(id => ({ userId: id, role: 'Employee' })),
			},
		})
			.unwrap()
			.then(() => onCloseModal())
			.catch(err => console.log('Error: ', err));
	};

	// * Filters
	const departments = departmentData?.body?.map(item => ({
		id: item.id,
		name: item.name,
	})) || [{ id: '', name: '' }];

	// * Users
	const users = useMemo(() => {
		const preparedUsers =
			usersData?.body
				.filter(user => !userIds.includes(user.user.id))
				.map(item => {
					const { user, departmentUser } = item;

					const id = user.id;
					const name = `${user.firstName} ${user.lastName}`.trim();
					const avatar = user.avatar;
					const department = departmentUser?.department?.name || ' - ';
					const departmentId = departmentUser?.department?.id || '';

					return {
						id,
						name,
						avatar: (
							<Avatar
								name={name}
								userAvatar={avatar}
							/>
						),
						department,
						departmentId,
					};
				}) || [];

		return preparedUsers;
	}, [usersData]);

	const [usersList, setUsersList] = useState(users);

	// * Filter
	const nameValue = watch('name');
	const showSelected = watch('showSelected');
	const selectedUsers: string[] = watch('selectedUsers');
	const department = watch('department');

	useEffect(() => {
		let filteredUsers = users;

		if (nameValue) {
			filteredUsers = filteredUsers.filter(user => user.name.toLocaleLowerCase().includes(nameValue.toLocaleLowerCase()));
		}

		if (showSelected) {
			filteredUsers = filteredUsers.filter(user => selectedUsers.includes(user.id));
		}

		if (department.id) {
			filteredUsers = filteredUsers.filter(user => user.departmentId === department.id);
		}

		setUsersList(filteredUsers);
	}, [users, nameValue, showSelected, department, selectedUsers.length]);

	// * Render
	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<FormProvider {...formMethods}>
				<div className={s.container}>
					<h2>Добавить участника</h2>

					<div className={s.filters}>
						<SelectSingleField
							name="department"
							placeholder="Департамент"
							options={departments}
							isNullable
						/>

						<StringField
							name="name"
							placeholder="Поиск сотрудника по ФИО"
						/>
					</div>

					<div className={s.user_select}>
						<h5>Выбрано сотрудников: {selectedUsers.length}</h5>

						<SwitchField
							name="showSelected"
							text="Просмотреть выбранных"
							isLeftText
						/>
					</div>

					{isLoading && <LoaderCircle />}

					{usersList.length > 0 && <UserAddList users={usersList} />}
					{usersList.length === 0 && !isLoading && <EmptyData />}

					<div className={s.form_buttons}>
						<Button
							fixedWidth
							variant="tertiary"
							onClick={e => {
								e.preventDefault();
								onCloseModal();
							}}
						>
							Отменить
						</Button>

						<Button
							fixedWidth
							type="submit"
							variant="primary"
							isLoading={isCreateLoading}
						>
							Пригласить
						</Button>
					</div>
				</div>
			</FormProvider>
		</form>
	);
};
