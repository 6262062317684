import { saveAs as saveFile } from 'file-saver';
import React from 'react';
import { file_serviceAPI } from 'src/app/redux/queries/file-service/file_serviceAPI';
import { base64ToBlob } from 'src/shared/lib/file';
import { IconButton } from 'src/shared/ui/_buttons/IconButton/IconButton';
import { ReactComponent as DownloadSVG } from 'src/shared/assets/svg_icons/file/download.svg';
import { FileSource } from 'src/app/redux/queries/file-service/types/types';
import { FileInfo } from 'src/app/redux/queries/event-service/types/types';

interface Props {
	file: FileInfo;
	fileSourse: FileSource;
}

export const FileDownload: React.FC<Props> = ({ file, fileSourse }) => {
	// * API
	const [getFiles] = file_serviceAPI.useLazyGetFileGetQuery();

	// * Download file
	const downloadFile = () => {
		const downloadFileAction = (downloadedFile: any) => {
			const blob = base64ToBlob(`data:${downloadedFile.contentType};base64,${downloadedFile.fileContents}`);
			saveFile(blob, `${file.name}${file.extension}`);
		};

		getFiles({
			filesIds: [file.id],
			fileSource: fileSourse,
		})
			.unwrap()
			.then(res => {
				const downloadedFile = res[0];
				downloadFileAction(downloadedFile);
			})
			.catch(err => console.log(err));
	};

	// * Render
	return (
		<IconButton
			Icon={<DownloadSVG />}
			onClick={downloadFile}
		/>
	);
};
