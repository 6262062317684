import React, { useState } from 'react';
import { AddButton } from 'src/shared/ui/_buttons/AddButton/AddButton';
import { UserAddContent } from 'src/pages/project_service_pages/ui/ProjectDetailPage/ui/UserAddContent/UserAddContent';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';

interface Props {
	projectId: string;
	userIds: string[];
}

export const TeamUserAdd: React.FC<Props> = props => {
	const { projectId, userIds } = props;

	// * Modal
	const [isAddUserModal, setIsAddUserModal] = useState(false);
	const toggleAddUserModal = () => setIsAddUserModal(prevState => !prevState);

	// * Render
	return (
		<>
			{isAddUserModal && (
				<ModalNewProto
					isOpen={isAddUserModal}
					onClose={toggleAddUserModal}
					width="l"
				>
					<UserAddContent
						projectId={projectId}
						userIds={userIds}
						onCloseModal={toggleAddUserModal}
					/>
				</ModalNewProto>
			)}

			<AddButton
				title="Добавить участника"
				onClick={toggleAddUserModal}
			/>
		</>
	);
};
