import { ReactDatePickerProps } from 'react-datepicker';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { DatePickerInput } from '../_inputs/date_pickers/DatePickerInput/DatePickerInput';

interface Props extends Omit<ReactDatePickerProps, 'onChange'> {
	name: string;
	label: string;
	required?: boolean;
}

export const DateField: React.FC<Props> = props => {
	const { name, label, required, ...datePickerProps } = props;

	const { control, setValue } = useFormContext();

	// * Render
	return (
		<Controller
			name={name}
			control={control}
			render={({ field, fieldState }) => (
				<DatePickerInput
					{...datePickerProps}
					selectedDate={field.value}
					setSelectedDate={value => setValue(name, value)}
					label={label}
					errorMessage={fieldState.error?.message}
					required={required}
				/>
			)}
		/>
	);
};
