import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CategoryOption, FormValues } from './types';
import { ImageInfo } from 'src/app/redux/state/image/image/types';

interface State extends FormValues {
	showModal: boolean;
	collapsed: boolean;
}

export const initialState: State = {
	showModal: false,
	collapsed: false,
	category: {
		id: 'Bug',
		name: 'Сломалось',
	},
	comment: '',
	images: [],
};

export const slice = createSlice({
	name: 'BugReportModal',
	initialState,
	reducers: {
		setShowModal: (state, action: PayloadAction<boolean>) => {
			state.showModal = action.payload;
		},
		setCollapsed: (state, action: PayloadAction<boolean>) => {
			state.collapsed = action.payload;
		},
		setCategory: (state, action: PayloadAction<CategoryOption>) => {
			state.category = action.payload;
		},
		setComment: (state, action: PayloadAction<string>) => {
			state.comment = action.payload;
		},
		setImages: (state, action: PayloadAction<ImageInfo[]>) => {
			state.images = action.payload;
		},
		resetForm: state => {
			state.category = initialState.category;
			state.comment = initialState.comment;
			state.images = initialState.images;
		},
	},
});

export const actionsBugReportModal = slice.actions;
