import React from 'react';
import s from './BookingRoomsList.module.scss';
import { useAppDispatch, useAppSelector } from 'src/app/redux/utils';
import { RoomsList } from '../../../../offices/ui/_pages/SingleOfficePage/Content/RoomsContent/RoomsList/RoomsList';
import { DropDownMenu } from 'src/shared/ui/DropDownMenu/DropDownMenu';
import { ReactComponent as EditSVG } from 'src/shared/assets/svg_icons/action/edit.svg';
import { ReactComponent as DeleteSVG } from 'src/shared/assets/svg_icons/action/delete.svg';
import { useRoomEdit } from '../../../../offices/ui/_pages/SingleOfficePage/Content/RoomsContent/hooks/useRoomEdit';
import { officeServiceActions } from 'src/app/redux/state/office/actions';
import { useNavigate } from 'react-router-dom';
import { routerPath } from 'src/app/router/paths';
import { OfficeRoom } from 'src/app/redux/state/office/office/types';

interface Props {
	officeId: string;
}

export const BookingRoomsList: React.FC<Props> = props => {
	const { officeId } = props;

	// * Router
	const navigate = useNavigate();
	const toBookingTable = (room: OfficeRoom) => navigate(`${routerPath.booking.page}/${room.room.officeId}/${room.room.id}`);

	// * Actions
	const dispatch = useAppDispatch();
	const { findRooms } = officeServiceActions.room.async;
	const { editRoom } = officeServiceActions.room.async;

	// * Selectors
	const rooms = useAppSelector(state => state.office_service.room.rooms);
	const userInfo = useAppSelector(state => state.user_service.user.userInfo);

	const sortedRooms = [...rooms].sort((a, b) => {
		return a.name.toLocaleLowerCase().localeCompare(b.name.toLowerCase());
	});

	// * Prepared variables
	const roomsPrepared = sortedRooms
		.filter(room => room.isActive === true)
		.map(item => ({
			room: { ...item },
			workspaces: [],
		}));
	const isAdmin = userInfo?.user.isAdmin;

	// * Create room
	const { openRoomForm } = useRoomEdit({ officeId });

	return officeId ? (
		<div className={s.container}>
			<h5 className={s.title}>Всего результатов: {roomsPrepared.length}</h5>

			{sortedRooms.length > 0 && (
				<RoomsList
					rooms={roomsPrepared}
					onClick={toBookingTable}
					getDropDownMenu={
						!isAdmin
							? undefined
							: (roomId: string) => (
									<DropDownMenu
										items={[
											{
												title: 'Редактировать',
												icon: <EditSVG />,
												action: () => openRoomForm(roomId),
											},
											{
												title: 'Удалить',
												icon: <DeleteSVG />,
												action: () => {
													dispatch(
														editRoom({
															params: {
																roomId,
															},
															payload: [
																{
																	op: 'replace',
																	path: '/isActive',
																	value: false,
																},
															],
														}),
													)
														.unwrap()
														.then(() => {
															dispatch(
																findRooms({
																	params: {
																		skipCount: 0,
																		takeCount: 1000,
																		officeId: officeId,
																	},
																}),
															);
														});
												},
											},
										]}
									/>
								)
					}
				/>
			)}
		</div>
	) : (
		<></>
	);
};
