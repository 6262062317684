import React, { memo, useCallback, useEffect, useState } from 'react';
import { useActions } from 'src/app/redux/utils';
import { ReactComponent as CloseSVG } from 'src/shared/assets/svg/action/close.svg';
import { ReactComponent as InfoSVG } from 'src/shared/assets/svg/alerts/info.svg';
import { ReactComponent as SuccessSVG } from 'src/shared/assets/svg/alerts/success.svg';
import { ReactComponent as WarningSVG } from 'src/shared/assets/svg/alerts/warning.svg';
import { actionsNotifications } from '../_BLL/slice';
import { Notification, NotificationType } from '../_BLL/types';
import s from './NotificationSingle.module.scss';
import cn from 'classnames';

export const NotificationSingle: React.FC<Notification> = memo(props => {
	const { id, message, type } = props;

	// * Styles
	const getCardClass = (type: NotificationType) => {
		if (type === 'info') return s.container_info;
		if (type === 'success') return s.container_success;
		if (type === 'warning') return s.container_warning;
		if (type === 'error') return s.container_error;
	};

	const getIcon = (type: NotificationType) => {
		if (type === 'info') return <InfoSVG className={s.icon_info} />;
		if (type === 'success') return <SuccessSVG className={s.icon_success} />;
		if (type === 'warning') return <WarningSVG className={s.icon_warning} />;
		if (type === 'error') return <InfoSVG className={s.icon_error} />;
	};

	// * Actions
	const { deleteNotification } = useActions(actionsNotifications);

	// * Local state
	const [close, setClose] = useState(false);
	const [barWidth, setBarWidth] = useState<number>(0);
	const [intervalId, setIntervalId] = useState<ReturnType<typeof setInterval> | null>(null);

	const onClose = () => {
		setClose(true);
		setTimeout(() => {
			deleteNotification({ notificationId: id });
		}, 400);
	};

	const handleStartTimer = useCallback(() => {
		const id = setInterval(() => {
			setBarWidth(prevState => {
				if (prevState < 100) {
					return prevState + 0.5;
				}
				return prevState;
			});
		}, 20);

		setIntervalId(id);
	}, []);

	const handlePauseTimer = useCallback(() => {
		clearInterval(intervalId as ReturnType<typeof setInterval>);
	}, [intervalId]);

	useEffect(() => {
		handleStartTimer();
	}, [handleStartTimer]);

	useEffect(() => {
		if (barWidth === 100 && type !== 'error') {
			handlePauseTimer();
			onClose();
		}
	}, [barWidth, deleteNotification, handlePauseTimer, id]);

	// * Render
	return (
		<div className={cn(s.container, close ? s.anim_close : s.anim_initialize, getCardClass(type))}>
			<div className={s.inner}>
				{getIcon(type)}
				<p>{message}</p>
			</div>

			<CloseSVG
				className={s.close_icon}
				onClick={onClose}
			/>
		</div>
	);
});
