import { yupResolver } from '@hookform/resolvers/yup';
import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { education_serviceAPI } from 'src/app/redux/queries/education-service/education_serviceAPI';
import { ButtonPair } from 'src/entities/_buttons/ButtonPair/ButtonPair';
import { galleyFileToBase64 } from 'src/shared/lib/file';
import { DropZone } from 'src/shared/ui/DropZone/DropZone';
import { Heading } from 'src/shared/ui/Heading';
import { SelectSingleField } from 'src/shared/ui/_fields/SelectSingleField';
import { StringField } from 'src/shared/ui/_fields/StringField';
import { SwitchField } from 'src/shared/ui/_fields/SwitchField';
import { createImageInfoFromFile } from 'src/shared/ui/_galleries/Galley/_utils';
import { ImageList } from 'src/shared/ui/_galleries/ImageList';
import { v4 } from 'uuid';
import * as yup from 'yup';
import { CERTIFICATE_TYPE, EDUCATION_TYPE } from '../../const/type';
import { useCreateEducation } from '../../hooks/useCreateEducation';
import { useEditEducation } from '../../hooks/useEditEducation';
import { getEducationType } from '../../lib';
import { DefaultValues } from '../../types';
import { UniversityInput } from '../UniversityInput/UniversityInput';
import s from './EducationForm.module.scss';

interface Props {
	userId: string;
	educationId?: string;
	isCertificate?: boolean;
	initialValues?: DefaultValues;
	onCloseModal: () => void;
}

export const EducationForm: FC<Props> = props => {
	const {
		userId, //
		educationId = '',
		isCertificate = false,
		initialValues,
		onCloseModal,
	} = props;

	const isEdit = !!initialValues;

	// * API
	const { data: formData } = education_serviceAPI.useGetEducationFormGetQuery({});
	const educationForms = formData?.body?.map(item => ({ id: item.id, name: item.name })) || [{ id: '', name: '' }];

	// - type of education
	const educationType = educationForms //
		.filter(item => EDUCATION_TYPE.includes(item.name))
		.map(item => ({ id: item.id, name: getEducationType(item.name) }));

	const certificateType = educationForms //
		.filter(item => CERTIFICATE_TYPE.includes(item.name))
		.map(item => ({ id: item.id, name: getEducationType(item.name) }));

	// * Form
	const schema = yup.object().shape({
		institution: yup
			.object()
			.shape({
				id: yup.string().nullable(),
				name: yup.string().nullable(),
			})
			.nullable(),
		specialization: yup.string().nullable().required(''),
		form: yup
			.object()
			.shape({
				id: yup.string().nullable().required(''),
				name: yup.string().nullable(),
			})
			.nullable(),
		startDate: yup.string().nullable().required(''),
		completeness: yup.bool(),
	});

	const defaultValues: DefaultValues = initialValues || {
		institution: {
			id: '',
			name: '',
		},
		specialization: '',
		form: {
			id: '',
			name: '',
		},
		startDate: '',
		endDate: '',
		completeness: false,
		images: [],
	};

	const formMethods = useForm({
		defaultValues,
		resolver: yupResolver(schema),
	});

	const { setValue, watch, handleSubmit } = formMethods;
	const existedImages = watch('images');
	const institutionName = watch('institution.name');
	const specialization = watch('specialization');
	const form = watch('form.id');
	const startDate = watch('startDate');
	const endDate = watch('endDate');

	const filledForm =
		Boolean(institutionName) && //
		Boolean(specialization) &&
		Boolean(form) &&
		Boolean(startDate && +startDate) &&
		Boolean((endDate && +endDate && +endDate >= +startDate) || !endDate);

	// - Delete image
	const onDelete = (id: string) => {
		setValue(
			'images',
			existedImages.filter(item => item.id !== id),
		);
	};

	// - On drop
	const onDrop = async (acceptedFiles: File[]) => {
		const files = acceptedFiles.map(file =>
			Object.assign(file, {
				id: `new_${v4()}`,
				parentId: null,
				preview: URL.createObjectURL(file),
			}),
		);

		Promise.all(files.map(file => galleyFileToBase64(file))).then((values: any) => {
			const images = createImageInfoFromFile(values);

			setValue('images', images);
		});
	};

	// * Submit
	const { onCreateSubmit, isCreateLoading } = useCreateEducation(userId, onCloseModal);
	const { onEditSubmit, isEditLoading } = useEditEducation(educationId, defaultValues, onCloseModal);

	// * Render
	return (
		<form onSubmit={handleSubmit(isEdit ? onEditSubmit : onCreateSubmit)}>
			<FormProvider {...formMethods}>
				<Heading
					level={2}
					marginBottom="l"
				>
					{isCertificate ? 'Сертификат' : 'Образование'}
				</Heading>

				<div className={s.form}>
					<UniversityInput name="institution" />

					<StringField
						name="specialization"
						label="Специализация"
						placeholder="Специализация"
						required
					/>

					<div className={s.row}>
						<SelectSingleField
							name="form"
							label="Форма обучения"
							placeholder="Выберите из списка"
							options={isCertificate ? certificateType : educationType}
							required
						/>

						<div className={s.input_range}>
							<StringField
								name="startDate"
								label="Год начала"
								placeholder="ГГГГ"
								required
								maxLength={4}
								errorBorder={startDate ? !+startDate : false}
							/>

							<span className={s.separator}>—</span>

							<StringField
								name="endDate"
								label="Год окончания"
								placeholder="ГГГГ"
								maxLength={4}
								errorBorder={endDate ? !+endDate || +endDate < +startDate : false}
							/>
						</div>

						{/* <DateRangeField
							startTimeName="startDate"
							endTimeName="endDate"
							labels={['Дата начала', 'Дата окончания']}
							showYearDropdown
							isClearable
							startRequired
						/> */}

						<div className={s.switch}>
							<SwitchField
								name="completeness"
								text="Оконченное"
								disabled={!endDate}
							/>
						</div>
					</div>

					{existedImages.length > 0 ? (
						<ImageList
							className={s.images}
							images={existedImages}
							onDelete={onDelete}
						/>
					) : (
						<DropZone
							onDrop={onDrop}
							accept={{
								'image/jpeg': ['.jpeg'],
								'image/png': ['.png'],
								'image/tiff': ['.tiff'],
							}}
							placeholder="Перетащите документ об образовании или загрузите"
							maxFiles={1}
						/>
					)}
				</div>

				<ButtonPair
					primaryText={isEdit ? 'Сохранить' : 'Добавить'}
					primaryIsLoading={isCreateLoading || isEditLoading}
					primaryDisabled={!filledForm}
					secondaryText="Отменить"
					secondaryOnClick={onCloseModal}
					secondaryIsLoading={isCreateLoading || isEditLoading}
				/>
			</FormProvider>
		</form>
	);
};
