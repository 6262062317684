import React, { useCallback, useEffect, useState } from 'react';
import { useLazyGetFileFindQuery, usePatchEventEditMutation, usePostEventCreateMutation } from 'src/app/redux/queries/event-service/event_serviceAPI';
import { usePostFileCreateMutation } from 'src/app/redux/queries/file-service/file_serviceAPI';
import { ImageContent } from 'src/app/redux/queries/survey-service/types';
import { actionsEventCalendar } from 'src/app/redux/state/event-calendar/slice';
import { FileInfo } from 'src/app/redux/state/wiki/types';
import { useAppDispatch, useAppSelector } from 'src/app/redux/utils';
import { ReactComponent as DeleteSVG } from 'src/shared/assets/svg/action/delete.svg';
import { ReactComponent as DocSVG } from 'src/shared/assets/svg/files/doc.svg';
import { ReactComponent as FolderSVG } from 'src/shared/assets/svg/files/folder.svg';
import { ReactComponent as PhotoSVG } from 'src/shared/assets/svg/files/image.svg';
import { galleyFileToImageContent, imageContentToGalleyFile } from 'src/shared/lib/file';
import { formatBytes } from 'src/shared/lib/string';
import { DropZone } from 'src/shared/ui/DropZone/DropZone';
import { GalleyFile } from 'src/shared/ui/_galleries/Galley/_types';
import { v4 } from 'uuid';
import s from './AddFilesToEvent.module.scss';
import { AccessType, FormatType } from 'src/app/redux/queries/event-service/types/types';

interface Props {
	formName: string;
}

export const AddFilesToEvent: React.FC<Props> = props => {
	const {
		formName, //
	} = props;

	// * Selectors
	const eventId = useAppSelector(state => state.event_calendar.event.eventId);
	const event = useAppSelector(state => state.event_calendar.event.event);
	const selectedUsersFull = useAppSelector(state => state.event_calendar.event.selectedUsersInfo);

	const [eventFiles, setEventFiles] = useState<FileInfo[]>([]);

	const dispatch = useAppDispatch();

	const { setEventInfo } = actionsEventCalendar;

	// * API
	const [findFiles, { data: files }] = useLazyGetFileFindQuery();
	const [createEvent, { isLoading: isCreateEventLoading }] = usePostEventCreateMutation();
	const [createFile, { isLoading: isCreateFileLoading }] = usePostFileCreateMutation();

	useEffect(() => {
		if (eventId) {
			findFiles({
				skipcount: 0,
				takecount: 20,
				entityId: eventId,
			});
		}
	}, [eventId]);

	// const [updateEvent, { isLoading: isCreateEventLoading }] = usePatchEventEditMutation();

	const [formData, setFormData] = useState<FormData>();

	const onDropImage = async (acceptedFiles: any) => {
		const imgs = acceptedFiles.map((img: any) => {
			return Object.assign(img, {
				id: `new_${v4()}`,
				preview: URL.createObjectURL(img),
			});
		});

		const imgFormat = Promise.all(
			imgs.map((img: GalleyFile) => {
				return galleyFileToImageContent(img);
			}),
		);

		const imgFormatData = await imgFormat;

		dispatch(
			setEventInfo({
				eventImages: event?.eventImages //
					? [...event.eventImages, ...imgFormatData]
					: [...imgFormatData],
			}),
		);
	};

	const saveArticleFiles = (newFiles: any) => {
		const localFormData = new FormData();

		for (const newFile of newFiles) {
			localFormData.append('uploadedFiles', newFile);
		}

		setEventFiles(prevState => [...prevState, ...newFiles]);

		setFormData(localFormData);
	};

	const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		if (event && selectedUsersFull.users.length > 0) {
			createEvent({
				createEventRequest: {
					name: event.name,
					address: event.address,
					description: event.description,
					date: event.date!,
					endDate: event.endDate,
					format: event.format as FormatType, // TODO типизировать
					access: event.access as AccessType, // TODO типизировать
					users: event.users,
					categoriesIds: event.categoriesIds,
					eventImages: event.eventImages,
				},
			})
				.then(res => {
					const entityId = 'data' in res && res.data.body;

					if (entityId) {
						createFile({
							createFilesRequest: {
								fileSource: 'Event',
								accessType: 'Public',
								entityId,
								uploadedFiles: formData,
							},
						});
					}
				})
				.catch(error => console.log('error :>> ', error));
		}

		if (eventId) {
			createFile({
				createFilesRequest: {
					fileSource: 'Event',
					accessType: 'Public',
					entityId: eventId,
					uploadedFiles: formData,
				},
			});

			// updateEvent({
			// 	eventId: eventId as string,
			// 	body: [
			// 		{
			// 			op: 'replace',
			// 			path: '/Images',
			// 			value: event!.eventImages,
			// 		},
			// 	],
			// });
		}
	};

	const onDropFiles = useCallback(
		async (acceptedFiles: any) => {
			saveArticleFiles(acceptedFiles);
		},
		[files],
	);

	const getIcon = (type: string) => {
		if (type === '.jpg' || type === '.jpeg' || type === '.png') {
			return <PhotoSVG />;
		} else if (type === '.zip') {
			return <FolderSVG />;
		} else {
			return <DocSVG />;
		}
	};

	const renderImages = (images: ImageContent[]): JSX.Element[] => {
		if (images) {
			return images.map(image => {
				const img = imageContentToGalleyFile(image);

				return (
					<img
						className={s.image}
						src={img.preview}
						alt={img.name}
						key={img.id}
					/>
				);
			});
		}

		return [];
	};

	const deleteFile = (id: number) => {
		setEventFiles(prevState => prevState.filter(file => file.name !== prevState[id].name));
	};

	const renderFiles = (files: any) => {
		if (files) {
			return files.map((file: any, id: number) => {
				return (
					<div
						className={s.file}
						key={file.name}
					>
						<div className={s.formatIcon}>{getIcon(file.extension)}</div>
						<div className={s.name}>{file.name}</div>
						<div className={s.size}>{formatBytes(file.size)}</div>
						<div onClick={() => deleteFile(id)}>
							<DeleteSVG />
						</div>
					</div>
				);
			});
		}
	};

	return (
		<div>
			<form
				id={formName}
				onSubmit={onSubmit}
			/>
			<div className={s.gallery}>
				<h2 className={s.title}>Галерея</h2>
				<div className={s.dropzone}>
					<DropZone
						accept={{ 'image/*': ['.png', '.tiff', '.jpg', '.mpeg', '.avi', '.wmv', '.mp4', '.webm', '.gif'] }}
						onDrop={onDropImage}
						maxFiles={10}
					/>
				</div>
				<div className={s.imageRow}>{event && renderImages(event.eventImages)}</div>
			</div>

			<div className={s.gallery}>
				<h2 className={s.title}>Документы</h2>
				<div className={s.dropzone}>
					<DropZone
						onDrop={onDropFiles}
						maxFiles={10}
					/>
				</div>
				<div className={s.imageRow}>{event && renderFiles(eventFiles)}</div>
			</div>
		</div>
	);
};
