import React, { useState } from 'react';
import { ReactComponent as CloseSVG } from 'src/shared/assets/svg/action/close.svg';
import { ReactComponent as SearchSVG } from 'src/shared/assets/svg/action/search.svg';
import { matchItems } from 'src/shared/lib/filter';
import { SelectSingleCustomValue } from 'src/shared/ui/_inputs/selects/SelectSingleCustomValue/SelectSingleCustomValue';
import { TextInput } from 'src/shared/ui/_inputs/text_Inputs/TextInput/TextInput';
import s from './FiltersWithSearch.module.scss';

// TODO: Выпилить компонент, заменить на SelectStringSearchFilter
export interface SelectValue {
	id: string | null;
	name: string | null;
}

export interface SelectValues {
	id: string;
	name: string;
}

interface Props {
	selectPlaceholder: string;
	selectOptions: SelectValues[];
	selectedOption: SelectValue;
	onSelectChange: (value: SelectValue) => void;

	searchValueOption: string;
	onChangeSearchOption: (value: string) => void;

	searchPlaceholder: string;
	searchValue: string;
	onSearchChange: (value: string) => void;

	isNullable?: boolean;
}

export const FiltersWithSearch: React.FC<Props> = props => {
	const {
		selectPlaceholder, //
		selectOptions,
		selectedOption,
		onSelectChange,
		searchValueOption,
		onChangeSearchOption,
		searchPlaceholder,
		searchValue,
		onSearchChange,
		isNullable,
	} = props;

	const [filteredOptions, setFilteredOptions] = useState<SelectValue[] | { name: string; id: string }[]>(selectOptions);

	// * Render
	return (
		<div className={s.container}>
			<SelectSingleCustomValue
				placeholder={selectPlaceholder}
				keyNames={{
					name: 'name',
					value: 'id',
				}}
				searchSubstring={searchValueOption}
				onStringChange={value => {
					const sameValue = selectOptions.find(item => item.name === value);
					setFilteredOptions(matchItems(selectOptions, value));
					onChangeSearchOption(value);
					onSelectChange(sameValue ? { name: sameValue.name, id: sameValue.id } : { name: null, id: null });
				}}
				options={filteredOptions}
				selectedOption={selectedOption}
				setSelectedOption={onSelectChange}
				isNullable={isNullable}
			/>

			<TextInput
				value={searchValue}
				placeholder={searchPlaceholder}
				onChange={event => onSearchChange(event.currentTarget.value)}
				RightIcon={searchValue ? <CloseSVG /> : <SearchSVG />}
				onRightIconClick={searchValue ? () => onSearchChange('') : undefined}
			/>
		</div>
	);
};
