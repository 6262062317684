import { FloatingFocusManager } from '@floating-ui/react';
import React, { HTMLAttributes, PropsWithChildren, useState } from 'react';
import { ReactComponent as ArrowSVG } from 'src/shared/assets/svg/navigation/Arrow_down_v2.svg';
import { useFloatingWrapper } from 'src/shared/hooks/useFloatingWrapper';
import _input from 'src/shared/ui/_inputs/_styles/_input.module.scss';
import { Chip } from '../../../_chips/Chip/Chip';
import { OptionsWindow } from '../../../_option_lists/OptionsWindow/OptionsWindow';
import { ErrorWithLimit } from '../../_shared/ErrorWithLimit/ErrorWithLimit';
import { InputLabel } from '../../_shared/InputLabel/InputLabel';
import _input_with_options from '../../_styles/_input_with_options.module.scss';
import s from './SelectMulti.module.scss';

interface Props<T, Key> extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
	label?: string;
	placeholder?: string | undefined;
	keyNames: {
		name: Key;
		value: Key;
	};
	selectedOptions: T[];
	setSelectedOptions: React.Dispatch<React.SetStateAction<T[]>>;
	options: T[];
	errorMessage?: string;
	disabled?: boolean;
	required?: boolean;
}

export const SelectMulti = <T extends Record<string, any>, Key extends keyof T>(props: PropsWithChildren<Props<T, Key>>) => {
	const {
		className, //
		label,
		keyNames,
		placeholder,
		selectedOptions,
		setSelectedOptions,
		options,
		errorMessage,
		disabled,
		required,
	} = props;

	// * User actions
	const [collapsed, setCollapsed] = useState(true);

	const toggleDropDown = () => setCollapsed(!collapsed);

	const onChange = (option: T) => {
		const optionExists = !!selectedOptions.find(prevOption => prevOption[keyNames.value] === option[keyNames.value]);
		if (!optionExists || selectedOptions.length === 0) {
			setSelectedOptions([...selectedOptions, option]);
		} else {
			setSelectedOptions(selectedOptions.filter(prevOption => prevOption[keyNames.value] !== option[keyNames.value]));
		}
	};

	// * Floating
	const { floatingStyles, refs, context, getReferenceProps, getFloatingProps, headingId } = useFloatingWrapper(!collapsed, (value: any) => !disabled && setCollapsed(!value));

	// * Render
	return (
		<div className={_input.container}>
			<InputLabel
				label={label}
				required={required}
			/>

			<div className={`${_input_with_options.container} ${_input.drop_down_container} ${className}`}>
				<div
					className={_input.input_wrapper}
					onClick={toggleDropDown}
					ref={refs.setReference}
					{...getReferenceProps()}
				>
					<div className={_input.input} />

					<ArrowSVG
						className={_input.icon_right}
						style={{ zIndex: 2 }}
					/>
					<div className={_input.border} />
					<span className={_input.placeholder}>{placeholder}</span>
				</div>

				{!collapsed && (
					<FloatingFocusManager
						context={context}
						modal={false}
					>
						<div
							className={_input_with_options.options}
							ref={refs.setFloating}
							style={{
								...floatingStyles,
								zIndex: 'var(--z-index-floating)',
							}}
							aria-labelledby={headingId}
							{...getFloatingProps()}
						>
							<OptionsWindow
								keyNames={keyNames}
								options={options}
								onOptionClick={onChange}
								selectedOptions={selectedOptions}
							/>
						</div>
					</FloatingFocusManager>
				)}

				{collapsed && selectedOptions.length > 0 && (
					<div className={s.chip_container}>
						{selectedOptions.map(option => (
							<Chip
								key={option.id}
								text={option.name}
								variant="blue"
								handleClose={() => onChange(option)}
							/>
						))}
					</div>
				)}
			</div>

			{errorMessage && <ErrorWithLimit errorMessage={errorMessage} />}
		</div>
	);
};
