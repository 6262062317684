import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFindProjectsQuery } from 'src/app/redux/queries/project-service/project_serviceAPI';
import { LineTabs } from 'src/shared/ui/_tabs/lineTabs';
import { PageDecorator } from 'src/app/decorators/page_decorators/PageDecorator/PageDecorator';
import { PageTitle } from 'src/entities/PageTitle/PageTitle';
import { TimeListDownload } from 'src/entities/TimeListDownload';
import { MonthPickerHeader } from 'src/pages/project_service_pages/ui/TimeListPage/ui/MonthPickerHeader/MonthPickerHeader';
import { useGenerateTimeListUser } from 'src/shared/api/useGenerateTimeListUsers';
import { MAX_APP_DATE, MIN_APP_DATE } from 'src/shared/consts/consts';
import { TimeListTable } from 'src/widgets/TimeListTable';
import s from './TimeListDDPage.module.scss';
import { useGenerateDepartments } from './hooks/useGenerateDepartments';
import { ConditionalRendering } from 'src/shared/providers';
import { SelectStringSearchFilter } from 'src/entities/_filters/SelectStringSearchFilter/SelectStringSearchFilter';

export const TimeListDDPage = () => {
	// * Routing
	const { departmentId } = useParams() as { departmentId: string };

	// * Tabs
	const { availableDepartments, isLoadingDepartment } = useGenerateDepartments(departmentId);

	const [tab, setTab] = useState<string | undefined>(undefined);

	useEffect(() => {
		if (availableDepartments.length > 0) {
			setTab(availableDepartments[0].id);
		}
	}, [availableDepartments]);

	const [date, setDate] = useState(new Date());

	// * TimeListTable
	const { users, isLoading: isLoadingGenerateTimeList, refetch } = useGenerateTimeListUser(DateTime.fromJSDate(date), { departmentId: tab });

	// * Filters
	const { data: dataProjects } = useFindProjectsQuery({
		skipCount: 0,
		takeCount: 1000,
		departmentid: departmentId,
	});

	const projects = dataProjects?.body ?? [];
	const selectOptions = [{ id: null, name: '-' }, ...projects];
	const [selectedProject, setSelectedProject] = useState<{ id: null | string; name: string | null }>({ id: null, name: '-' });

	const [userName, setUserName] = useState<string>('');

	const filteredUsers = [...users]
		.filter(user => selectedProject.id === null || user.projects.find(project => project.id === selectedProject.id))
		.filter(user => user.fullName.toLocaleLowerCase().includes(userName.toLocaleLowerCase()));

	const selectedDepartment = availableDepartments.find(item => item.id === tab);

	// * Render
	return (
		<PageDecorator>
			<PageTitle
				title="Таймлиcт ДД"
				titleComponent={
					<MonthPickerHeader
						selectedDate={date}
						setSelectedDate={setDate}
						minDate={MIN_APP_DATE}
						maxDate={MAX_APP_DATE}
					/>
				}
			/>

			<ConditionalRendering
				initialLoading={isLoadingDepartment}
				isSuccessful={!!availableDepartments}
				LoadedResult={
					<>
						{tab && (
							<LineTabs
								className={s.tabs}
								tabs={availableDepartments.map(department => ({
									id: department.id,
									name: department.name,
								}))}
								activeTabId={tab}
								setActiveTabId={activeTabId => setTab(activeTabId)}
							/>
						)}
					</>
				}
			/>

			{tab && (
				<div className={s.filters}>
					<SelectStringSearchFilter
						selectPlaceholder="Проект"
						selectValue={selectedProject}
						setSelect={setSelectedProject}
						selectOptions={selectOptions}
						searchPlaceholder="Поиск по ФИО"
						searchStringValue={userName}
						setSearchString={setUserName}
					/>

					<TimeListDownload
						date={date}
						departmentId={tab}
						name={selectedDepartment?.shortName || selectedDepartment?.name}
					/>
				</div>
			)}

			<ConditionalRendering
				initialLoading={isLoadingGenerateTimeList}
				isSuccessful={!!filteredUsers}
				LoadedResult={
					<TimeListTable
						users={filteredUsers}
						refetch={refetch}
					/>
				}
			/>
		</PageDecorator>
	);
};
