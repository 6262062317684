import React, { HTMLAttributes } from 'react';
import { getTwoCharsAcronym } from 'src/shared/lib/string';
import { image_serviceAPI } from 'src/app/redux/queries/image-serviceAPI/image_serviceAPI';
import { ImageSource } from 'src/app/redux/state/image/image/types';
import { UserAvatar } from 'src/app/redux/state/user/user/types';
import { createGalleyFileFromImageInfo } from '../_galleries/Galley/_utils';
import s from './Avatar.module.scss';
import cn from 'classnames';
import { EMPTY_ID } from 'src/shared/consts/consts';

interface Props extends HTMLAttributes<HTMLDivElement> {
	name: string;
	userAvatar?: UserAvatar | null;
	size?: 'extra-small' | 'small' | 'medium' | 'large';

	avatarId?: string | null;
	imageSource?: ImageSource;
}

export const Avatar: React.FC<Props> = props => {
	const {
		className, //
		name,
		userAvatar,
		size = 'medium',
		avatarId,
		imageSource = 'User',
		...divProps
	} = props;

	// * API
	const { currentData: avatarInfo } = image_serviceAPI.useGetImageQuery(
		{
			params: {
				imageId: avatarId as string,
				source: imageSource,
			},
		},
		{ skip: !avatarId || avatarId === EMPTY_ID || !!userAvatar },
	);

	const avatar = userAvatar || avatarInfo?.body;

	const avatarImage =
		avatar &&
		createGalleyFileFromImageInfo({
			...avatar,
			name: avatar.name || 'avatar.jpg',
		}).preview;

	// * Render
	return (
		<div
			className={cn(s.container, s[`size_${size}`], className)}
			{...divProps}
		>
			{avatarImage ? (
				<img
					className={s[`size_${size}`]}
					src={avatarImage}
					alt={name}
				/>
			) : (
				<span className={cn(s.name, size === 'extra-small' && s.name_small)}>{getTwoCharsAcronym(name)}</span>
			)}
		</div>
	);
};
