import React, { useEffect, useState } from 'react';
import s from './FiltersSurveys.module.scss';
import { injectTimeToDate, isDateInRange, getIsPastDate } from 'src/shared/lib/date';
import { useGetCategoriesQuery } from 'src/app/redux/queries/survey-service/survey_serviceAPI';
import { ReactComponent as SearchSVG } from 'src/shared/assets/svg/action/search.svg';
import { ReactComponent as FiltersSVG } from 'src/shared/assets/svg/action/filters.svg';
import { ReactComponent as ArrowLeftSVG } from 'src/shared/assets/svg/navigation/Arrow_left_v3.svg';
import { DateRangeInput } from 'src/shared/ui/_inputs/date_pickers/DateRangeInput/DateRangeInput';
import { SelectSingle } from 'src/shared/ui/_inputs/selects/SelectSingle/SelectSingle';
import { TextInput } from 'src/shared/ui/_inputs/text_Inputs/TextInput/TextInput';
import { IconButton } from 'src/shared/ui/_buttons/IconButton/IconButton';
import { Radio } from 'src/shared/ui/_inputs/Radio/Radio';
import { useWindowDimensions } from 'src/shared/hooks/useWindowDimensions';
import { FindSurveysResultInfo } from 'src/app/redux/queries/survey-service/types';

interface Props {
	getFilteredResults: (results: any) => void;
	activeTab: string;
	surveys: FindSurveysResultInfo[];
}

export const FiltersSurveys: React.FC<Props> = props => {
	const { getFilteredResults, activeTab, surveys } = props;

	const isCompleted = activeTab === 'completed';

	const { data } = useGetCategoriesQuery({
		skipcount: 0,
		takecount: 1000,
	});

	const categories = data?.body;

	// * Filter
	// - Name
	const [name, setName] = useState('');

	// - Status
	const statusOptions = [
		{ id: 'Все опросы' as const, name: 'Все опросы' },
		{ id: 'Активные' as const, name: 'Активные' },
		{ id: 'Завершённые' as const, name: 'Завершённые' },
	];
	const [status, setStatus] = useState(statusOptions[0]);

	// - Categories
	const defaultCategoryOptions = [
		{ id: 'Все категории' as const, name: 'Все категории' },
		{ id: 'Без категории' as const, name: 'Без категории' },
	];

	const categoryOptions = categories ? [...defaultCategoryOptions, ...categories] : defaultCategoryOptions;
	const [categoriesFilter, setCategoriesFilter] = useState(categoryOptions[0]);

	// - Dates
	const [startDate, setStartDate] = useState<Date | null>(null);
	const [endDate, setEndDate] = useState<Date | null>(null);

	// * Filter apply
	useEffect(() => {
		if (surveys) {
			let filteredSurveys = surveys;

			if (isCompleted) {
				filteredSurveys = filteredSurveys?.filter(survey => survey.isCompleted);
			}

			if (name !== '') {
				filteredSurveys = filteredSurveys?.filter(survey => survey.subject.toLocaleLowerCase().includes(name.toLocaleLowerCase()));
			}

			if (startDate || endDate) {
				filteredSurveys = filteredSurveys?.filter(survey => isDateInRange(new Date(survey.createdAtUtc), [startDate, endDate]));
			}

			if (status.id === 'Завершённые') {
				filteredSurveys = filteredSurveys?.filter(survey => getIsPastDate(survey.deadline));
			}

			if (status.id === 'Активные') {
				filteredSurveys = filteredSurveys?.filter(survey => !getIsPastDate(survey.deadline));
			}

			if (categoriesFilter.id !== 'Все категории') {
				if (categoriesFilter.id === 'Без категории') {
					filteredSurveys = filteredSurveys?.filter(survey => survey.surveyCategories && survey.surveyCategories.length === 0);
				} else {
					filteredSurveys = filteredSurveys?.filter(survey => survey.surveyCategories && survey.surveyCategories.some(category => category.id === categoriesFilter.id));
				}
			}

			getFilteredResults(filteredSurveys);
		}
	}, [surveys, name, status, categoriesFilter, startDate, endDate]);

	useEffect(() => {
		setName('');
		setStatus(statusOptions[0]);
		setCategoriesFilter(categoryOptions[0]);
		setStartDate(null);
		setEndDate(null);
	}, [activeTab]);

	// * Adaptive
	const [isVisibleFilters, setIsVisibleFilters] = useState(false);
	const toggleVisibility = () => setIsVisibleFilters(prevState => !prevState);

	const { width } = useWindowDimensions();
	const isDesktop = width >= 1024;

	// * Render
	return (
		<>
			{!isVisibleFilters && (
				<div className={s.container}>
					<TextInput
						placeholder="Поиск по названию опроса"
						value={name}
						onChange={event => setName(event.target.value)}
						LeftIcon={isDesktop ? null : <FiltersSVG />}
						RightIcon={<SearchSVG />}
						onLeftIconClick={toggleVisibility}
					/>

					<div className={s.filters_wrapper}>
						{activeTab !== 'template' && (
							<SelectSingle
								keyNames={{ name: 'name', value: 'id' }}
								options={statusOptions}
								selectedOption={status}
								setSelectedOption={option => setStatus(option)}
								isNullable={false}
							/>
						)}

						<SelectSingle
							keyNames={{ name: 'name', value: 'id' }}
							options={categoryOptions}
							selectedOption={categoriesFilter}
							setSelectedOption={option => setCategoriesFilter(option)}
							isNullable={false}
						/>

						<DateRangeInput
							placeholderText="ДД.ММ.ГГГГ"
							startDate={startDate}
							setStartDate={date => setStartDate(date ? injectTimeToDate(date, '00:00') : date)}
							endDate={endDate}
							setEndDate={date => setEndDate(date ? injectTimeToDate(date, '23:59') : date)}
							isClearable={true}
						/>
					</div>
				</div>
			)}

			{isVisibleFilters && (
				<div className={s.mobile_container}>
					<div className={s.mobile_header}>
						<IconButton
							style={{ padding: '0.5rem' }}
							Icon={<ArrowLeftSVG />}
							onClick={() => setIsVisibleFilters(false)}
						/>

						<h5>Фильтры</h5>
					</div>

					<div className={s.mobile_filters_wrapper}>
						<div className={s.filter}>
							<h5 className={s.subtitle}>Статус опросов</h5>

							{statusOptions.map(option => (
								<div
									key={option.id}
									className={s.option}
								>
									<Radio
										checked={status.id === option.id}
										onChange={() => setStatus(option)}
									/>

									<div className={s.option_text}>{option.name}</div>
								</div>
							))}
						</div>

						<div className={s.filter}>
							<h5 className={s.subtitle}>Временной диапазон</h5>

							<DateRangeInput
								placeholderText="ДД.ММ.ГГГГ"
								startDate={startDate}
								setStartDate={date => setStartDate(date ? injectTimeToDate(date, '00:00') : date)}
								endDate={endDate}
								setEndDate={date => setEndDate(date ? injectTimeToDate(date, '23:59') : date)}
								isClearable={true}
							/>
						</div>

						<div className={s.filter}>
							<h5 className={s.subtitle}>Категории</h5>

							{categoryOptions.map(option => (
								<div
									key={option.id}
									className={s.option}
								>
									<Radio
										checked={categoriesFilter.id === option.id}
										onChange={() => setCategoriesFilter(option)}
									/>

									<div className={s.option_text}>{option.name}</div>
								</div>
							))}
						</div>
					</div>
				</div>
			)}
		</>
	);
};
