import React, { DetailedHTMLProps, HTMLAttributes, useMemo } from 'react';
import { ReactComponent as LogoSVG } from 'src/shared/assets/svg/logo.svg';
import s from './Nav.module.scss';

import cn from 'classnames';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from 'src/app/redux/utils';
import { routerPath } from 'src/app/router/paths';
import { NavItem } from 'src/features/_layout/Nav/ui/NavItem/NavItem';
import { ReactComponent as TimeListSVG } from 'src/shared/assets/svg/timeList.svg';
import { useRights } from 'src/shared/hooks/useRights';
import { isDevEnv } from 'src/shared/lib/app';
import { MENU_ITEMS_PRIVATE_DEV, MENU_ITEMS_PUBLIC_DEV } from './consts/MENU_ITEMS';
import { MENU_ITEMS_PRIVATE_PROD, MENU_ITEMS_PUBLIC_PROD } from './consts/MENU_ITEMS_PROD';
import { isActive } from './lib/isActive';

const menuItemsPrivate = isDevEnv ? MENU_ITEMS_PRIVATE_DEV : MENU_ITEMS_PRIVATE_PROD;
const menuItemsPublic = isDevEnv ? MENU_ITEMS_PUBLIC_DEV : MENU_ITEMS_PUBLIC_PROD;

interface Props extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
	isPrivate: boolean;
}

export const Nav: React.FC<Props> = props => {
	const {
		isPrivate, //
		className,
		...divProps
	} = props;

	const location = useLocation();

	// * Selectors
	const userId = useAppSelector(state => state.user_service.user.userInfo?.user.id);
	const departmentUser = useAppSelector(state => state.user_service.user.userInfo?.departmentUser);

	const menuItems = isPrivate ? menuItemsPrivate : menuItemsPublic;

	const isAdmin = useRights();
	const allowedHR = useRights(18);

	const role = departmentUser?.role;

	if (role === 'Manager' && isPrivate) {
		const departmentId = departmentUser?.department.id;

		const TITLE = 'Таймлист ДД';

		const tileExists = !menuItems.find(item => item.title === TITLE);

		if (tileExists) {
			menuItems.splice(4, 0, {
				title: TITLE,
				Icon: <TimeListSVG />,
				route: `${routerPath.departments.timeList}/${departmentId}`,
			});
		}
	}

	// * Render
	return (
		<div
			{...divProps}
			className={cn(s.container, className)}
		>
			<a
				className={s.logoWrapper}
				href={`/users/${userId}`}
			>
				<LogoSVG className={s.logo} />
				<span className={s.title}>ЦИФРОВОЙ ОФИС</span>
			</a>

			<div className={s.menuOptions}>
				{menuItems
					.filter(menuItem => {
						if (menuItem.title === 'Опросы') {
							// Пункты меню которые должны быть только в dev
							return isDevEnv;
						}
						if (menuItem.title === 'Администрирование' || menuItem.title === 'Обратная связь' || menuItem.title === 'Офисы') {
							return isAdmin;
						}
						if (menuItem.title === 'HR') {
							return allowedHR;
						}
						return true;
					})
					.map(menuItem => (
						<NavItem
							key={menuItem.title}
							active={isActive(location.pathname, menuItem.route)}
							{...menuItem}
						/>
					))}
			</div>
		</div>
	);
};
