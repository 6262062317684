import { yupResolver } from '@hookform/resolvers/yup';
import { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { user_serviceAPI } from 'src/app/redux/queries/user-service/user_serviceAPI';
import { actionsAuth } from 'src/app/redux/state/auth/slice';
import { LoginRequestBody } from 'src/app/redux/state/auth/types';
import { useAppDispatch } from 'src/app/redux/utils';
import { Button } from 'src/shared/ui/_buttons/Button/Button';
import { PasswordField } from 'src/shared/ui/_fields/PasswordField';
import * as Yup from 'yup';
import s from '../AuthPage/AuthPage.module.scss';

export const Reactivate: FC = () => {
	const navigate = useNavigate();

	const dispatch = useAppDispatch();
	const { setTokens } = actionsAuth;

	// * Params
	const [params] = useSearchParams();
	const userId = params.get('userId');

	useEffect(() => {
		if (!userId) {
			navigate('/');
		}
	}, [userId]);

	// * API
	const [reactivate, { isLoading }] = user_serviceAPI.usePutCredentialsReactivateMutation();

	// * Form
	const schema = Yup.object().shape({
		password: Yup.string().required(''),
	});

	const formMethods = useForm({
		defaultValues: {
			password: '',
		},
		resolver: yupResolver(schema),
	});

	const { handleSubmit } = formMethods;

	const onSubmit = (values: Pick<LoginRequestBody, 'password'>) => {
		reactivate({
			reactivateCredentialsRequest: {
				password: values.password,
				userId: userId as string,
			},
		})
			.unwrap()
			.then(res => res?.body && dispatch(setTokens(res.body)))
			.catch(err => console.log('reactivate err: ', err));
	};

	return (
		<form
			className={s.AuthPage_form}
			onSubmit={handleSubmit(onSubmit)}
		>
			<FormProvider {...formMethods}>
				<PasswordField
					name="password"
					placeholder="Введите пароль из письма"
				/>

				<Button
					type="submit"
					isLoading={isLoading}
					wide
				>
					Войти
				</Button>
			</FormProvider>
		</form>
	);
};
