import React from 'react';
import s from './ChoiceGrid.module.scss';
import { ReactComponent as CloseSVG } from 'src/shared/assets/svg/action/close.svg';
import { OptionInput } from './OptionInput/OptionInput';
import { IconButton } from 'src/shared/ui/_buttons/IconButton/IconButton';
import { GridOption } from '../../../_types';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DragDropHandle } from 'src/shared/ui/DragDropHandle/DragDropHandle';

interface Props {
	option: GridOption;
	allRows: GridOption[];
	indexes: {
		indexSection: number;
		indexQuestion: number;
		indexRow: number;
	};
	onRowSignatureChange: (value: string, prevValue: string, indexSection: number, indexQuestion: number) => void;
	removeRow: (indexSection: number, indexQuestion: number, rowValue: string) => void;

	isEdit?: boolean;
}

export const ChoiceGridOptionRow: React.FC<Props> = props => {
	const {
		option,
		allRows,
		indexes: { indexSection, indexQuestion, indexRow },
		onRowSignatureChange,
		removeRow,

		isEdit,
	} = props;

	// * DnD
	const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: option.id });

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	};

	// * Render
	return (
		<div
			className={s.row_field}
			style={style}
			ref={setNodeRef}
		>
			{!isEdit && (
				<DragDropHandle
					{...attributes}
					{...listeners}
				/>
			)}

			<div className={s.count}>{indexRow + 1}.</div>

			<OptionInput
				id={option.id}
				placeholder={`Строка ${indexRow + 1}`}
				value={option.rowSignature}
				existValues={allRows.map(item => item.rowSignature).filter(item => item !== option.rowSignature)}
				onSignatureChange={value => onRowSignatureChange(value, option.rowSignature, indexSection, indexQuestion)}
			/>

			{allRows.length > 1 && !isEdit && (
				<IconButton
					Icon={<CloseSVG />}
					onClick={e => {
						e.preventDefault();
						e.stopPropagation();
						removeRow(indexSection, indexQuestion, option.rowSignature);
					}}
				/>
			)}
		</div>
	);
};
