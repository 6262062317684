import React, { useState } from 'react';
import { useAppSelector } from 'src/app/redux/utils';
import { AddUserMC } from 'src/pages/offices/ui/_pages/SingleOfficePage/Content/UsersContent/AddUserMC/AddUserMC';
import { UsersList } from 'src/pages/offices/ui/_pages/SingleOfficePage/Content/UsersContent/UsersList/UsersList';
import { AddButton } from 'src/shared/ui/_buttons/AddButton/AddButton';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';
import { EventCreationHeader } from '../../../../../EventCreationHeader/EventCreationHeader';
import s from './AddUsersToEvent.module.scss';

interface Props {
	formName: string;
	onSubmitAfterValidation: () => void;
}

export const AddUsersToEvent: React.FC<Props> = props => {
	const {
		formName, //
		onSubmitAfterValidation,
	} = props;

	const [showModal, setShowModal] = useState(false);

	const toggleModal = () => setShowModal(prevState => !prevState);

	const event = useAppSelector(state => state.event_calendar.event.event);
	const selectedUsersFull = useAppSelector(state => state.event_calendar.event.selectedUsersInfo);

	const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (selectedUsersFull.users.length > 0) {
			onSubmitAfterValidation();
		}
	};

	return (
		<div>
			<form
				id={formName}
				onSubmit={onSubmit}
			/>
			<div className={s.header}>
				<EventCreationHeader title="Участники" />
			</div>

			<div className={s.userNumber}>Всего участников: {event?.users?.length || 0}</div>

			<div className={s.addButton}>
				<AddButton
					title="Пригласить участника"
					onClick={toggleModal}
				/>
			</div>

			{showModal && (
				<ModalNewProto
					isOpen={showModal}
					onClose={toggleModal}
				>
					<AddUserMC
						toggleModal={toggleModal}
						addToEvent={true}
					/>
				</ModalNewProto>
			)}

			{selectedUsersFull.users.length > 0 && <UsersList users={selectedUsersFull.users} />}
		</div>
	);
};
