import React, { useEffect } from 'react';
import s from './OfficeSelectionCard.module.scss';
import mapIMG from 'src/shared/assets/img/map.png';
import { SelectSingle } from 'src/shared/ui/_inputs/selects/SelectSingle/SelectSingle';
import { useAppDispatch, useAppSelector } from 'src/app/redux/utils';
import { officeServiceActions } from 'src/app/redux/state/office/actions';
import { bookingPageActions } from 'src/app/redux/state/office/_states/booking_page_state';
import { Map, Placemark, YMaps } from '@pbe/react-yandex-maps';
import { OfficeSchema } from 'src/entities/OfficeSchema/OfficeSchema';

interface Props {
	setOfficeId: (officeId: string) => void;
}

export const OfficeSelectionCard: React.FC<Props> = props => {
	const { setOfficeId } = props;

	// * Selectors
	const selectedAddress = useAppSelector(state => state.bookingPage.selectedOffice);
	const officesList = useAppSelector(state => state.office_service.office.officesList);

	// * Actions
	const dispatch = useAppDispatch();
	const { setSelectedAddress } = bookingPageActions;
	const { findOffices } = officeServiceActions.office.async;

	// * Initial request
	useEffect(() => {
		dispatch(
			findOffices({
				includeDeactivated: false,
				takeCount: 1000,
				skipCount: 0,
			}),
		);
	}, []);

	// * selectOffice
	useEffect(() => {
		if (selectedAddress.id) {
			setOfficeId(selectedAddress.id);
		} else {
			setOfficeId('');
		}
	}, [selectedAddress]);

	// * Render
	return (
		<div className={s.container}>
			<h3>Выбор офиса</h3>

			<SelectSingle
				label="Адрес"
				placeholder="Выберите офис из списка"
				keyNames={{
					name: 'name',
					value: 'id',
				}}
				options={officesList ? officesList : []}
				selectedOption={selectedAddress}
				setSelectedOption={value => dispatch(setSelectedAddress(value))}
			/>

			{selectedAddress.id ? (
				<>
					{selectedAddress.longitude && selectedAddress.latitude && (
						<div className={s.map}>
							<YMaps>
								<Map
									state={{
										center: [selectedAddress.latitude, selectedAddress.longitude],
										zoom: 10,
									}}
									height="18rem"
									width="100%"
								>
									<Placemark geometry={[selectedAddress.latitude, selectedAddress.longitude]} />
								</Map>
							</YMaps>
						</div>
					)}

					<div className={s.officeAbout}>
						<div className={s.officeAbout__inner}>
							<div>
								<h4>{selectedAddress.name}</h4>

								<h5>{selectedAddress.address}</h5>
							</div>

							{selectedAddress.id && (
								<OfficeSchema
									officeId={selectedAddress.id}
									buttonVariant="secondary"
									imagesOnly
								/>
							)}
						</div>

						<div className={s.description_office}>{selectedAddress.description}</div>
					</div>
				</>
			) : (
				<div className={s.map}>
					<img
						className={s.map__image}
						src={mapIMG}
						alt="Карта"
					/>
					<p className={s.map__placeholder}>{'Выберите офис \n для продолжения бронирования'}</p>
				</div>
			)}
		</div>
	);
};
