import { ChangeEvent } from 'react';
import { getIsChecked } from 'src/pages/surveys/libs/getIsChecked/getIsChecked';
import { Checkbox } from 'src/shared/ui/_inputs/Checkbox/Checkbox';
import { Radio } from 'src/shared/ui/_inputs/Radio/Radio';
import { useFocus } from 'src/shared/hooks/useFocus';
import { Option, SectionProps } from '../../../../NewSurveyPage/_types';
import s from './ChoiceAnswer.module.scss';

interface Props {
	type: 'radio' | 'check';
	options: Option[];
	hasCustomOptions: boolean;

	indexGroup: number;
	indexQuestion: number;

	fillingInfo: Array<SectionProps>;
	changeFillingInfo: (info: Array<SectionProps>) => void;

	isEndedSurvey?: boolean;
	isCompleted?: boolean;
}

export const ChoiceAnswer = (props: Props) => {
	const {
		type,
		options,
		hasCustomOptions,

		indexGroup,
		indexQuestion,

		fillingInfo,
		changeFillingInfo,

		isEndedSurvey,
		isCompleted,
	} = props;

	// * Variables
	const filteredOptions = options.filter(opt => !opt.isCustom);
	const cleanAnswers = filteredOptions.map(opt => ({
		...opt,
		usersAnswers: [],
	}));

	const customAnswer = options.filter(opt => opt.isCustom && opt.usersAnswers && opt.usersAnswers.length > 0)[0];
	const hasCustomAnswer = options.filter(opt => opt.isCustom && opt.usersAnswers && opt.usersAnswers.length > 0).length > 0;

	// * onChange
	const onChangeCheckOption = (e: ChangeEvent<HTMLInputElement>, id: string) => {
		const checked = e.target.checked;

		const changedGroups = fillingInfo.map((group, i) => {
			if (i !== indexGroup) return group;

			return {
				...group,
				isActive: true,
				questions: group.questions.map((question, ind) => {
					if (ind !== indexQuestion) return question;

					return {
						...question,
						options: question.options.map(option => {
							if (option.id !== id) return option;

							const newAnswer = checked ? [{ id: option.id }] : [];

							return {
								...option,
								usersAnswers: newAnswer,
							};
						}),
					};
				}),
			};
		});

		changeFillingInfo([...changedGroups]);
	};

	const onChangeRadioOption = (id: string) => {
		const changedGroups = fillingInfo.map((group, i) => {
			if (i !== indexGroup) return group;

			return {
				...group,
				isActive: true,
				questions: group.questions.map((question, ind) => {
					if (ind !== indexQuestion) return question;

					return {
						...question,
						options: question.options.map(option => {
							const newAnswer = option.id === id ? [{ id: option.id }] : [];

							return {
								...option,
								usersAnswers: newAnswer,
							};
						}),
					};
				}),
			};
		});

		changeFillingInfo([...changedGroups]);
	};

	// * Custom option
	const onChangeCustomOption = (checked: boolean, value: string, type: 'check' | 'radio') => {
		const changedGroups = fillingInfo.map((group, i) => {
			if (i !== indexGroup) return group;

			return {
				...group,
				isActive: true,
				questions: group.questions.map((question, ind) => {
					if (ind !== indexQuestion) return question;

					const { options } = question;

					if (type === 'check') {
						const newCustomOpts: Option[] = hasCustomAnswer
							? [
									...filteredOptions,
									{
										...customAnswer,
										content: value,
										usersAnswers: [{ id: customAnswer.id }],
									},
								]
							: [
									...options,
									{
										id: 'custom_option',
										content: value,
										isCustom: true,
										position: options.length + 1,
										usersAnswers: [
											{
												id: 'custom_option',
											},
										],
									},
								];

						const newOptions = checked
							? newCustomOpts
							: [
									...filteredOptions,
									{
										...customAnswer,
										usersAnswers: [],
									},
								];

						return {
							...question,
							options: newOptions,
						};
					} else {
						// radio
						const newCustomOpts: Option[] = hasCustomAnswer
							? [
									...cleanAnswers,
									{
										...customAnswer,
										content: value,
										usersAnswers: [{ id: customAnswer.id }],
									},
								]
							: [
									...cleanAnswers,
									{
										id: 'custom_option',
										content: value,
										isCustom: true,
										position: options.length + 1,
										usersAnswers: [
											{
												id: 'custom_option',
											},
										],
									},
								];

						const newOptions = checked
							? newCustomOpts
							: [
									...cleanAnswers,
									{
										...customAnswer,
										usersAnswers: [],
									},
								];

						return {
							...question,
							options: newOptions,
						};
					}
				}),
			};
		});

		changeFillingInfo([...changedGroups]);
	};

	const onChangeContentCustomOption = (e: ChangeEvent<HTMLInputElement>, type: 'check' | 'radio') => {
		const value = e.currentTarget.value;
		const checked = Boolean(value.trim());

		type === 'check' ? onChangeCustomOption(checked, value, type) : onChangeCustomOption(checked, value, type);
	};

	const [ref, setFocus] = useFocus();

	// * Render
	return (
		<div className={s.container}>
			{filteredOptions.map(option => (
				<div
					key={option.id}
					className={s.option}
				>
					{type === 'check' && (
						<Checkbox
							label={option.content}
							checked={getIsChecked(option)}
							onChange={e => onChangeCheckOption(e, option.id)}
							disabled={isCompleted}
							noDisabledStyles={!isEndedSurvey}
						/>
					)}

					{type === 'radio' && (
						<Radio
							label={option.content}
							checked={getIsChecked(option)}
							onChange={() => onChangeRadioOption(option.id)}
							disabled={isCompleted}
							noDisabledStyles={!isEndedSurvey}
						/>
					)}
				</div>
			))}

			{hasCustomOptions && (
				<div
					className={s.option}
					onClick={setFocus}
				>
					{type === 'check' && (
						<Checkbox
							label="Другое"
							checked={getIsChecked(customAnswer)}
							disabled={isCompleted}
							noDisabledStyles={!isEndedSurvey}
						/>
					)}

					{type === 'radio' && (
						<Radio
							label="Другое"
							checked={getIsChecked(customAnswer)}
							disabled={isCompleted}
							noDisabledStyles={!isEndedSurvey}
						/>
					)}

					{/* TODO: Сделать по человечески %) */}
					<input
						ref={ref}
						type="text"
						style={{
							width: '100%',
							fontSize: '1rem',
							fontFamily: '"Lato", Arial, serif',
							borderStyle: 'none',
							borderBottom: '1px solid var(--color-primary-2)',
							outline: 'none',
						}}
						value={customAnswer?.content ? customAnswer?.content : ''}
						maxLength={300}
						onChange={e => onChangeContentCustomOption(e, type)}
						disabled={isCompleted}
					/>
				</div>
			)}
		</div>
	);
};
