import { createColumnHelper } from '@tanstack/react-table';
import { TableItem } from './types';

const columnHelper = createColumnHelper<TableItem>();

export const COLUMNS = [
	columnHelper.accessor('Checkbox', {
		header: '',
		cell: info => info.getValue(),
		meta: {
			style: { width: '1%' },
		},
	}),
	columnHelper.accessor('Avatar', {
		header: 'ФИО',
		cell: info => info.getValue(),
		meta: {
			style: { width: '1%' },
		},
	}),
	columnHelper.accessor('Name', {
		header: '',
		cell: info => info.getValue(),
	}),
	columnHelper.accessor('department', {
		header: 'ДЕПАРТАМЕНТ',
		cell: info => info.getValue(),
	}),
];
