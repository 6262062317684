import { createColumnHelper } from '@tanstack/react-table';
import { WorkTimeTableDataItem } from '../_types';

const columnHelper = createColumnHelper<WorkTimeTableDataItem>();

export const COLUMNS_WORK_TIME = [
	columnHelper.accessor('name', {
		header: 'НАЗВАНИЕ ПРОЕКТА',
		cell: info => info.getValue(),
	}),
	columnHelper.accessor('userHours', {
		header: 'ВНЕСЕННЫЕ ЧАСЫ',
		cell: info => info.getValue(),
	}),
	columnHelper.accessor('date', {
		header: 'ДАТА ВНЕСЕНИЯ',
		cell: info => info.getValue() ?? '-',
	}),
	columnHelper.accessor('description', {
		header: 'КОММЕНТАРИЙ',
		cell: info => {
			const value = info.getValue();
			return value && value.toString().length > 0 ? info.getValue() : '-';
		},
	}),
	columnHelper.accessor('extra', {
		header: '',
		cell: info => info.getValue(),
		meta: {
			style: {
				width: '1%',
			},
		},
	}),
];
