import { createColumnHelper } from '@tanstack/react-table';
import { ReactNode } from 'react';
import { ReactComponent as ArrowDownSVG } from 'src/shared/assets/svg/navigation/Arrow_down_v1.svg';
import { ReactComponent as ArrowUpSVG } from 'src/shared/assets/svg/navigation/Arrow_up_v1.svg';
import { LeaveInfoTableDataItem } from '../lib/generateColumnsLeaveInfo';
import { ProjectsInfoTableDataItem } from '../lib/generateColumnsProjectsInfo';
import s from '../styles/columns.module.scss';

export interface TimeListTableDataItem {
	id: string;
	avatar: ReactNode;
	fullName: string;
	normHours: string;
	projectsCount: string;
	contract: string;
	projects: ProjectsInfoTableDataItem[];
	leave: LeaveInfoTableDataItem[];
	isActive: boolean;
}

const columnHelper = createColumnHelper<TimeListTableDataItem>();

export const COLUMNS_TIMELIST = [
	columnHelper.accessor('avatar', {
		header: () => null,
		cell: info => info.getValue(),
	}),
	columnHelper.accessor('fullName', {
		header: 'ФИО',
		cell: info => info.getValue(),
	}),
	columnHelper.accessor('normHours', {
		header: 'ЧАСЫ/НОРМА',
		cell: info => info.getValue(),
	}),
	columnHelper.accessor('projectsCount', {
		header: 'ПРОЕКТЫ',
		cell: info => info.getValue(),
	}),
	columnHelper.accessor('contract', {
		header: 'ТИП ДОГОВОРА',
		cell: info => info.getValue(),
	}),
	columnHelper.accessor('id', {
		header: () => null,
		footer: props => props.column.id,
		cell: ({ row }) => {
			if (!row.getCanExpand()) return null;

			return <div className={s.icon_wrapper}>{row.getIsExpanded() ? <ArrowUpSVG /> : <ArrowDownSVG />}</div>;
		},
	}),
];
