import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { UserResponse } from 'src/app/redux/queries/user-service/types/types';
import { RootState } from 'src/app/redux/store';
import { ArchiveModal } from 'src/pages/UsersPage/ui/ArchiveModal/ArchiveModal';
import { Form } from 'src/pages/UsersPage/ui/Form/Form';
import { getDefaultValues } from 'src/pages/UsersPage/ui/Form/consts/defaultValues';
import { UserActivation } from 'src/pages/UsersPage/ui/UserActivation/UserActivation';
import { ReactComponent as CLoseSVG } from 'src/shared/assets/svg_icons/action/close.svg';
import { ReactComponent as EditSVG } from 'src/shared/assets/svg_icons/action/edit.svg';
import { ReactComponent as ArchiveSVG } from 'src/shared/assets/svg_icons/file/archive.svg';
import { ReactComponent as UnArchiveSVG } from 'src/shared/assets/svg_icons/file/unarchive.svg';
import { ReactComponent as EmailSVG } from 'src/shared/assets/svg_icons/social/mail.svg';
import { useRights } from 'src/shared/hooks/useRights';
import { DropDownMenu } from 'src/shared/ui/DropDownMenu/DropDownMenu';
import { Heading } from 'src/shared/ui/Heading';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';
import s from '../General/General.module.scss';

interface Props {
	userInfo: UserResponse;
}

export const GeneralHeader: FC<Props> = props => {
	const { userInfo } = props;

	const userGeneralInfo = getDefaultValues(userInfo);

	// * Personal
	const personalInfo = useSelector((state: RootState) => state.user_service.user.userInfo);
	const isPersonalCard = personalInfo?.user.id === userInfo.user.id;

	// * Rights
	const hasEditUsersRights = useRights(1);
	const hasEditCompanyDataRights = useRights(11);
	const hasEditPositionRights = useRights(6);
	const hasEditRoleRights = useRights(12);
	const isAdmin = useRights();

	// * Modal
	const [isEditGeneral, setIsEditGeneral] = useState(false);
	const toggleEditGeneral = () => setIsEditGeneral(prevState => !prevState);

	const [isArchiveModal, setIsArchiveModal] = useState(false);
	const toggleArchiveModal = () => setIsArchiveModal(prevState => !prevState);

	const [isUnArchiveModal, setIsUnArchiveModal] = useState(false);
	const toggleUnArchiveModal = () => setIsUnArchiveModal(prevState => !prevState);

	// * Drop down menu
	const items = [
		{
			icon: <EmailSVG />,
			title: 'Отправить приглашение',
			isVisible: hasEditUsersRights && !!userInfo.user.pendingInfo,
			action: () => toggleUnArchiveModal(),
		},
		{
			icon: <CLoseSVG />,
			title: 'Отменить приглашение',
			isVisible: hasEditUsersRights && !!userInfo.user.pendingInfo,
			action: () => toggleArchiveModal(),
		},
		{
			icon: <EditSVG />,
			title: 'Редактировать',
			isVisible: userInfo.user.isActive && (isPersonalCard || hasEditUsersRights || hasEditCompanyDataRights || hasEditPositionRights || hasEditRoleRights),
			action: () => toggleEditGeneral(),
		},
		{
			icon: <ArchiveSVG />,
			title: 'Добавить в архив',
			isVisible: isAdmin && userInfo.user.isActive && !userInfo.user.pendingInfo,
			action: () => toggleArchiveModal(),
		},
		{
			icon: <UnArchiveSVG />,
			title: 'Восстановить из архива',
			isVisible: isAdmin && !userInfo.user.isActive && !userInfo.user.pendingInfo,
			action: () => toggleUnArchiveModal(),
		},
	].filter(item => item.isVisible);

	//* Render
	return (
		<>
			{isEditGeneral && (
				<ModalNewProto
					isOpen={isEditGeneral}
					onClose={toggleEditGeneral}
				>
					<Form
						userInfo={userGeneralInfo}
						isPersonalCard={isPersonalCard}
						onCloseModal={toggleEditGeneral}
					/>
				</ModalNewProto>
			)}

			{isArchiveModal && (
				<ArchiveModal
					id={userInfo.user.id}
					isActive={userInfo.user.isActive}
					isVisible={isArchiveModal}
					onClose={toggleArchiveModal}
				/>
			)}

			{isUnArchiveModal && (
				<ModalNewProto
					isOpen={isUnArchiveModal}
					onClose={toggleUnArchiveModal}
				>
					<UserActivation
						userId={userInfo.user.id}
						isPending={!!userInfo.user.pendingInfo}
						onClose={toggleUnArchiveModal}
						contacts={userInfo.user.communications || []}
					/>
				</ModalNewProto>
			)}

			<div className={s.header}>
				<Heading
					level={2}
					size={3}
				>
					Основная информация
				</Heading>

				<div className={s.actions}>
					{items.length > 0 && (
						<DropDownMenu
							items={items}
							value={userInfo.user.id}
						/>
					)}
				</div>
			</div>
		</>
	);
};
