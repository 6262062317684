import cn from 'classnames';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { PageDecorator } from 'src/app/decorators/page_decorators';
import { useEditFeedbackStatusesMutation } from 'src/app/redux/queries/feedback-service/feedback_serviceAPI';
import { useGetFeedbackGetQuery } from 'src/app/redux/queries/gateway-service/gateway_serviceAPI';
import { FeedbackStatusType } from 'src/app/redux/queries/gateway-service/types/types';
import { routerPath } from 'src/app/router/paths';
import { ReactComponent as DeleteSVG } from 'src/shared/assets/svg/action/delete.svg';
import { backendISOUTCToLocalLuxon, dateToFullRuDateString, dateToTimeString } from 'src/shared/lib/date';
import { Heading } from 'src/shared/ui/Heading';
import { NavRoute } from 'src/shared/ui/NavRoute';
import { IconButton } from 'src/shared/ui/_buttons/IconButton/IconButton';
import { SimpleGallery } from 'src/shared/ui/_galleries/SimpleGallery/ui/SimpleGallery/SimpleGallery';
import { SelectSingle } from 'src/shared/ui/_inputs/selects/SelectSingle/SelectSingle';
import { CATEGORIES_RUS } from '../FeedbackPage/consts';
import { DeleteModal } from '../FeedbackPage/ui/DeleteModal';
import s from './SingleFeedbackPage.module.scss';
import { STATUSES_RUS } from './consts';
import { InfoItem } from './ui/InfoItem';
import { ReactComponent as CircleSVG } from 'src/shared/assets/svg_icons/social/circle.svg';
import { LoaderCircle } from 'src/shared/ui/_loaders/LoaderCircle/LoaderCircle';

export const SingleFeedbackPage = () => {
	// * Router
	const { feedbackId } = useParams() as { feedbackId: string };

	// * API
	const {
		data,
		refetch,
		isLoading: InitialLoading,
	} = useGetFeedbackGetQuery({
		feedbackId,
	});

	const feedbackInfo = data?.body;

	console.log('feedbackInfo :>> ', feedbackInfo);

	const [editFeedback, { isLoading }] = useEditFeedbackStatusesMutation();

	// * Actions
	const onEditClick = (status: Exclude<FeedbackStatusType, 'Archived'>) => {
		editFeedback({
			editFeedbackStatusesRequest: {
				FeedbackIds: [feedbackId],
				Status: status,
			},
		}).finally(() => refetch());
	};

	// * Status select
	const selectOptions = Object.entries(STATUSES_RUS).map(([key, value]) => ({
		id: key as Exclude<FeedbackStatusType, 'Archived'>,
		name: value.name,
		Icon: <CircleSVG style={{ fill: value.color }} />,
	}));

	const selectedOption = (() => {
		const feedbackStatus = Object.keys(STATUSES_RUS).find(status => status === feedbackInfo?.feedback?.status);
		if (feedbackStatus) {
			return selectOptions.find(option => option.id === feedbackStatus);
		}
	})();

	// * Modal
	const [modal, setModal] = useState<boolean>(false);

	const toggleModal = () => setModal(prevState => !prevState);

	// * Conditions
	const imagesExist = feedbackInfo?.feedbackImages && feedbackInfo?.feedbackImages.length > 0;

	// * Render
	return (
		<PageDecorator>
			<NavRoute
				className={s.route}
				route={routerPath.feedback.page}
				text="Обратная связь"
			/>

			<DeleteModal
				selectedIds={[feedbackId]}
				isActive={modal}
				toggleModal={toggleModal}
			/>

			{InitialLoading ? (
				<LoaderCircle />
			) : (
				<div className={s.container}>
					<div className={s.header}>
						<div className={s.header__left}>
							{/* <Heading
							className={s.header__heading}
							level={1}
							size={2}
						>
							№ -
						</Heading> */}

							{selectedOption && (
								<SelectSingle
									className={s.header__select}
									selectedOption={selectedOption}
									setSelectedOption={option => onEditClick(option.id)}
									keyNames={{
										value: 'id',
										name: 'name',
									}}
									options={selectOptions}
									disabled={isLoading}
								/>
							)}
						</div>

						<IconButton
							Icon={<DeleteSVG />}
							onClick={toggleModal}
							disabled={isLoading}
						/>
					</div>

					{feedbackInfo &&
						(() => {
							const { senderFullName, createdAtUtc, type, content } = feedbackInfo.feedback;
							const createDate = backendISOUTCToLocalLuxon(createdAtUtc).toJSDate();

							return (
								<div className={cn(s.content, imagesExist && s.content_withImages)}>
									<Heading
										level={2}
										size={3}
									>
										{senderFullName}
									</Heading>

									<div className={s.content__row}>
										<InfoItem
											title="Дата и время"
											content={`${dateToFullRuDateString(createDate)} ${dateToTimeString(createDate, true)}`}
										/>

										<InfoItem
											title="Категория запроса"
											content={CATEGORIES_RUS[type]}
										/>
									</div>

									<InfoItem
										title="Комментарий"
										content={content}
									/>
								</div>
							);
						})()}

					{imagesExist && <SimpleGallery images={feedbackInfo?.feedbackImages} />}
				</div>
			)}
		</PageDecorator>
	);
};
