import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getIsChecked } from 'src/pages/surveys/libs/getIsChecked/getIsChecked';
import { getAnswers } from 'src/pages/surveys/libs/getAnswers/getAnswers';
import { getGridRows } from 'src/pages/surveys/libs/getGridRows/getGridRows';
import { getIsPastDate } from 'src/shared/lib/date';
import { RootState } from 'src/app/redux/store';
import { surveyPageActions } from 'src/app/redux/state/survey/slice';
import { survey_serviceAPI } from 'src/app/redux/queries/survey-service/survey_serviceAPI';
import { useActions } from 'src/app/redux/utils';
import { Button } from 'src/shared/ui/_buttons/Button/Button';
import { Modal } from 'src/shared/ui/_modals/Modal/ui/Modal/Modal';
import { LoaderCircle } from 'src/shared/ui/_loaders/LoaderCircle/LoaderCircle';
import { PageDecorator } from 'src/app/decorators/page_decorators/PageDecorator/PageDecorator';
import { PageTitle } from 'src/entities/PageTitle/PageTitle';
import { useRights } from 'src/shared/hooks/useRights';
import { useWindowDimensions } from 'src/shared/hooks/useWindowDimensions';
import { FillingOutForm } from '../../components/FillingOutForm/FillingOutForm';
import { ModalContent } from '../../components/ModalContent/ModalContent';
import { Option, SectionProps } from '../NewSurveyPage/_types';
import s from './DisplaySurveyPage.module.scss';
import { SuccessSurvey } from './SuccessSurvey/SuccessSurvey';

interface CustomAnswers {
	questionId: string;
	options: Pick<Option, 'content' | 'isCustom'>[];
}

export const DisplaySurveyPage: React.FC = () => {
	// * Rights
	const isAdmin = useRights();

	// * Window
	const { width } = useWindowDimensions();
	const isDesktop = width >= 1024;

	// * States
	const [isSend, setIsSend] = useState(false);

	// * Navigate
	const navigate = useNavigate();

	// * Action
	const { setRemoteSurveyName } = useActions(surveyPageActions);

	// * survey id
	const { surveyId } = useParams<'surveyId'>();
	const isNewSurvey = surveyId === 'new';

	// * localStorage
	const visitedSurveysJSON = localStorage.getItem('visited_surveys');
	const visitedSurveys = visitedSurveysJSON ? JSON.parse(visitedSurveysJSON) : null;
	const isVisitedSurvey = Array.isArray(visitedSurveys) && !!visitedSurveys.find(id => id === surveyId);

	useEffect(() => {
		if (!Array.isArray(visitedSurveys) && !isVisitedSurvey) {
			localStorage.setItem('visited_surveys', JSON.stringify([surveyId]));
		}

		if (Array.isArray(visitedSurveys) && !isVisitedSurvey) {
			localStorage.setItem('visited_surveys', JSON.stringify([...visitedSurveys, surveyId]));
		}
	}, []);

	// * User id
	const userInfo = useSelector((state: RootState) => state.user_service.user.userInfo);
	const userId = userInfo?.user.id || '';

	// * API
	const [getSurvey, { currentData: surveyIdData, isLoading: isLoadingData }] = survey_serviceAPI.useLazyGetSurveysSurveyidQuery();
	const isRevoteAvailable = surveyIdData?.isRevoteAvailable;

	const [postAnswers, { isLoading: isLoadingAnswers }] = survey_serviceAPI.usePostAnswersMutation();
	const [postOptions, { isLoading: isLoadingOptions }] = survey_serviceAPI.usePostOptionsMutation();
	const [deleteAnswersByAnswerid, { isLoading: isLoadingDelete }] = survey_serviceAPI.useDeleteAnswersByAnsweridMutation();

	const isAnonymous = !!surveyIdData?.isAnonymous;
	const isLoading = isLoadingData || isLoadingAnswers || isLoadingOptions || isLoadingDelete;
	const isEndedSurvey = getIsPastDate(surveyIdData?.deadline);

	const fetchQuery = () => {
		!isNewSurvey &&
			getSurvey({
				surveyid: surveyId as string,
				includeCategories: true,
				includeuseranswers: true,
				// includeuserinfo: true,
				includeImages: true,
				userid: userId,
			});
	};

	// * Initial
	useEffect(() => {
		fetchQuery();
	}, [userId]);

	useEffect(() => {
		if (surveyIdData && !surveyIdData?.isActive) {
			setRemoteSurveyName(surveyIdData.subject);
			return navigate('/surveys');
		}
	}, [surveyIdData]);

	// * Is completed survey
	const userAnswers: string[] = [];
	surveyIdData?.groups?.filter(group => {
		group.questions?.filter(question => {
			const { options, gridOptions, scaleOptions } = question;

			const opts = [];
			if (options && options.length > 0) opts.push(...options);
			if (gridOptions && gridOptions.length > 0) opts.push(...gridOptions);
			if (scaleOptions && scaleOptions.length > 0) opts.push(...scaleOptions);

			opts.forEach(opt => {
				if (!opt.usersAnswers) return;

				opt.usersAnswers.forEach(answer => userAnswers.push(answer.id));
			});
		});
	});

	const isCompleted = userAnswers.length > 0;

	// * Modal
	const [isShowRevoteModal, setIsShowRevoteModal] = useState(false);
	const toggleRevoteModal = () => setIsShowRevoteModal(prevState => !prevState);

	const subtitle = `Вы действительно хотите отменить отправку\n вашего ответа к опросу\n "${surveyIdData?.subject}"?`;

	// * Remove answers
	const removeAnswers = () => {
		const promises: any[] = [];

		userAnswers.forEach(answerid => {
			promises.push(
				deleteAnswersByAnswerid({
					answerid,
				}).unwrap(),
			);
		});

		Promise.allSettled(promises)
			.then(() => {
				setIsSend(false);
				setIsShowRevoteModal(false);
				setTimeout(() => fetchQuery(), 400); // !HACK: После удаление запросов надо дать немного времени перед отправкой запроса на все данные формы. Если отправить запрос сразу, то данные на сервере не успевают обновиться и приходят ответы которые должны быть удаленны.
			})
			.catch(err => console.log('Error - delete answers: ', err));
	};

	// * Error
	const [errorQuestions, setErrorQuestions] = useState<string[]>([]);

	const getErrorQuestions = (groups: SectionProps[]): string[] => {
		const withoutAnswers: string[] = [];

		groups.forEach(group => {
			group.questions.forEach(question => {
				const { id, options, gridOptions, scaleOptions, isObligatory, hasMultipleChoice } = question;

				if (!isObligatory) return;

				// custom option without content
				const customAnswer = options.filter(opt => opt.usersAnswers && opt.usersAnswers.length > 0 && opt.isCustom && !opt.content);

				if (customAnswer.length > 0) {
					return withoutAnswers.push(id);
				}

				// other options & scale options
				if (gridOptions.length < 1) {
					const preparedOptions = [];
					options.length > 0 && preparedOptions.push(...options);
					scaleOptions.length > 0 && preparedOptions.push(...scaleOptions);

					const answers = getAnswers(preparedOptions);

					if (answers.length > 0) return;

					return withoutAnswers.push(id);
				}

				// gris options
				const rowsCount = getGridRows(gridOptions).length;

				if (!hasMultipleChoice) {
					const answers = getAnswers(gridOptions);

					if (answers.length !== rowsCount) return withoutAnswers.push(id);

					return;
				}

				const withAnswers = gridOptions.filter(opt => getIsChecked(opt));
				const rowsWithAnswers = getGridRows(withAnswers);
				const answers = getAnswers(rowsWithAnswers);

				answers.length !== rowsCount && withoutAnswers.push(id);
			});
		});

		const filteredAnswers = new Set(withoutAnswers);

		return Array.from(filteredAnswers);
	};

	// * Scroll
	const handleClickScroll = (id: string) => {
		const element = document.getElementById(id);

		if (element) {
			element.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
	};

	// * Submit
	const onSubmit = (groups: SectionProps[]): void => {
		// error
		const errors = getErrorQuestions(groups);
		setErrorQuestions([...errors]);

		if (errors.length > 0) {
			handleClickScroll(errors[0]);
			return;
		}

		// На каждый вопрос отправлять отдельный запрос с ответами
		groups.forEach(group => {
			group.questions.forEach(question => {
				const { options, gridOptions, scaleOptions } = question;

				const answers: string[] = [];
				const customAnswers: CustomAnswers[] = [];

				options.forEach(opt => {
					if (!opt.usersAnswers || opt.usersAnswers.length < 1) return;

					if (opt.isCustom) {
						opt.usersAnswers.forEach(_answ =>
							customAnswers.push({
								questionId: question.id,
								options: [
									{
										content: opt.content,
										isCustom: true,
									},
								],
							}),
						);
					}

					!opt.isCustom && opt.usersAnswers.forEach(answ => answers.push(answ.id));
				});

				gridOptions.forEach(opt => {
					if (!opt.usersAnswers || opt.usersAnswers.length < 1) return;

					opt.usersAnswers.forEach(answ => answers.push(answ.id));
				});

				scaleOptions.forEach(opt => {
					if (!opt.usersAnswers || opt.usersAnswers.length < 1) return;

					opt.usersAnswers.forEach(answ => answers.push(answ.id));
				});

				// Кастомный ответ
				customAnswers.length > 0 &&
					customAnswers.forEach(answer => {
						postOptions({
							createOptionCommand: {
								questionId: answer.questionId,
								content: answer.options[0].content,
								isCustom: answer.options[0].isCustom,
							},
						})
							.unwrap()
							.then(() => setIsSend(true))
							.catch(err => console.log('Error - postOptions: ', err));
					});

				// Все остальные ответы
				answers.length > 0 &&
					surveyId &&
					postAnswers({
						createAnswerCommand: {
							optionIds: answers,
							surveyId: surveyId,
						},
					})
						.unwrap()
						.then(() => setIsSend(true))
						.catch(err => console.log('Error - postAnswers: ', err));
			});
		});
	};

	// * Render
	return (
		<PageDecorator>
			{isShowRevoteModal && (
				<Modal
					toggleModal={toggleRevoteModal}
					show={isShowRevoteModal}
					zIndex={3}
					width={476}
					Content={
						<ModalContent
							title="Отменить отправку"
							subtitle={subtitle}
							buttonText="Отменить"
							closeModalText="Назад"
							action={removeAnswers}
							onClose={toggleRevoteModal}
						/>
					}
				/>
			)}

			<PageTitle
				className={s.header}
				title="Опросы"
				isBeta
				buttons={[
					<React.Fragment key="Перейти к результатам">
						{!isSend && isAdmin && isDesktop && (
							<Button
								onClick={e => {
									e.preventDefault();
									e.stopPropagation();
									navigate('results');
								}}
							>
								Перейти к результатам
							</Button>
						)}
					</React.Fragment>,
					<React.Fragment key="Отменить отправку">
						{isRevoteAvailable && isCompleted && !isSend && !isEndedSurvey && (
							<Button
								variant="secondary"
								onClick={e => {
									e.preventDefault();
									e.stopPropagation();
									toggleRevoteModal();
								}}
							>
								Отменить отправку
							</Button>
						)}
					</React.Fragment>,
				]}
			/>

			{isLoadingData && (
				<div className={s.loading}>
					<LoaderCircle />
				</div>
			)}

			{!isSend && !isLoadingData && (
				<FillingOutForm
					id={surveyIdData?.id}
					surveyInfo={{
						title: surveyIdData?.subject || '',
						description: surveyIdData?.description || '',
						isAnonymous: !!surveyIdData?.isAnonymous,
					}}
					category={surveyIdData?.surveyCategories ? surveyIdData.surveyCategories[0] : null}
					deadline={surveyIdData?.deadline}
					groups={surveyIdData?.groups || []}
					notClickable={isCompleted}
					errorQuestions={errorQuestions}
					onSubmit={onSubmit}
					isLoading={isLoading}
				/>
			)}

			{isSend && !isLoading && (
				<SuccessSurvey
					isAnonymous={isAnonymous}
					isRevoteAvailable={surveyIdData?.isRevoteAvailable}
					removeAnswers={toggleRevoteModal}
				/>
			)}
		</PageDecorator>
	);
};
