import React from 'react';
import s from './SuccessSurvey.module.scss';
import { Button } from 'src/shared/ui/_buttons/Button/Button';
import { useNavigate } from 'react-router-dom';
import { useRights } from 'src/shared/hooks/useRights';
import { useWindowDimensions } from 'src/shared/hooks/useWindowDimensions';

interface Props {
	isAnonymous?: boolean;
	isRevoteAvailable?: boolean;
	removeAnswers?: () => void;
}

export const SuccessSurvey: React.FC<Props> = props => {
	const { isAnonymous, isRevoteAvailable, removeAnswers } = props;

	// * Rights
	const isAdmin = useRights();

	// * Window
	const windowDimensions = useWindowDimensions();
	const isDesktop = windowDimensions.width >= 1024;

	// * Navigate
	const navigate = useNavigate();

	const toAllSurveys = () => {
		navigate('/surveys');
	};

	// * Render
	return (
		<div className={s.container}>
			<h3>Ответ отправлен</h3>

			<div className={s.subtitle}>Спасибо за прохождение опроса!</div>

			<div className={s.buttons}>
				<Button onClick={toAllSurveys}>Перейти к опросам</Button>

				{isAdmin && !isAnonymous && isDesktop && (
					<Button
						variant="tertiary"
						onClick={e => {
							e.preventDefault();
							e.stopPropagation();
							navigate('results');
						}}
					>
						Перейти к результатам
					</Button>
				)}

				{isRevoteAvailable && removeAnswers && (
					<Button
						variant="tertiary"
						onClick={e => {
							e.preventDefault();
							e.stopPropagation();
							removeAnswers();
						}}
					>
						Отменить отправку
					</Button>
				)}
			</div>
		</div>
	);
};
