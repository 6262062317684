import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import cn from 'classnames';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Checkbox } from 'src/shared/ui/_inputs/Checkbox/Checkbox';
import { COLUMNS_USERS, UsersTableDataItem } from 'src/pages/project_service_pages/ui/ProjectDetailPage/ui/UserAddList/consts/COLUMNS_USERS';
import s from './UserAddList.module.scss';

interface Props {
	users: UsersTableDataItem[];
}

export const UserAddList: React.FC<Props> = props => {
	const { users } = props;

	const [data, setData] = useState(users);

	useEffect(() => {
		setData(users);
	}, [users]);

	// * Form
	const { control, setValue, watch } = useFormContext();

	const selectedUsers = watch('selectedUsers');

	const editSelectedUsers = (e: ChangeEvent<HTMLInputElement>, userId: string) => {
		const checked = e.target.checked;

		if (checked) {
			setValue('selectedUsers', [...selectedUsers, userId]);
		} else {
			setValue(
				'selectedUsers',
				selectedUsers.filter((id: string) => id !== userId),
			);
		}
	};

	// * Table
	const table = useReactTable({
		data,
		columns: COLUMNS_USERS,
		getCoreRowModel: getCoreRowModel(),
	});

	return (
		<div className={s.container}>
			<div className={s.table}>
				{table.getHeaderGroups().map(headerGroup => (
					<div
						className={cn(s.table_row, s.header_wrapper)}
						key={headerGroup.id}
					>
						<span />
						{headerGroup.headers.map(header => (
							<div
								className={s.table_header}
								key={header.id}
							>
								{header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
							</div>
						))}
					</div>
				))}

				{table.getRowModel().rows.map(row => (
					<div
						key={row.original.id}
						className={cn(s.table_row, selectedUsers.includes(row.original.id) ? s.selected_cell_card : s.cell_card)}
					>
						<Controller
							name="selectedUsers"
							control={control}
							render={({ field }) => (
								<Checkbox
									{...field}
									checked={field.value.includes(row.original.id)}
									onChange={e => editSelectedUsers(e, row.original.id)}
								/>
							)}
						/>

						{row.getVisibleCells().map(cell => (
							<div
								className={s.table_cell}
								key={cell.id}
							>
								{flexRender(cell.column.columnDef.cell, cell.getContext())}
							</div>
						))}
					</div>
				))}
			</div>
		</div>
	);
};
