import { FC, useState } from 'react';
import { ReactComponent as AddSVG } from 'src/shared/assets/svg/action/add.svg';
import { IconButton } from 'src/shared/ui/_buttons/IconButton/IconButton';
import { LeaveEnter } from 'src/pages/TimeTrackingPage/ui/LeaveEnter/LeaveEnter';
import { Heading } from 'src/shared/ui/Heading';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';

interface Props {
	userId: string;
	refetch: () => void;
}

export const AddLeaveButton: FC<Props> = props => {
	const { userId, refetch } = props;

	const [modal, setModal] = useState<boolean>(false);
	const toggleModal = () => setModal(prevProps => !prevProps);

	return (
		<>
			<ModalNewProto
				isOpen={modal}
				onClose={toggleModal}
			>
				<Heading
					level={2}
					marginBottom="l"
				>
					Внести часы
				</Heading>

				<LeaveEnter
					userId={userId}
					onSuccess={() => {
						refetch(), toggleModal();
					}}
				/>
			</ModalNewProto>

			<IconButton
				Icon={<AddSVG />}
				onClick={toggleModal}
			/>
		</>
	);
};
