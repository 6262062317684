import React from 'react';
import s from './NewCatergoryMC.module.scss';
import { RadioColor } from 'src/shared/ui/RadioColor/RadioColor';
import { Button } from 'src/shared/ui/_buttons/Button/Button';
import { StringField } from 'src/shared/ui/_fields/StringField';
import * as yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CategoryInfo } from 'src/app/redux/queries/survey-service/types';

export interface FormValues {
	id: string | null;
	name: string;
	color?: number;
}

interface Props {
	toggleModal: () => void;
	title: string;
	saveButtonTitle: string;
	onSave: (values: FormValues) => void;
	categories: CategoryInfo[];

	isLoading: boolean;

	category?: CategoryInfo;
}

export const NewCategoryMC: React.FC<Props> = props => {
	const { toggleModal, title, saveButtonTitle, onSave, categories, isLoading, category } = props;

	const colorNumbers = Array.from(Array(12).keys());

	// * Form
	const defaultValues: FormValues = {
		id: null,
		name: '',
		color: 0,
	};

	const schema = yup.object().shape({
		name: yup.string().required('Необходимо название категории').max(20, 'Название не может быть больше 20 символов').nullable(),
	});

	const formMethods = useForm({
		defaultValues: category ? category : defaultValues,
		resolver: yupResolver(schema),
	});

	const { handleSubmit, watch, setError, clearErrors, setValue } = formMethods;

	const selectedColor = watch('color');

	const setColor = (event: React.MouseEvent<HTMLInputElement>) => {
		const value = +event.currentTarget.value;
		setValue('color', value);
		// value === selectedColor ? setValue('color', null) : setValue('color', value);
	};

	const onSubmit = (values: FormValues) => {
		const restCategoriesNames = categories.filter(existingCategory => existingCategory.id !== category?.id).map(category => category.name.toLowerCase().trim());

		const categoryNameExists = restCategoriesNames.includes(values.name.toLowerCase().trim());

		if (categoryNameExists) {
			setError('name', { message: 'Категория уже существует' });
		} else {
			clearErrors();
			onSave({ ...values, name: values.name.trim() });
		}
	};

	// * Render
	return (
		<FormProvider {...formMethods}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className={s.container}>
					<h3>{title}</h3>

					<StringField
						name="name"
						label="Название"
						placeholder="Введите название категории"
					/>

					<div className={s.colors}>
						{colorNumbers.map(number => {
							return (
								<RadioColor
									key={number}
									value={number}
									onClick={setColor}
									checked={selectedColor === number}
								/>
							);
						})}
					</div>

					<div className={s.buttons}>
						<Button
							type="button"
							variant="tertiary"
							wide
							isLoading={isLoading}
							onClick={toggleModal}
						>
							Отменить
						</Button>

						<Button
							type="submit"
							wide
							isLoading={isLoading}
						>
							{saveButtonTitle}
						</Button>
					</div>
				</div>
			</form>
		</FormProvider>
	);
};
