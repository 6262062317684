import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'src/app/api/apiSlice';
import { ServiceName } from 'src/app/api/api_types';
import {
	DeleteUnsentEmailResendApiArg,
	GetAdminFindApiArg,
	GetFeedbackFindApiArg,
	GetFeedbackGetApiArg,
	GetFileGetApiArg,
	GetUnsentEmailFindApiArg,
	PatchGraphicalUserInterfaceEditApiArg,
	PatchModuleSettingEditApiArg,
	PostAdminInstallApiArg,
	PostAuthLoginApiArg,
	PostAuthRefreshApiArg,
	PostFeedbackCreateApiArg,
	PostFileCreateApiArg,
	PutAdminEditApiArg,
	PutModuleSettingCheckApiArg,
} from './types/reqTypes';
import {
	DeleteUnsentEmailResendApiResponse,
	GetAdminFindApiResponse,
	GetFeedbackFindApiResponse,
	GetFeedbackGetApiResponse,
	GetFileGetApiResponse,
	GetGraphicalUserInterfaceGetApiArg,
	GetGraphicalUserInterfaceGetApiResponse,
	GetUnsentEmailFindApiResponse,
	PatchGraphicalUserInterfaceEditApiResponse,
	PatchModuleSettingEditApiResponse,
	PostAdminInstallApiResponse,
	PostAuthLoginApiResponse,
	PostAuthRefreshApiResponse,
	PostFeedbackCreateApiResponse,
	PostFileCreateApiResponse,
	PutAdminEditApiResponse,
	PutModuleSettingCheckApiResponse,
} from './types/resTypes';

const reducerPath = `${ServiceName.GATEWAY_SERVICE}` as const;

export const gateway_serviceAPI = createApi({
	baseQuery: baseQueryWithReAuth,
	reducerPath,
	tagTypes: ['Admin', 'Auth', 'Feedback', 'File', 'GraphicalUserInterface', 'ModuleSetting', 'UnsentEmail'],
	endpoints: build => ({
		getAdminFind: build.query<GetAdminFindApiResponse, GetAdminFindApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Admin/find`,
				params: {
					skipcount: queryArg.skipcount,
					takecount: queryArg.takecount,
				},
			}),
			providesTags: ['Admin'],
		}),
		putAdminEdit: build.mutation<PutAdminEditApiResponse, PutAdminEditApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Admin/edit`,
				method: 'PUT',
				body: queryArg.body,
			}),
			invalidatesTags: ['Admin'],
		}),
		postAdminInstall: build.mutation<PostAdminInstallApiResponse, PostAdminInstallApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Admin/install`,
				method: 'POST',
				body: queryArg.installAppRequest,
			}),
			invalidatesTags: ['Admin'],
		}),
		postAuthLogin: build.mutation<PostAuthLoginApiResponse, PostAuthLoginApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Auth/login`,
				method: 'POST',
				body: queryArg.loginRequest,
			}),
			invalidatesTags: ['Auth'],
		}),
		postAuthRefresh: build.mutation<PostAuthRefreshApiResponse, PostAuthRefreshApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Auth/refresh`,
				method: 'POST',
				body: queryArg.refreshRequest,
			}),
			invalidatesTags: ['Auth'],
		}),
		postFeedbackCreate: build.mutation<PostFeedbackCreateApiResponse, PostFeedbackCreateApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Feedback/create`,
				method: 'POST',
				body: queryArg.createFeedbackRequest,
			}),
			invalidatesTags: ['Feedback'],
		}),
		getFeedbackFind: build.query<GetFeedbackFindApiResponse, GetFeedbackFindApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Feedback/find`,
				params: {
					ownmessagesonly: queryArg.ownmessagesonly,
					feedbackstatus: queryArg.feedbackstatus,
					feedbacktype: queryArg.feedbacktype,
					orderbydescending: queryArg.orderbydescending,
					skipcount: queryArg.skipcount,
					takecount: queryArg.takecount,
				},
			}),
			providesTags: ['Feedback'],
		}),
		getFeedbackGet: build.query<GetFeedbackGetApiResponse, GetFeedbackGetApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Feedback/get`,
				params: { feedbackId: queryArg.feedbackId },
			}),
			providesTags: ['Feedback'],
		}),
		postFileCreate: build.mutation<PostFileCreateApiResponse, PostFileCreateApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/File/create`,
				method: 'POST',
				body: queryArg.body,
				params: { feedbackId: queryArg.feedbackId },
			}),
			invalidatesTags: ['File'],
		}),
		getFileGet: build.query<GetFileGetApiResponse, GetFileGetApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/File/get`,
				params: { fileId: queryArg.fileId },
			}),
			providesTags: ['File'],
		}),
		getGraphicalUserInterfaceGet: build.query<GetGraphicalUserInterfaceGetApiResponse, GetGraphicalUserInterfaceGetApiArg>({
			query: () => ({ url: `${reducerPath}/GraphicalUserInterface/get` }),
			providesTags: ['GraphicalUserInterface'],
		}),
		patchGraphicalUserInterfaceEdit: build.mutation<PatchGraphicalUserInterfaceEditApiResponse, PatchGraphicalUserInterfaceEditApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/GraphicalUserInterface/edit`,
				method: 'PATCH',
				body: queryArg.body,
			}),
			invalidatesTags: ['GraphicalUserInterface'],
		}),
		patchModuleSettingEdit: build.mutation<PatchModuleSettingEditApiResponse, PatchModuleSettingEditApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/ModuleSetting/edit`,
				method: 'PATCH',
				body: queryArg.body,
				params: { moduleSettingId: queryArg.moduleSettingId },
			}),
			invalidatesTags: ['ModuleSetting'],
		}),
		putModuleSettingCheck: build.mutation<PutModuleSettingCheckApiResponse, PutModuleSettingCheckApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/ModuleSetting/check`,
				method: 'PUT',
				body: queryArg.checkSmtpRequest,
			}),
			invalidatesTags: ['ModuleSetting'],
		}),
		deleteUnsentEmailResend: build.mutation<DeleteUnsentEmailResendApiResponse, DeleteUnsentEmailResendApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/UnsentEmail/resend`,
				method: 'DELETE',
				params: { unsentEmailId: queryArg.unsentEmailId },
			}),
			invalidatesTags: ['UnsentEmail'],
		}),
		getUnsentEmailFind: build.query<GetUnsentEmailFindApiResponse, GetUnsentEmailFindApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/UnsentEmail/find`,
				params: {
					skipcount: queryArg.skipcount,
					takecount: queryArg.takecount,
				},
			}),
			providesTags: ['UnsentEmail'],
		}),
	}),
});

export const {
	useGetAdminFindQuery,
	usePutAdminEditMutation,
	usePostAdminInstallMutation,
	usePostAuthLoginMutation,
	usePostAuthRefreshMutation,
	usePostFeedbackCreateMutation,
	useGetFeedbackFindQuery,
	useGetFeedbackGetQuery,
	usePostFileCreateMutation,
	useGetFileGetQuery,
	useGetGraphicalUserInterfaceGetQuery,
	usePatchGraphicalUserInterfaceEditMutation,
	usePatchModuleSettingEditMutation,
	usePutModuleSettingCheckMutation,
	useDeleteUnsentEmailResendMutation,
	useGetUnsentEmailFindQuery,
} = gateway_serviceAPI;
