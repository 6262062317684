import { AsyncThunkAction } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { galleyFileToBase64 } from 'src/shared/lib/file/galleyFileToBase64/galleyFileToBase64';
import { ImageInfo } from 'src/app/redux/state/image/image/types';
import { ImageModal } from '../../_modals/ImageModal/ImageModal';
import s from './Gallery.module.scss';
import { GalleyFile } from './_types';
import { createGalleyFileFromImageInfo, createImageInfoFromFile } from './_utils';
import { GalleryItem } from './components/GalleryItem';
import { ImageDrop } from './components/ImageDropDown/ImageDrop';

interface Props {
	images: ImageInfo[];
	allowedToEdit: boolean;
	saveAllImages?: (images: ImageInfo[]) => void; // Задает все изображения.
	createImages?: (images: ImageInfo[]) => void; // Создает изображения.
	deleteImages?: (imagesIds: string[]) => void; // Удаляет изображения.
	fetchImage?: (imageId: string) => AsyncThunkAction<any, any, any>; // ! Загружает изображения для просмотра с сервера. Если не задан, берет изображение из состояния files. Не задавать если нет изображения из сервера!
	maxFiles?: number;
	isLoading?: boolean;
}

export const Gallery: React.FC<Props> = props => {
	const { images, allowedToEdit, saveAllImages, createImages, deleteImages, fetchImage, maxFiles = 8, isLoading } = props;

	const [files, setFiles] = useState<GalleyFile[]>(images.map(image => createGalleyFileFromImageInfo(image)));
	const [imageToShow, setImageToShow] = useState<number | null>(null);

	useEffect(() => {
		setFiles(images.map(image => createGalleyFileFromImageInfo(image)));

		return () => setFiles([]);
	}, [images.length]);

	useEffect(() => {
		if (saveAllImages) {
			Promise.all(files.map(file => galleyFileToBase64(file)))
				// TODO: Type values
				.then((values: any) => {
					const images = createImageInfoFromFile(values);
					saveAllImages(images);
				});
		}
	}, [files.length]);

	useEffect(() => {
		// Make sure to revoke the data uris to avoid memory leaks, will run on unmount
		return () => files.forEach(file => URL.revokeObjectURL(file.preview));
	}, []);

	return (
		<>
			{imageToShow !== null && (
				<ImageModal
					toggleModal={() => setImageToShow(null)}
					files={files}
					imageToShow={imageToShow}
					setImageToShow={setImageToShow}
					fetchImage={fetchImage}
					zIndex={5}
				/>
			)}

			<div className={s.container}>
				<aside className={s.thumbs}>
					{files[0] &&
						files.map((file, index) => (
							<GalleryItem
								key={file.id}
								file={file}
								index={index}
								setImageToShow={setImageToShow}
								files={files}
								setFiles={setFiles}
								deleteImages={deleteImages}
								allowedToEdit={allowedToEdit}
							/>
						))}

					{allowedToEdit && files.length < maxFiles && (
						<ImageDrop
							currentFilesCount={files.length}
							setFiles={newFiles => setFiles([...files, ...newFiles])}
							createImages={createImages}
							maxFiles={maxFiles}
							isLoading={isLoading}
						/>
					)}
				</aside>
			</div>
		</>
	);
};
