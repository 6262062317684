import React, { useState } from 'react';
import s from '../Gallery.module.scss';
import { ReactComponent as DeleteSVG } from 'src/shared/assets/svg/action/delete.svg';
import { IconSquareButton } from '../../../_buttons/IconSquareButton/IconSquareButton';
import { GalleyFile } from '../_types';

interface Props {
	file: GalleyFile;
	files: GalleyFile[];
	setFiles: React.Dispatch<React.SetStateAction<GalleyFile[]>>;
	index: number;
	setImageToShow: React.Dispatch<React.SetStateAction<number | null>>;
	deleteImages?: (imagesIds: string[]) => void;
	allowedToEdit: boolean;
}

export const GalleryItem: React.FC<Props> = props => {
	const {
		file, //
		index,
		setImageToShow,
		files,
		setFiles,
		deleteImages,
		allowedToEdit,
	} = props;

	const [showDeleteIcon, setShowDeleteIcon] = useState(false);

	const removeFile = (file: GalleyFile) => {
		const newFiles = [...files];
		newFiles.splice(newFiles.indexOf(file), 1);
		setFiles(newFiles);

		deleteImages && deleteImages([file.id]);
	};

	// * Render
	return (
		<div
			className={s.thumb}
			onMouseEnter={() => allowedToEdit && setShowDeleteIcon(true)}
			onMouseLeave={() => setShowDeleteIcon(false)}
		>
			<img
				src={file.preview}
				alt={file.name}
				onClick={() => setImageToShow(index)}
			/>

			{showDeleteIcon && (
				<IconSquareButton
					size="small"
					Icon={<DeleteSVG />}
					onClick={() => removeFile(file)}
					className={s.delete}
				/>
			)}
		</div>
	);
};
