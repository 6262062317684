import React, { ChangeEvent } from 'react';
import { getGridColumns } from 'src/pages/surveys/libs/getGridColumns/getGridColumns';
import { getGridRows } from 'src/pages/surveys/libs/getGridRows/getGridRows';
import { getIsChecked } from 'src/pages/surveys/libs/getIsChecked/getIsChecked';
import { Checkbox } from 'src/shared/ui/_inputs/Checkbox/Checkbox';
import { Radio } from 'src/shared/ui/_inputs/Radio/Radio';
import { Answers, GridOption, SectionProps } from '../../../../NewSurveyPage/_types';
import s from './GridAnswer.module.scss';

interface Props {
	type: 'radio' | 'check';
	options: GridOption[];

	indexGroup: number;
	indexQuestion: number;

	fillingInfo: Array<SectionProps>;
	changeFillingInfo: (info: Array<SectionProps>) => void;

	isEndedSurvey?: boolean;
	isCompleted?: boolean;
}

export const GridAnswer: React.FC<Props> = props => {
	const {
		type,
		options,

		indexGroup,
		indexQuestion,

		fillingInfo,
		changeFillingInfo,

		isEndedSurvey,
		isCompleted,
	} = props;

	const columns = getGridColumns(options);
	const rows = getGridRows(options);

	const toSelectCell = (event: ChangeEvent<HTMLInputElement>, rowSign: string, columnSign: string) => {
		const isChecked = event.target.checked;

		const changedGroups = fillingInfo.map((group, i) => {
			if (i !== indexGroup) return group;

			return {
				...group,
				isActive: true,
				questions: group.questions.map((question, ind) => {
					if (ind !== indexQuestion) return question;

					return {
						...question,
						gridOptions: options.map(option => {
							// type === radio
							if (type === 'radio') {
								const newAnswer: Answers[] = [];

								if (rowSign !== option.rowSignature && option.usersAnswers && option.usersAnswers.length > 0) {
									newAnswer.push(option.usersAnswers[0]);
								}

								if (rowSign === option.rowSignature && columnSign === option.columnSignature) {
									newAnswer.push({ id: option.id });
								}

								return {
									...option,
									usersAnswers: newAnswer,
								};
							}

							// type === check
							if (rowSign !== option.rowSignature || columnSign !== option.columnSignature) return option;

							return {
								...option,
								usersAnswers: isChecked ? [{ id: option.id }] : [],
							};
						}),
					};
				}),
			};
		});

		changeFillingInfo([...changedGroups]);
	};

	// * Render
	return (
		<div className={s.container}>
			<div className={s.rows_wrapper}>
				{rows.map(row => (
					<div
						key={row.id}
						className={s.row_content}
					>
						{row.rowSignature}
					</div>
				))}
			</div>

			<div className={s.columns_wrapper}>
				{columns.map(column => (
					<div
						key={column.id}
						className={s.column_content}
					>
						{column.columnSignature}

						{rows.map(row => {
							const filtered = options.filter(opt => opt.columnSignature === column.columnSignature && opt.rowSignature === row.rowSignature);
							const cell = filtered[0];

							return (
								<div
									key={row.id}
									className={s.cell}
								>
									{type === 'radio' && (
										<Radio
											checked={getIsChecked(cell)}
											onChange={e => toSelectCell(e, row.rowSignature, column.columnSignature)}
											disabled={isCompleted}
											noDisabledStyles={!isEndedSurvey}
										/>
									)}
									{type === 'check' && (
										<Checkbox
											checked={getIsChecked(cell)}
											onChange={e => toSelectCell(e, row.rowSignature, column.columnSignature)}
											disabled={isCompleted}
											noDisabledStyles={!isEndedSurvey}
										/>
									)}
								</div>
							);
						})}
					</div>
				))}
			</div>
		</div>
	);
};
