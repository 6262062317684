import React, { useState } from 'react';
import s from './DepartmentCardDetail.module.scss';
import { CompanyUsersList } from '../CompanyUsersList/CompanyUsersList';
import { AddButton } from 'src/shared/ui/_buttons/AddButton/AddButton';
import { AddUserModal } from '../AddUserModal/AddUserModal';
import { Modal } from 'src/shared/ui/_modals/Modal/ui/Modal/Modal';
import { useRights } from 'src/shared/hooks/useRights';
import { DropDownMenu } from 'src/shared/ui/DropDownMenu/DropDownMenu';
import { ReactComponent as EditSVG } from 'src/shared/assets/svg_icons/action/edit.svg';
import { ReactComponent as DeleteSVG } from 'src/shared/assets/svg_icons/action/delete.svg';
import { CreateNewUnit } from '../../../Content/UnitsContent/CreateNewUnit/CreateNewUnit';
import { company_structure_serviceAPI } from 'src/app/redux/queries/company-structure-service/company_structure_serviceAPI';
import { RemoveModalContent } from '../../../../components/RemoveModalContent/RemoveModalContent';
import { position_serviceAPI } from 'src/app/redux/queries/position-service/position_serviceAPI';
import { FiltersWithSearch, SelectValue } from '../../../../components/FiltersWithSearch/FiltersWithSearch';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';
import { matchItems } from 'src/shared/lib/filter';

interface Props {
	departmentId: string;
}

export const DepartmentCardDetail: React.FC<Props> = props => {
	const { departmentId } = props;

	const isAdmin = useRights();

	// * Queries
	const { currentData: usersData } = company_structure_serviceAPI.useGetUsersQuery({
		skipcount: 0,
		takecount: 1000,
		departmentId,
		isAscendingSort: true,
	});

	const { currentData: departmentDetail } = company_structure_serviceAPI.useGetDepartmentQuery({
		departmentId: departmentId,
	});

	const { currentData: customPositions } = company_structure_serviceAPI.useGetPositionsQuery({
		params: {
			skipcount: 0,
			takecount: 1000,
		},
	});

	const { currentData: positionsData } = position_serviceAPI.useFindPositionsQuery({
		skipcount: 0,
		takecount: 1000,
		includeDeactivated: false,
	});
	const allUserPositions = positionsData?.body || [];
	const allCustomPositions = customPositions?.body || [];
	const allPositions = [...allUserPositions, ...allCustomPositions];

	// * All users
	const allUsers =
		usersData?.body.map(user => {
			const { fullName } = user;
			return {
				...user,
				name: fullName || '',
			};
		}) || [];

	// * Filter
	const [position, setPosition] = useState<SelectValue>({ id: null, name: null });
	const [userName, setUserName] = useState('');
	const [valuePosition, setValuePosition] = useState('');

	const usersFilteredByPosition = allUsers.filter(item => {
		if (position.name) {
			return item.position === position.name;
		}
		return item;
	});

	const usersFilteredByName = matchItems(usersFilteredByPosition, userName);

	// * Modal
	const [showModal, setShowModal] = useState(false);
	const [showEditUnitModal, setShowEditUnitModal] = useState(false);
	const [showRemoveModal, setShowRemoveModal] = useState(false);

	const toggleModal = () => setShowModal(prevState => !prevState);
	const toggleEditUnitModal = () => setShowEditUnitModal(prevState => !prevState);
	const toggleRemoveModal = () => setShowRemoveModal(prevState => !prevState);

	// * Menu
	const DropDownItems = [
		{
			icon: <EditSVG />,
			title: 'Редактировать',
			action: () => toggleEditUnitModal(),
		},
		{
			icon: <DeleteSVG />,
			title: 'Удалить',
			action: () => toggleRemoveModal(),
		},
	];

	return (
		<div className={s.container}>
			{departmentDetail && (
				<div className={s.description}>
					<div className={s.header}>
						<h2>{departmentDetail.department.name}</h2>

						{isAdmin && departmentDetail.department.isCustom && (
							<div className={s.menu}>
								<DropDownMenu items={DropDownItems} />
							</div>
						)}
					</div>
				</div>
			)}

			{allPositions.length > 0 && (
				<div className={s.filters}>
					<FiltersWithSearch
						selectPlaceholder="Должность"
						selectOptions={allPositions}
						selectedOption={position}
						onSelectChange={(value: SelectValue) => setPosition(value)}
						searchPlaceholder="Поиск сотрудника по ФИО"
						searchValueOption={valuePosition}
						onChangeSearchOption={setValuePosition}
						searchValue={userName}
						onSearchChange={setUserName}
						isNullable
					/>
				</div>
			)}

			{isAdmin && departmentDetail?.department.isCustom && (
				<div className={s.add_users}>
					<AddButton
						title="Добавить сотрудника"
						onClick={toggleModal}
					/>
				</div>
			)}

			{showModal && (
				<ModalNewProto
					isOpen={showModal}
					onClose={toggleModal}
				>
					<AddUserModal
						departmentId={departmentId}
						toggleModal={toggleModal}
					/>
				</ModalNewProto>
				// <Modal
				// 	show={showModal}
				// 	toggleModal={toggleModal}
				// 	width={800}
				// 	// overflowX='visible'
				// 	Content={
				// 		<AddUserModal
				// 			departmentId={departmentId}
				// 			toggleModal={toggleModal}
				// 		/>
				// 	}
				// />
			)}

			<div className={s.company_users}>
				<CompanyUsersList
					isCustomUnit={departmentDetail?.department.isCustom}
					edit={isAdmin}
					users={usersFilteredByName}
				/>
			</div>

			{showEditUnitModal && (
				<ModalNewProto
					isOpen={showEditUnitModal}
					onClose={toggleEditUnitModal}
				>
					<CreateNewUnit
						values={{
							unitId: departmentId,
							name: departmentDetail?.department.name || '',
							description: departmentDetail?.department.description || '',
						}}
						toggleModal={toggleEditUnitModal}
					/>
				</ModalNewProto>
			)}

			<Modal
				show={showRemoveModal}
				width={476}
				top={200}
				toggleModal={toggleRemoveModal}
				overflowX="visible"
				Content={
					<RemoveModalContent
						unit
						id={departmentId}
						name={departmentDetail?.department.name || ''}
						toggleRemoveModal={toggleRemoveModal}
					/>
				}
			/>
		</div>
	);
};
