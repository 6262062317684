import { UseFormSetError } from 'react-hook-form';
import { rights_serviceAPI } from 'src/app/redux/queries/rights-service/rights_serviceAPI';
import { PatchRoleLocalizationDocument } from 'src/app/redux/queries/rights-service/types/types';

export const useEditRole = (
	roleId: string,
	roleLocalizationId: string,
	defaultValues: {
		name: string;
		description: string;
		rights: string[];
	},
	onCloseModal: () => void,
	setError: UseFormSetError<{ name: string; description: string; rights: string[] }>,
) => {
	const promises: any[] = [];

	const [editRoleRights, { isLoading: isLoading1 }] = rights_serviceAPI.useEditRoleRightsMutation();
	const [editRoleLocalization, { isLoading: isLoading2 }] = rights_serviceAPI.useEditRoleLocalizationMutation();

	const isEditLoading = isLoading1 || isLoading2;

	const onEditSubmit = (data: { name: string; description: string; rights: string[] }) => {
		const { name, description, rights } = data;

		const preparedArgs: PatchRoleLocalizationDocument[] = [];

		if (defaultValues.name !== name) {
			preparedArgs.push({
				op: 'replace',
				path: '/Name',
				value: name,
			});
		}

		if (defaultValues.description !== description) {
			preparedArgs.push({
				op: 'replace',
				path: '/Description',
				value: description,
			});
		}

		preparedArgs.length > 0 &&
			promises.push(
				editRoleLocalization({
					roleLocalizationId,
					editRoleLocalizationRequest: preparedArgs,
				}).unwrap(),
			);

		if (rights.length !== defaultValues.rights.length || (rights.length === defaultValues.rights.length && !rights.every(right => defaultValues.rights.includes(right)))) {
			promises.push(
				editRoleRights({
					updateRoleRightsRequest: {
						roleId,
						rights: rights.map(right => Number(right)),
					},
				}).unwrap(),
			);
		}

		Promise.allSettled(promises)
			.then(values => {
				const errors = values.filter(value => value.status !== 'fulfilled');
				const hasError = errors.length > 0;

				if (!hasError) return onCloseModal();

				errors.forEach((item: any) => {
					const message = item?.reason?.data?.errors[0] || '';
					if (message.includes('Name')) {
						setError('name', { message: 'Роль с таким названием уже существует.'});
					}

					if (message.includes('rights')) {
						setError('rights', { message: 'Роль с таким набором прав уже существует.'});
					}
				});
			})
			.catch(err => console.log('Error: ', err));
	};

	return { onEditSubmit, isEditLoading };
};
