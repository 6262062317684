import React, { useState } from 'react';
import s from './PercentageUsers.module.scss';
import { Button } from 'src/shared/ui/_buttons/Button/Button';
import { Modal } from 'src/shared/ui/_modals/Modal/ui/Modal/Modal';
import { Users } from '../Users/Users';

interface Props {
	total: number;
	count: number;
	title: string;
	surveyId: string;
	departmentId: string;
	isAnonymous: boolean;
}

export const PercentageUsers: React.FC<Props> = props => {
	const {
		total, // Всего сотрудников
		count, // Прошли опрос
		title,
		surveyId,
		departmentId,
		isAnonymous,
	} = props;

	const percent = total ? `${((count * 100) / total).toFixed()}%` : '0%';

	// * Modal
	const [showModal, setShowModal] = useState(false);

	const toggleModal = () => setShowModal(prevState => !prevState);

	return (
		<div className={s.container}>
			{showModal && (
				<Modal
					show={showModal}
					toggleModal={toggleModal}
					width={800}
					Content={
						<Users
							count={count}
							department={title}
							surveyId={surveyId}
							departmentId={departmentId}
						/>
					}
				/>
			)}

			<div className={s.header}>
				<h4>{title}</h4>

				{!isAnonymous && (
					<Button
						variant="tertiary"
						onClick={toggleModal}
					>
						Участники опроса
					</Button>
				)}
			</div>

			<div className={s.wrapper}>
				<div className={s.label}>
					{count} из {total}
				</div>

				<div className={s.percent_line}>
					<div
						style={{ width: percent }}
						className={s.filled}
					/>
				</div>

				<div className={s.percent}>{percent}</div>
			</div>
		</div>
	);
};
