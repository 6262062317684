import React from 'react';
import { ImageContent } from 'src/app/redux/queries/survey-service/types';
import s from './Image.module.scss';
import { base64InfoToString, isValidBase64DataString } from 'src/shared/lib/file';

interface Props extends ImageContent {
	onClick: () => void;
}

export const Image: React.FC<Props> = props => {
	const {
		content, //
		extension,
		name,
		onClick,
	} = props;

	return (
		<img
			className={s.container}
			onClick={onClick}
			src={isValidBase64DataString(content) ? content : base64InfoToString(extension, content)}
			alt={name ?? ''}
		/>
	);
};
