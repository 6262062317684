import React, { useState } from 'react';
import { useGetDepartmentsTreeGetQuery } from 'src/app/redux/queries/department-service/department_serviceAPI';
import { useGetUserFindQuery } from 'src/app/redux/queries/user-service/user_serviceAPI';
import { actionsEventCalendar } from 'src/app/redux/state/event-calendar/slice';
import { officeServiceActions } from 'src/app/redux/state/office/actions';
import { useAppDispatch, useAppSelector } from 'src/app/redux/utils';
import { ButtonPair } from 'src/entities/_buttons/ButtonPair';
import { SelectStringSearchFilter, SelectValue } from 'src/entities/_filters/SelectStringSearchFilter';
import { matchItems } from 'src/shared/lib/filter';
import { LoaderCircle } from 'src/shared/ui/_loaders/LoaderCircle/LoaderCircle';
import { UserList } from '../UsersList/UsersList';
import { AddUserList } from './AddUserList/AddUserList';
import s from './AddUserMC.module.scss';

interface Props {
	toggleModal: () => void;
	addToEvent?: boolean;
}

export const AddUserMC: React.FC<Props> = props => {
	const {
		toggleModal, //
		addToEvent,
	} = props;

	// * Selectors
	// const departments = useAppSelector(state => state.department_service.departmentTree.departments);
	const companyUsers = useAppSelector(state => state.user_service.user.companyUsers);
	const officeId = useAppSelector(state => state.office_service.office.office?.id);
	const fullEventUsers = useAppSelector(state => state.event_calendar.event.selectedUsersInfo.users);

	// * Add users
	const eventUsers = useAppSelector(state => state.event_calendar.event.event?.users);
	const selectedUsersFromEvent = eventUsers && [...eventUsers.map(user => user.userId)];
	const [selectedUsersFull, setSelectedUsersFull] = useState<UserList[]>(addToEvent ? (fullEventUsers as UserList[]) : []);
	const [selectedUsers, setSelectedUsers] = useState<string[]>(addToEvent ? selectedUsersFromEvent || [] : []);

	// * Actions
	const dispatch = useAppDispatch();
	const { setEventInfo, setSelectedUsersInfo } = actionsEventCalendar;
	const { getOffice } = officeServiceActions.office.async;
	const { createOfficeUsers } = officeServiceActions.user.async;

	// * API
	const { data: usersListData, isLoading: isLoadingUsersList } = useGetUserFindQuery({
		skipcount: 0,
		takecount: 2000,
		includedepartments: true,
		isactive: true,
		includecurrentavatar: true,
	});

	const usersList = usersListData?.body ?? [];

	const { data: departmentTreeData, isLoading: isLoadingDepartmentTree } = useGetDepartmentsTreeGetQuery({});
	const departmentTree = departmentTreeData?.body ?? [];

	const isLoading = isLoadingUsersList || isLoadingDepartmentTree;

	// * Company users id
	const companyUsersId =
		companyUsers?.map(companyUser => {
			const { body } = companyUser;
			const { user } = body;
			return user.id;
		}) || [];

	// * All users
	const allUsers =
		usersList.map(user => {
			return {
				id: user.user.id,
				name: `${user.user.lastName} ${user.user.firstName}`,
				department: user.departmentUser ? user.departmentUser.department.name : '',
				avatar: user.user?.avatar,
			};
		}) || [];

	// * Filters
	const [department, setDepartment] = useState<SelectValue>({ id: null, name: null });
	const [userName, setUserName] = useState('');

	const usersFilter = allUsers?.filter(item => {
		if (department.name) {
			return item.department === department.name;
		}
		return item;
	});

	const usersFilterName = matchItems(usersFilter, userName);

	const usersWithoutCompanyUsers = usersFilterName?.filter(item => {
		if (companyUsersId?.find(id => id === item.id)) {
			return;
		}
		return item;
	});

	const allDepartments =
		departmentTree.length > 0 &&
		departmentTree
			.map(department => {
				return department.children ? [department, ...department.children] : department;
			})
			.flat();

	const onAddUser = (usersId: string[]) => {
		if (addToEvent) {
			const userList: { userId: string }[] = usersId.map(user => {
				return { userId: user };
			});

			dispatch(setEventInfo({ users: userList }));
			dispatch(setSelectedUsersInfo(selectedUsersFull));
			toggleModal();

			return;
		}
		officeId &&
			dispatch(
				createOfficeUsers({
					payload: {
						officeId,
						userIds: [...usersId],
					},
				}),
			)
				.unwrap()
				.then(() => dispatch(getOffice({ params: { officeId } })))
				.then(() => {
					setSelectedUsers([]);
					toggleModal();
				});
	};

	const clickCancelButton = () => {
		setSelectedUsers([]);
		setSelectedUsersFull([]);
		toggleModal();
	};

	const addSelectedFullUser = (user: UserList) => {
		setSelectedUsersFull(prevState => {
			return [
				...prevState,
				{
					id: user.id,
					name: user.name,
					department: user.department,
					avatar: user.avatar,
				},
			];
		});
	};

	const deleteSelectedFullUser = (user: string) => {
		setSelectedUsersFull(prevState => prevState.filter(item => item.id !== user));
	};

	// * Render
	return (
		<div className={s.container}>
			<div className={s.title}>
				<h2>{addToEvent ? 'Пригласить' : 'Добавление сотрудника'}</h2>
			</div>

			{isLoading ? (
				<LoaderCircle />
			) : (
				<>
					{allDepartments && (
						<div className={s.filter_block}>
							<SelectStringSearchFilter
								selectPlaceholder="Департамент"
								selectValue={department}
								setSelect={(value: SelectValue) => setDepartment(value)}
								selectOptions={allDepartments}
								searchPlaceholder="Введите ФИО сотрудника"
								searchStringValue={userName}
								setSearchString={setUserName}
							/>
						</div>
					)}

					{addToEvent ? (
						<AddUserList
							users={usersFilterName}
							selectedUsers={selectedUsers}
							setSelectedUsers={setSelectedUsers}
							addSelectedFullUser={addSelectedFullUser}
							deleteSelectedFullUser={deleteSelectedFullUser}
							addToEvent={addToEvent}
						/>
					) : (
						usersWithoutCompanyUsers && (
							<AddUserList
								users={usersWithoutCompanyUsers}
								selectedUsers={selectedUsers}
								setSelectedUsers={setSelectedUsers}
								addSelectedFullUser={addSelectedFullUser}
								deleteSelectedFullUser={deleteSelectedFullUser}
								addToEvent={addToEvent}
							/>
						)
					)}

					<ButtonPair
						primaryText="Сохранить"
						primaryOnClick={() => onAddUser(selectedUsers)}
						primaryIsLoading={false} // TODO
						secondaryText="Отменить"
						secondaryOnClick={() => clickCancelButton()}
						secondaryIsLoading={false} // TODO
					/>
				</>
			)}
		</div>
	);
};
