import { UserResponse } from 'src/app/redux/queries/user-service/types/types';
import { contractsByTerm, RATES, visibilityBirthday } from './options';
import { DefaultValues } from '../types';
import { dateToTimeString } from 'src/shared/lib/date';

export const defaultValuesUserForm = {
	email: '',
	firstName: '',
	lastName: '',
	middleName: '',
	birthday: null,
	gender: {
		id: '',
		name: '',
	},
	visibilityBirthday: {
		id: 'WithoutYear',
		name: 'День и месяц',
	},
	rate: {
		id: '1',
		name: '1',
	},
	position: {
		id: '',
		name: '',
	},
	role: {
		id: '',
		name: '',
	},
	contract: {
		id: '',
		name: '',
	},
	contractByTerm: {
		id: 'FixedTerm',
		name: 'Бессрочный',
	},
	dateEndContract: null,
	probation: false,
	dateEndProbation: null,
	isAdmin: false,
	department: {
		id: '',
		name: '',
	},
	startWorkingDate: null,
	businessHoursFrom: null,
	businessHoursTo: null,
};

export const getDefaultValues = (userInfo: UserResponse): DefaultValues => {
	const { user, userAddition, departmentUser, position, companyUser, role } = userInfo;

	return {
		firstName: user.firstName,
		lastName: user.lastName,
		middleName: user.middleName || '',
		birthday: userAddition.dateOfBirth ? new Date(userAddition.dateOfBirth) : null,
		gender: userAddition.gender || {
			id: '',
			name: '',
		},
		visibilityBirthday: visibilityBirthday?.find(item => item.id === userAddition.birthType) ?? {
			id: 'WithoutYear',
			name: 'День и месяц',
		},
		rate: RATES.find(item => +item.id === companyUser?.rate) ?? {
			id: '1',
			name: '1',
		},
		position: {
			id: position?.id || '',
			name: position?.name || '',
		},
		role: {
			id: role?.id || '',
			name: role?.name || '',
		},
		contract: {
			id: companyUser?.contractSubject?.id || '',
			name: companyUser?.contractSubject?.name || '',
		},
		contractByTerm: contractsByTerm.find(item => item.id === companyUser?.contractTermType) ?? {
			id: '',
			name: '',
		},
		dateEndContract: companyUser?.endWorkingAt ? new Date(companyUser.endWorkingAt) : null,
		probation: !!companyUser?.probation,
		dateEndProbation: companyUser?.probation ? new Date(companyUser.probation) : null,
		isAdmin: user.isAdmin,
		department: {
			id: departmentUser?.department.id || '',
			name: departmentUser?.department.name || '',
		},
		startWorkingDate: companyUser?.startWorkingAt ? new Date(companyUser.startWorkingAt) : null,
		businessHoursFrom: userAddition.businessHoursFromUtc ? dateToTimeString(new Date(userAddition.businessHoursFromUtc)) : null,
		businessHoursTo: userAddition.businessHoursToUtc ? dateToTimeString(new Date(userAddition.businessHoursToUtc)) : null,
	};
};
