import { ComponentPropsWithoutRef, FC, useState } from 'react';
import { Button } from 'src/shared/ui/_buttons/Button/Button';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';
import { FormValues } from '../../types';
import { Form } from '../Form/Form';

interface Props extends ComponentPropsWithoutRef<'button'> {
	department?: FormValues;
}

export const AddEditDepartmentButton: FC<Props> = props => {
	const {
		department, //
		...buttonProps
	} = props;

	const isEditMode = !!department;

	const [departmentInfo, setDepartmentInfo] = useState<FormValues | null>(null);

	const closeModal = () => setDepartmentInfo(null);
	const openModal = () =>
		isEditMode
			? setDepartmentInfo(department)
			: setDepartmentInfo({
					id: null,
					name: '',
					shortName: '',
					isActive: {
						id: 'true',
						name: 'Активный',
					},
				});

	return (
		<>
			<ModalNewProto
				isOpen={!!departmentInfo}
				onClose={closeModal}
			>
				{departmentInfo && (
					<Form
						initialValues={departmentInfo}
						closeModal={closeModal}
					/>
				)}
			</ModalNewProto>

			<Button
				{...buttonProps}
				onClick={openModal}
				variant={isEditMode ? 'tertiary' : undefined}
			>
				{isEditMode ? 'Редактировать' : 'Добавить департамент'}
			</Button>
		</>
	);
};
