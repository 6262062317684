import { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/app/redux/store';
import { UserResponse } from 'src/app/redux/queries/user-service/types/types';
import { useRights } from 'src/shared/hooks/useRights';
import { Children } from '../Children';
import { Education } from '../Education';
import { General } from '../General';
import { Projects } from '../Projects';
import s from './InfoCard.module.scss';

interface Props {
	userInfo: UserResponse;
}

export const InfoCard: FC<Props> = props => {
	const { userInfo } = props;

	// * Rights
	const personalInfo = useSelector((state: RootState) => state.user_service.user.userInfo);
	const isPersonalUserCard = personalInfo?.user.id === userInfo.user.id;
	const isAdmin = useRights();
	const allowedHR = useRights(18);

	// * Render
	return (
		<div className={s.container}>
			<General
				userInfo={userInfo}
				hasRights={isPersonalUserCard || isAdmin}
			/>
			<Education userId={userInfo.user.id} />
			<Projects />
			{/* {(isPersonalUserCard || allowedHR) && <Children />} */}
		</div>
	);
};
