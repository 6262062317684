import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { project_serviceAPI } from 'src/app/redux/queries/project-service/project_serviceAPI';
import { useAppSelector } from 'src/app/redux/utils';
import { Button } from 'src/shared/ui/_buttons/Button/Button';
import { LoaderCircle } from 'src/shared/ui/_loaders/LoaderCircle/LoaderCircle';
import { PageDecorator } from 'src/app/decorators/page_decorators/PageDecorator/PageDecorator';
import { PageTitle } from 'src/entities/PageTitle/PageTitle';
import { useRights } from 'src/shared/hooks/useRights';
import { Status } from 'src/pages/project_service_pages/consts/status';
import { ProjectInfo } from 'src/pages/project_service_pages/ui/ProjectDetailPage/ui/ProjectInfo/ProjectInfo';
import { Team } from 'src/pages/project_service_pages/ui/ProjectDetailPage/ui/Team/Team';
import { FormButton } from 'src/pages/project_service_pages/ui/ProjectPage/ui/FormButton/FormButton';
import { dateToFullRuDateString, getDiffBetweenDates } from 'src/shared/lib/date';

export const ProjectDetailPage = () => {
	// * Router
	const params = useParams<{ projectId: string }>();
	const projectId: string = params.projectId as string;

	// * API
	const { data: projectData, isLoading } = project_serviceAPI.useGetProjectQuery(
		{
			projectId: projectId,
			includeprojectusers: true,
			includedepartment: true,
		},
		{ skip: !projectId },
	);

	const { name, customer, department, status, endDateUtc, startDateUtc, users } = projectData?.body || {};

	// * Selectors
	const userId = useAppSelector(state => state.user_service.user.userInfo?.user.id);

	// * Rights
	const isAdmin = useRights();
	const allowedToEditProjects = useRights(2);
	const isManagerRole = useMemo(() => !!users?.find(user => user.userId === userId && user.role === 'Manager'), [projectData, userId]);

	// * Navigate
	const navigate = useNavigate();

	// * Render
	return (
		<PageDecorator>
			<PageTitle
				title={name || ''}
				goBackRoute={{
					text: 'Проекты',
					route: '/projects',
				}}
				buttons={
					isAdmin || allowedToEditProjects || isManagerRole
						? [
								<React.Fragment key="0">
									<FormButton isEdit />
								</React.Fragment>,

								<React.Fragment key="1">
									<Button onClick={() => navigate('timelist')}>Таймлист</Button>
								</React.Fragment>,
							]
						: []
				}
			/>

			<ProjectInfo
				customer={customer || '-'}
				department={department?.name || '-'}
				usersCount={users?.length || 0}
				status={status ? Status[status].name : '-'}
				endDateUtc={endDateUtc ? dateToFullRuDateString(new Date(endDateUtc)) : '-'}
				startDateUtc={startDateUtc ? dateToFullRuDateString(new Date(startDateUtc)) : '-'}
				duration={startDateUtc && endDateUtc ? `${getDiffBetweenDates(startDateUtc, endDateUtc)} дней` : '-'}
			/>

			{isLoading && <LoaderCircle />}

			<Team
				projectId={projectId}
				hasRights={isAdmin || allowedToEditProjects || isManagerRole}
			/>
		</PageDecorator>
	);
};
