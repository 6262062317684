import { CellContext, createColumnHelper } from '@tanstack/react-table';
import { ReactNode } from 'react';

export interface UsersTableDataItem {
	id: string;
	name: string;
	department: string;
	departmentId: string;
	avatar?: ReactNode;
}

const columnHelper = createColumnHelper<UsersTableDataItem>();

export const COLUMNS_USERS = [
	columnHelper.accessor('avatar', {
		header: '',
		cell: info => info.getValue(),
		meta: {
			style: {
				width: '1%',
			},
		},
	}),
	columnHelper.accessor('name', {
		header: 'ФИО',
	}),
	columnHelper.accessor('department', {
		header: 'ДЕПАРТАМЕНТ',
	}),
];
