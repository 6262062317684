import React, { ComponentPropsWithoutRef, ReactNode } from 'react';
import { TextArea } from '../_inputs/text_Inputs/TextArea/TextArea';
import { Controller, useFormContext } from 'react-hook-form';

interface Props extends ComponentPropsWithoutRef<'div'> {
	name: string;
	label?: string;
	placeholder?: string;
	characterLimit?: number;
	limitPosition?: 'start' | 'end';
	size?: 'small' | 'large' | 'tiny';
	disabled?: boolean;
	defaultValue?: string;
	Icon?: ReactNode;
	onIconClicked?: React.MouseEventHandler<HTMLDivElement>;
	required?: boolean;
}

export const TextAreaField: React.FC<Props> = props => {
	const { name, label, placeholder, characterLimit, size, disabled, defaultValue, Icon, onIconClicked, required, className } = props;

	const { control } = useFormContext();

	return (
		<Controller
			name={name}
			control={control}
			defaultValue={defaultValue}
			render={({ field, fieldState }) => (
				<TextArea
					{...field}
					className={className}
					label={label}
					placeholder={placeholder}
					characterLimit={characterLimit}
					size={size}
					Icon={Icon}
					disabled={disabled}
					errorMessage={fieldState.error?.message}
					defaultValue={defaultValue}
					onIconClicked={onIconClicked}
					required={required}
				/>
			)}
		/>
	);
};
