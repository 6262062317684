import React from 'react';
import s from './SuccessContent.module.scss';
import { Button } from 'src/shared/ui/_buttons/Button/Button';

interface Props {
	toggleSuccessModal: () => void;
}

export const SuccessContent: React.FC<Props> = props => {
	const { toggleSuccessModal } = props;

	// * Render
	return (
		<div className={s.success_container}>
			<h2 className={s.success_title}>Отправлено</h2>
			<div className={s.success_description}>
				Благодарим вас за оставленное сообщение
				<br />
				об ошибке, наша команда займется его изучением в ближайшее время.
			</div>
			<Button
				wide
				variant="primary"
				onClick={toggleSuccessModal}
			>
				Ок
			</Button>
		</div>
	);
};
