import React, { useState } from 'react';
import s from './SurveyPage.module.scss';
import { useNavigate } from 'react-router-dom';
import { FindSurveysResultInfo } from 'src/app/redux/queries/survey-service/types';
import { Button } from 'src/shared/ui/_buttons/Button/Button';
import { LineTabs, SingleTab } from 'src/shared/ui/_tabs/lineTabs/LineTabs';
import { PageDecorator } from 'src/app/decorators/page_decorators/PageDecorator/PageDecorator';
import { useRights } from 'src/shared/hooks/useRights';
import { useWindowDimensions } from 'src/shared/hooks/useWindowDimensions';
import { FiltersSurveys } from '../../components/FiltersSurveys/FiltersSurveys';
import { CompletedContent } from './Content/CompletedContent/CompletedContent';
import { SurveysContent } from './Content/SurveysContent/SurveysContent';
import { TemplateContent } from './Content/TemplateContent/TemplateContent';
import { useActions, useAppSelector } from 'src/app/redux/utils';
import { surveyPageActions } from 'src/app/redux/state/survey/slice';
import { PageTitle } from 'src/entities/PageTitle/PageTitle';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';
import { ModalContent } from '../../components/ModalContent/ModalContent';
import { survey_serviceAPI } from 'src/app/redux/queries/survey-service/survey_serviceAPI';
import { LoaderCircle } from 'src/shared/ui/_loaders/LoaderCircle/LoaderCircle';

export const SurveyPage: React.FC = () => {
	// * Selectors
	const activeTab = useAppSelector(state => state.surveyPage.activeTab);
	const remoteSurveyName = useAppSelector(state => state.surveyPage.remoteSurveyName);

	// * Actions
	const { setActiveTab } = useActions(surveyPageActions);
	const { setRemoteSurveyName } = useActions(surveyPageActions);

	const isTemplate = activeTab === 'template';

	// * Api
	const { currentData: surveysData, isLoading } = survey_serviceAPI.useGetSurveysQuery({
		includecategories: true,
		skipcount: 0,
		takecount: 1000,
		istemplate: isTemplate,
	});

	const surveys = surveysData?.body;

	// * Rights
	const isAdmin = useRights();

	// * Window
	const { width } = useWindowDimensions();
	const isDesktop = width >= 1024;

	// * Tabs navigation
	const tabs: SingleTab[] = [
		{
			id: 'surveys' as const,
			name: 'Все опросы',
		},
		{
			id: 'completed' as const,
			name: 'Пройденные',
		},
	];

	isAdmin &&
		isDesktop &&
		tabs.push({
			id: 'template' as const,
			name: 'Шаблоны',
		});

	const isTemplateTab = activeTab === 'template';

	// * Navigate
	const navigate = useNavigate();

	const navigateToNewSurvey = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		event.preventDefault();
		event.stopPropagation();

		const navigateTo = isTemplateTab ? 'template_form/new' : 'survey_form/new';
		navigate(navigateTo);
	};

	// * Filters
	const getFilteredResults = (filteredSurveys: FindSurveysResultInfo[]) => {
		setFilteredSurveys(filteredSurveys);
	};

	const [filteredSurveys, setFilteredSurveys] = useState<FindSurveysResultInfo[] | null>(null);

	// * Modal
	const [showSurveyInfo, setShowSurveyInfo] = useState(!!remoteSurveyName);

	const onCloseModal = () => {
		setShowSurveyInfo(false);
		setRemoteSurveyName('');
	};

	// * Render
	return (
		<PageDecorator>
			<PageTitle
				title="Опросы"
				isBeta
				buttons={[
					<React.Fragment key="0">
						{isAdmin && isDesktop && (
							<Button
								variant="primary"
								onClick={navigateToNewSurvey}
							>
								{isTemplateTab ? 'Создать шаблон' : 'Создать опрос'}
							</Button>
						)}
					</React.Fragment>,
				]}
			/>

			<ModalNewProto
				isOpen={showSurveyInfo}
				onClose={onCloseModal}
			>
				<ModalContent
					title="Опрос удалён"
					subtitle={`Опрос "${remoteSurveyName}" был удалён`}
					buttonText={`Перейти в "Опросы"`}
					action={onCloseModal}
				/>
			</ModalNewProto>

			<div className={s.container}>
				<LineTabs
					tabs={tabs}
					width="fit"
					activeTabId={activeTab}
					setActiveTabId={setActiveTab}
				/>

				{isLoading && <LoaderCircle />}

				{surveys && (
					<FiltersSurveys
						getFilteredResults={getFilteredResults}
						activeTab={activeTab}
						surveys={surveys}
					/>
				)}

				{activeTab === 'surveys' && filteredSurveys && <SurveysContent surveys={filteredSurveys} />}

				{activeTab === 'completed' && filteredSurveys && <CompletedContent surveys={filteredSurveys} />}

				{activeTab === 'template' && filteredSurveys && <TemplateContent templates={filteredSurveys} />}
			</div>
		</PageDecorator>
	);
};
