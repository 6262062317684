import React, { FC } from 'react';
import { Heading } from 'src/shared/ui/Heading';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';
import s from './ConfirmEmailModal.module.scss';
import { user_serviceAPI } from 'src/app/redux/queries/user-service/user_serviceAPI';
import { Button } from 'src/shared/ui/_buttons/Button/Button';

interface Props {
	id: string;
	email: string;
	isOpen: boolean;
	onClose: () => void;
}

export const ConfirmEmailModal: FC<Props> = props => {
	const { id, email, isOpen, onClose } = props;

	const { isLoading } = user_serviceAPI.useGetCommunicationResendconfirmationQuery({
		communicationId: id,
	});

	// * Render
	return (
		<ModalNewProto
			isOpen={isOpen}
			onClose={onClose}
			className={s.modal}
		>
			<div className={s.container}>
				<Heading
					level={2}
					marginBottom="l"
					className={s.header}
				>
					Подтверждение почты
				</Heading>

				<div className={s.description}>
					На адрес <span className={s.email}>{email}</span> {'выслана ссылка для подтверждения\nпочты.'}
				</div>
				<div className={s.description}>Она будет действительна в течение 30 минут.</div>

				<Button
					fixedWidth
					onClick={onClose}
					isLoading={isLoading}
				>
					Ок
				</Button>
			</div>
		</ModalNewProto>
	);
};
