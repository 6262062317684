import React, { useEffect, useState } from 'react';
import s from './BookingPage.module.scss';
import { PageDecorator } from 'src/app/decorators/page_decorators/PageDecorator/PageDecorator';
import { OfficeSelectionCard } from '../OfficeSelectionCard/OfficeSelectionCard';
import { MyBookingCard } from '../MyBookingCard/MyBookingCard';
import { BookingFilters } from '../BookingFilters/BookingFilters';
import { BookingRoomsList } from '../BookingRoomsList/BookingRoomsList';
import { bookingPageActions } from 'src/app/redux/state/office/_states/booking_page_state';
import { useAppDispatch } from 'src/app/redux/utils';
import { routerPath } from 'src/app/router/paths';

export const BookingPage: React.FC = () => {
	const [officeId, setOfficeId] = useState<string>('');

	// * Actions
	const dispatch = useAppDispatch();
	const { clearSelectedAddress } = bookingPageActions;

	useEffect(() => {
		return () => {
			if (!window.location.pathname.startsWith(routerPath.booking.page)) {
				dispatch(clearSelectedAddress());
			}
		};
	}, []);

	// * Render
	return (
		<PageDecorator>
			<h1 className={s.title}>Бронирование мест</h1>

			<div className={s.main_block}>
				<OfficeSelectionCard setOfficeId={setOfficeId} />

				<div className={s.second_block}>
					<MyBookingCard />

					{officeId && <BookingFilters officeId={officeId} />}
				</div>
			</div>

			<div className={s.rooms_list}>
				<BookingRoomsList officeId={officeId} />
			</div>
		</PageDecorator>
	);
};
