import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'src/app/redux/utils';
import { officeServiceActions } from 'src/app/redux/state/office/actions';
import { imageServiceActions } from 'src/app/redux/state/image/actions';
import { Gallery } from 'src/shared/ui/_galleries/Galley/Gallery';
import { useRights } from 'src/shared/hooks/useRights';
import { RequestStatus } from 'src/app/api/api_types';
import { ModalHeader } from '../../../../../../shared/ui/_modals/ModalHeader/ModalHeader';

interface Props {
	officeId: string;
}

export const OfficeSchemeMC: React.FC<Props> = props => {
	const { officeId } = props;

	// * Actions
	const dispatch = useAppDispatch();
	const { getImages, createImage, removeImages } = officeServiceActions.image.async;
	const { clearImages } = officeServiceActions.image.actions;
	const { getImage } = imageServiceActions.image.async;

	const fetchImages = () => {
		dispatch(
			getImages({
				params: {
					entityId: officeId,
				},
				options: {
					entity: 'schema',
				},
			}),
		);
	};

	// * Selectors
	const images = useAppSelector(state => state.office_service.image.schemaImages);
	const status = useAppSelector(state => state.office_service.image.status);

	// * Initialize
	useEffect(() => {
		fetchImages();

		return () => {
			dispatch(clearImages('schema'));
		};
	}, []);

	// * Rights
	const allowedToEdit = useRights();

	// * Render
	return (
		<>
			<ModalHeader title="Схема офиса" />

			<Gallery
				images={images.filter(image => image.isSchemaImage)}
				allowedToEdit={allowedToEdit}
				createImages={images => {
					if (images.length > 0) {
						images.forEach(image => {
							dispatch(
								createImage({
									payload: {
										entityId: officeId,
										name: image.name,
										content: image.content,
										extension: image.extension,
										isSchemaImage: true,
									},
								}),
							)
								.unwrap()
								.then(() => fetchImages());
						});
					}
				}}
				deleteImages={imagesIds => {
					dispatch(
						removeImages({
							payload: {
								entityId: officeId,
								imagesIds,
							},
						}),
					)
						.unwrap()
						.then(() => fetchImages());
				}}
				fetchImage={(imageId: string) =>
					getImage({
						params: {
							imageId,
							source: 'Office',
						},
					})
				}
				isLoading={status === RequestStatus.loading}
			/>
		</>
	);
};
