import React, { ComponentPropsWithoutRef, useState } from 'react';
import { PublicRubricData, RubricData } from 'src/app/redux/queries/wiki-service/types/types';
import { Rubric } from '../Rubric/Rubric';
import s from './RubricList.module.scss';
import { Button } from 'src/shared/ui/_buttons/Button/Button';
import { ReactComponent as AddSVG } from 'src/shared/assets/svg/action/add.svg';
import { useAppSelector } from 'src/app/redux/utils';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';
import { ModalContentCreateNew } from 'src/pages/wiki/_common_components/modals/ModalCreateNew/ModalContentCreateNew';

interface Props extends ComponentPropsWithoutRef<'div'> {
	rubrics: RubricData[] | PublicRubricData[];
	activeRubricId: string | null;
	setActiveRubric: (rubricId: string | null) => void;
}

export const RubricList: React.FC<Props> = props => {
	const {
		rubrics, //
		activeRubricId,
		setActiveRubric,
		...restDivProps
	} = props;

	const editMode = useAppSelector(state => state.wiki.editModeWiki);

	// - Create rubric/sub-rubric/article
	const [modalActive, setModalActive] = useState(false);
	const toggleModal = () => setModalActive(prevState => !prevState);

	// * Render
	return (
		<>
			{modalActive && (
				<ModalNewProto
					width="s"
					isOpen={modalActive}
					onClose={toggleModal}
				>
					<ModalContentCreateNew toggleModal={toggleModal} />
				</ModalNewProto>
			)}

			<div {...restDivProps}>
				<div className={s.tabsWrapper}>
					{rubrics.map(rubric => (
						<Rubric
							key={rubric.id}
							tabId={rubric.id}
							title={rubric.name}
							active={activeRubricId === rubric.id}
							archived={'isActive' in rubric ? !rubric.isActive : false}
							setActive={setActiveRubric}
						/>
					))}
					{editMode && (
						<Button
							variant="secondary"
							className={s.add_rubric}
							LeftIcon={<AddSVG />}
							onClick={toggleModal}
						>
							Добавить рубрику
						</Button>
					)}
				</div>
			</div>
		</>
	);
};
